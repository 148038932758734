import { DateTime } from 'luxon';
import React from 'react';
import FormCombobox from '../../components/Form/Input/FormCombobox';
import { reportStatisticsBySpecialistIdAndYouthWelfareIdAndDate } from '../../_api/appointment.services';

// bewilligung & kontingente von youthWelfareCaseId WHERE enddate >= date
// appointments WHERE specialistId AND youthWelfareCaseId AND date BETWEEN [bew.kont.startdate & enddate]
// durationBudget - (calc appointmentUsedDuration) = remainingDurationForAppointments
// efficacy = chargeableAppointmentDurations / durationBudget

export default function EfficacyratePanel({
  specialistId,
  youthWelfareCaseId,
  date,
  durationToBeAdded,
  appointmentTypeToBeAdded,
}) {
  const [stats, setStats] = React.useState({
    budget: 0,
    used: 0,
    billable: 0,
    profitablity: 0,
    areIndirectAppointmentsBillable: false,
    budgetAllocation: '',
  });
  const [selectedMonth, setSelectedMonth] = React.useState(
    DateTime.now().month
  );

  function calculateNewUsedDuration() {
    return stats.used + durationToBeAdded;
  }

  function calculateNewProfitability() {
    switch (appointmentTypeToBeAdded) {
      case 'DIRECT':
        return (
          (stats.billable + durationToBeAdded) /
          (stats.used + durationToBeAdded)
        );
      case 'INDIRECT':
        if (stats.areIndirectAppointmentsBillable == true) {
          return (
            (stats.billable + durationToBeAdded) /
            (stats.used + durationToBeAdded)
          );
        }
      default:
        return stats.billable / (stats.used + durationToBeAdded);
    }
  }

  function calculateNewBillableDuration() {
    switch (appointmentTypeToBeAdded) {
      case 'DIRECT':
        return stats.billable + durationToBeAdded;
      case 'INDIRECT':
        if (stats.areIndirectAppointmentsBillable == true) {
          return stats.billable + durationToBeAdded;
        } else {
          return stats.billable;
        }
      default:
        return stats.billable;
    }
  }

  React.useEffect(() => {
    if (specialistId && youthWelfareCaseId && date) {
      reportStatisticsBySpecialistIdAndYouthWelfareIdAndDate(
        undefined,
        youthWelfareCaseId,
        date,
        selectedMonth
      ).then((data) => {
        setStats(data);
      });
    }
  }, [specialistId, youthWelfareCaseId, date, selectedMonth]);

  return (
    <div className='shadow sm:rounded-md '>
      <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
        <div className=''>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>
            Auswertung
          </h3>
          <p className='mt-1 text-sm text-gray-600'>
            Sobald Sie Fachkraft- und Fallfeld ausgefüllt haben wird anhand des
            aktuellen oder ausgewählten Datum eine Auswertung berechnet.
          </p>
        </div>
        <hr />
        <div className='grid grid-cols-1'>
          {stats.budgetAllocation == 'MONTHLY' ? (
            <FormCombobox
              filterFn={(item, query) =>
                `${item.name?.toLowerCase()}`.includes(
                  query.toLowerCase().replace(/\s+/g, '')
                )
              }
              label='Monatauswahl'
              name='monthSelector'
              onChange={(selected) => {
                setSelectedMonth(selected);
              }}
              optionToKey={(item) => item.id.toString()}
              optionToString={(item) => item.name}
              optionToValue={(item) => item.id}
              options={[
                { id: '1', name: 'Januar' },
                { id: '2', name: 'Februar' },
                { id: '3', name: 'März' },
                { id: '4', name: 'April' },
                { id: '5', name: 'Mai' },
                { id: '6', name: 'Juni' },
                { id: '7', name: 'Juli' },
                { id: '8', name: 'August' },
                { id: '9', name: 'September' },
                { id: '10', name: 'Oktober' },
                { id: '11', name: 'November' },
                { id: '12', name: 'Dezember' },
              ]}
              selected={selectedMonth}
            />
          ) : null}

          <span>Stundenbudget: {stats.budget}</span>
          <span>
            Davon verbraucht: {stats.used} ({calculateNewUsedDuration()})
          </span>
          <span>
            Davon abrechenbar: {stats.billable}{' '}
            <span
              className={`${
                calculateNewBillableDuration() > stats.budget
                  ? 'text-red-600'
                  : ''
              }`}
            >
              ({calculateNewBillableDuration()})
            </span>
          </span>
          <span>
            Berechnete Effizienz: {stats.profitablity.toFixed(4)} (
            {calculateNewProfitability().toFixed(4)})
          </span>
        </div>
      </div>
    </div>
  );
}

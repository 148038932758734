const isTime = {
  quarterly: /^([0-2][0-9]):(00|15|30|45)$/,
};

const isDateDDMMYYYY = /^(0[1-9]|[12][0-9]|3[01]).(0[1-9]|1[0-2]).d{4}$/;
const isDateYYYYMMDD = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

export default {
  isTime,
  isDateDDMMYYYY,
  isDateYYYYMMDD,
};

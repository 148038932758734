import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import moment from 'moment';
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  any,
  boolean,
  enums,
  integer,
  number,
  object,
  pattern,
  string,
  validate,
} from 'superstruct';
import { useAuthenticationContext } from '../components/Authentication/AuthenticationContext';
import FormCombobox from '../components/Form/Input/FormCombobox';
import FormComboboxWithQuery from '../components/Form/Input/FormComboboxWithQuery';
import { regexDateISO } from '../services/regex/date';
import { regexTimeQuarterly } from '../services/regex/time';
import { findById } from '../_api/appointment.services';
import { findByYouthWelfareCaseIdAndDate } from '../_api/calculationPrinciple.services';
import { findAllAsAdminOrBySpecialistId as findAllSpecialistsAsAdminOrBySpecialistId } from '../_api/specialist.services';
import { findAllAsAdminOrBySpecialistId as findAllYouthWelfareCasesAsAdminOrBySpecialistId } from '../_api/youthWelfareCase.services';
import Checkbox from '../_components/Form/Checkbox/Checkbox';
import Dropdown from '../_components/Form/Dropdown/Dropdown';
import FormValidator from '../_components/Form/FormValidator';
import Textarea from '../_components/Form/Textarea/Textarea';
import TextInput from '../_components/Form/TextInput/TextInput';
import utility from '../_helpers/utility';
import AppointmentPanel from './AppointmentPanel/AppointmentPanel';
import EfficacyratePanel from './EfficacyRatePanel';
import StatisticsPanel from './StatisticsPanel/StatisticsPanel';

/**
 *
 * @param {{data: Object, onSubmit: UseMutationResult<AxiosResponse<unknown, any>, unknown, void, unknown>}} props
 * @returns
 */
export default function PatientenTerminForm({
  id,
  data,
  history,
  onSubmit,
  user,
}) {
  const initialFormValues = {
    date: '',
    startTime: '',
    endTime: '',
    appointmentType: '',
    location: '',
    pointOfDeparture: '',
    kilometers: 0,
    isCommute: false,
    contactActivity: '',
    activity: '',
    documentation: '',
    createdBy: '',
    updatedBy: '',
    inUseBy: '',
    youthWelfareCaseId: null,
    specialistId: null,
  };
  const formStruct = object({
    id: any(),
    date: any(),
    startTime: pattern(string(), utility.isTime.quarterly),
    endTime: pattern(string(), utility.isTime.quarterly),
    appointmentType: enums(['DIRECT', 'INDIRECT', 'INTERNAL']),
    terminDauer: any(),
    location: any(),
    pointOfDeparture: any(),
    kilometers: number(),
    isCommute: boolean(),
    contactActivity: any(),
    activity: any(),
    documentation: any(),

    youthWelfareCaseId: integer(),
    specialistId: integer(),

    createdBy: any(),
    createdAt: any(),
    updatedBy: any(),
    updatedAt: any(),
    inUseBy: any(),
    version: any(),
  });

  let [values, setValues] = React.useState(initialFormValues);
  let [datumErr, setDatumErr] = React.useState({
    invalid: false,
    text: 'Bitte Überprüfen Sie das Format Ihrer Eingabe.',
  });
  let [terminBeginErr, setTerminBeginErr] = React.useState({
    invalid: false,
    text: 'Bitte Überprüfen Sie das Format Ihrer Eingabe. Nur viertelstündige Zeiten.',
  });
  let [terminEndeErr, setTerminEndeErr] = React.useState({
    invalid: false,
    text: 'Bitte Überprüfen Sie das Format Ihrer Eingabe. Nur viertelstündige Zeiten.',
  });
  let [terminArtErr, setTerminArtErr] = React.useState({
    invalid: false,
    text: 'Dies ist ein Pflichtfeld.',
  });
  let [kilometerErr, setKilometerErr] = React.useState({
    invalid: false,
    text: 'Bitte Überprüfen Sie das Format Ihrer Eingabe.',
  });
  let [fallErr, setFallErr] = React.useState({
    invalid: false,
    text: 'Dies ist ein Pflichtfeld.',
  });
  let [therapeutErr, setTherapeutErr] = React.useState({
    invalid: false,
    text: 'Dies ist ein Pflichtfeld.',
  });

  const [appointmentDuration, setAppointmentDuration] = React.useState(0);
  const [noCalculationPrincipleError, setNoCalculationPrincipleError] =
    React.useState(false);

  const queryClient = useQueryClient();
  const auth = useAuthenticationContext();

  function isFormValid() {
    let dateTimeErr = false;
    const [err] = validate(values, formStruct);
    const dateInPast = moment().add(-3, 'weeks');
    const date = moment(values.date);
    const startTime = moment(values.startTime, 'HH:mm');
    const endTime = moment(values.endTime, 'HH:mm');

    if (err) {
      err.failures().forEach((element) => {
        if (element.key == 'date') {
          setDatumErr({ ...datumErr, invalid: true });
        }
        if (element.key == 'startTime') {
          setTerminBeginErr({ ...terminBeginErr, invalid: true });
        }
        if (element.key == 'endTime') {
          setTerminEndeErr({ ...terminEndeErr, invalid: true });
        }
        if (element.key == 'appointmentType') {
          setTerminArtErr({ ...terminArtErr, invalid: true });
        }
        if (element.key == 'kilometers') {
          setKilometerErr({ ...kilometerErr, invalid: true });
        }
        if (element.key == 'youthWelfareCaseId') {
          setFallErr({ ...fallErr, invalid: true });
        }
        if (element.key == 'specialistId') {
          setTherapeutErr({ ...therapeutErr, invalid: true });
        }
      });
    }

    if (regexDateISO.test(values.date)) {
      if (date < dateInPast && !auth.isAdmin()) {
        setDatumErr({
          text: 'Ihr angegebenes Datum befindet sich mehr als 3 Wochen zurück. Bitte wenden Sie sich an einen Benutzer mit höheren Benutzerrechten.',
          invalid: true,
        });
        dateTimeErr = true;
      } else {
        setDatumErr({
          invalid: false,
          text: 'Bitte Überprüfen Sie das Format Ihrer Eingabe.',
        });
      }
    } else {
      setDatumErr({
        invalid: true,
        text: 'Bitte Überprüfen Sie das Format Ihrer Eingabe.',
      });
      dateTimeErr = true;
    }

    if (endTime.diff(startTime) <= 0) {
      setTerminBeginErr({
        text: 'Die Startzeit ist größer als die Endzeit.',
        invalid: true,
      });
      setTerminEndeErr({
        text: 'Die Startzeit ist größer als die Endzeit.',
        invalid: true,
      });
      dateTimeErr = true;
    } else {
      setTerminBeginErr({
        ...terminBeginErr,
        text: 'Bitte Überprüfen Sie das Format Ihrer Eingabe. Nur viertelstündige Zeiten.',
      });
      setTerminEndeErr({
        ...terminEndeErr,
        text: 'Bitte Überprüfen Sie das Format Ihrer Eingabe. Nur viertelstündige Zeiten.',
      });
    }

    if (err || dateTimeErr == true) {
      alert('Fehler im Formular');
    }
    return !err && dateTimeErr == false;
  }

  const {
    status: youthWelfareCaseQueryStatus,
    data: youthWelfareCaseDropdownData,
  } = useQuery('YouthWelfareCasesDropdown', () => {
    return findAllYouthWelfareCasesAsAdminOrBySpecialistId();
  });
  const { status: specialistQueryStatus, data: specialistDropdownData } =
    useQuery('SpecialistsDropdown', () => {
      return findAllSpecialistsAsAdminOrBySpecialistId();
    });

  React.useEffect(() => {
    if (!!id) {
      findById(Number.parseInt(id)).then((data) =>
        setValues({ ...values, ...data })
      );
    } else if (!id && user.role != 'ADMIN') {
      setValues({ ...values, specialistId: user.id });
    }
  }, [id, youthWelfareCaseQueryStatus, specialistQueryStatus, user]);

  React.useEffect(() => {
    if (values.startTime && values.endTime) {
      const startTime = DateTime.fromFormat(values.startTime, 'HH:mm');
      const endTime = DateTime.fromFormat(values.endTime, 'HH:mm');
      setAppointmentDuration(endTime.diff(startTime, 'hours').hours);
    }
  }, [values.startTime, values.endTime]);

  React.useEffect(() => {
    if (
      values.youthWelfareCaseId != null &&
      DateTime.fromFormat(values.date, 'yyyy-MM-dd').isValid
    ) {
      findByYouthWelfareCaseIdAndDate(
        values.youthWelfareCaseId,
        values.date
      ).then((res) => {
        console.log(res);
        if (res != null) {
          setNoCalculationPrincipleError(false);
        } else {
          setNoCalculationPrincipleError(true);
        }
      });
    }
  }, [values.youthWelfareCaseId, values.date]);

  return (
    <>
      <div className='md:grid md:grid-cols-3 md:gap-6'>
        <div className='mt-5 md:mt-0 md:col-span-2'>
          <form
            autoComplete='off'
            onSubmit={(event) => {
              event.preventDefault();
              if (isFormValid()) {
                onSubmit.mutate(values);
              }
            }}
          >
            <div className='shadow sm:rounded-md sm:overflow-hidden'>
              <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                <div className='flex'>
                  <div>
                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                      Klienten Termin
                    </h3>
                    <p className='mt-1 text-sm text-gray-600'>Formular.</p>
                  </div>
                  <button
                    type='button'
                    onClick={() =>
                      document
                        .getElementById('appointment-statistics')
                        .scrollIntoView({ behavior: 'smooth' })
                    }
                    className='ml-auto mt-3 h-10 w-full inline-flex justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:w-auto sm:text-sm'
                  >
                    Statistiken
                  </button>
                </div>
                <div
                  className={`flex items-center py-2 px-4 rounded-lg text-sm text-gray-800 border border-yellow-400 bg-yellow-300 ${
                    noCalculationPrincipleError == false ? 'hidden' : ''
                  }`}
                >
                  <FontAwesomeIcon
                    className='mr-2'
                    icon={faTriangleExclamation}
                  />
                  Keine Berechnungsgrundlage gefunden, die den{' '}
                  {DateTime.fromFormat(values.date, 'yyyy-MM-dd').toFormat(
                    'dd.LL.yyyy'
                  )}{' '}
                  abdeckt. Eine Termineintragung ist weiterhin möglich.
                </div>
                <hr />
                <FormComboboxWithQuery
                  error={therapeutErr.invalid}
                  errormsg={therapeutErr.text}
                  filterFn={(item, query) =>
                    `${item.firstname?.toLowerCase()}${item.lastname?.toLowerCase()}`.includes(
                      query.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                  label='Fachkraft'
                  name='specialistId'
                  onBlur={() => {
                    setTherapeutErr({
                      ...therapeutErr,
                      invalid: !FormValidator.validateDropdown(
                        values.specialistId
                      ),
                    });
                  }}
                  onChange={(selected) => {
                    console.log(selected);
                    if (selected) {
                      setValues({ ...values, specialistId: selected });
                    } else {
                      setValues({ ...values, specialistId: null });
                    }
                    setTherapeutErr({
                      ...therapeutErr,
                      invalid: !FormValidator.validateDropdown(selected),
                    });
                  }}
                  optionToKey={(item) => item.id.toString()}
                  optionToString={(item) =>
                    item ? `${item.firstname} ${item.lastname}` : ''
                  }
                  optionToValue={(item) => item.id}
                  selected={
                    user.role == 'ADMIN' ? values.specialistId || '' : user.id
                  }
                  queryFn={findAllSpecialistsAsAdminOrBySpecialistId}
                  queryKeys={['SpecialistsDropdown']}
                />
                {/* {specialistQueryStatus == 'success' ? (
                  <Dropdown
                    disabled={user.isAdmin == false}
                    id='specialistId'
                    name='specialistId'
                    label='Fachkraft'
                    errorLabel={therapeutErr.text}
                    error={therapeutErr.invalid}
                    value={
                      user.role == 'ADMIN' ? values.specialistId || '' : user.id
                    }
                    options={specialistDropdownData || []}
                    optionToValue={(item) => item.id}
                    optionToString={(item) =>
                      `${item.firstname} ${item.lastname}`
                    }
                    onChange={({ target }) => {
                      const selectedObj = specialistDropdownData.filter(
                        (item) => item.id == target.value
                      )[0];
                      if (selectedObj) {
                        setValues({ ...values, specialistId: selectedObj.id });
                      } else {
                        setValues({ ...values, specialistId: null });
                      }
                      setTherapeutErr({
                        ...therapeutErr,
                        invalid: !FormValidator.validateDropdown(target.value),
                      });
                      queryClient.invalidateQueries(
                        'youthWelfareCases_AppointmentForm'
                      );
                    }}
                    onBlur={() => {
                      setTherapeutErr({
                        ...therapeutErr,
                        invalid: !FormValidator.validateDropdown(
                          values.specialistId
                        ),
                      });
                    }}
                  />
                ) : null} */}

                <FormComboboxWithQuery
                  error={fallErr.invalid}
                  errormsg={fallErr.text}
                  filterFn={(item, query) =>
                    `${item.identifier?.toLowerCase()}`.includes(
                      query.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                  label='Fall'
                  name='youthWelfareCaseId'
                  onBlur={() => {
                    setFallErr({
                      ...fallErr,
                      invalid: !FormValidator.validateDropdown(
                        values.youthWelfareCaseId
                      ),
                    });
                  }}
                  onChange={(selected) => {
                    if (selected) {
                      setValues({ ...values, youthWelfareCaseId: selected });
                    } else {
                      setValues({ ...values, youthWelfareCaseId: null });
                    }
                    setFallErr({
                      ...fallErr,
                      invalid: !FormValidator.validateDropdown(selected),
                    });
                  }}
                  optionToKey={(item) => item.id.toString()}
                  optionToString={(item) => (item ? `${item.identifier}` : '')}
                  optionToValue={(item) => item.id}
                  selected={values.youthWelfareCaseId}
                  queryFn={findAllYouthWelfareCasesAsAdminOrBySpecialistId}
                  queryKeys={['YouthWelfareCasesDropdown']}
                />

                {/* {youthWelfareCaseQueryStatus == 'success' ? (
                  <Dropdown
                    id='youthWelfareCaseId'
                    name='youthWelfareCaseId'
                    label='Fall'
                    errorLabel={fallErr.text}
                    error={fallErr.invalid}
                    value={values.youthWelfareCaseId || ''}
                    options={youthWelfareCaseDropdownData || []}
                    optionToValue={(item) => item.id}
                    optionToString={(item) => `${item.identifier}`}
                    onChange={({ target }) => {
                      const selectedObj = youthWelfareCaseDropdownData.filter(
                        (item) => item.id == target.value
                      )[0];
                      if (selectedObj) {
                        setValues({
                          ...values,
                          youthWelfareCaseId: selectedObj.id,
                        });
                      } else {
                        setValues({ ...values, youthWelfareCaseId: null });
                      }
                      setFallErr({
                        ...fallErr,
                        invalid: !FormValidator.validateDropdown(target.value),
                      });
                    }}
                    onBlur={() => {
                      setFallErr({
                        ...fallErr,
                        invalid: !FormValidator.validateDropdown(
                          values.youthWelfareCaseId
                        ),
                      });
                    }}
                  />
                ) : null} */}

                <FormCombobox
                  error={terminArtErr.invalid}
                  errormsg={terminArtErr.text}
                  filterFn={(item, query) =>
                    `${item.name?.toLowerCase()}`.includes(
                      query.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                  label='Terminart'
                  name='appointmentType'
                  onBlur={() => {
                    setTerminArtErr({
                      ...terminArtErr,
                      invalid: !FormValidator.validateText(
                        values.appointmentType
                      ),
                    });
                  }}
                  onChange={(selected) => {
                    if (selected) {
                      setValues({ ...values, appointmentType: selected });
                    } else {
                      setValues({ ...values, appointmentType: null });
                    }
                    setTerminArtErr({
                      ...terminArtErr,
                      invalid: !FormValidator.validateText(selected),
                    });
                  }}
                  optionToKey={(item) => item.id.toString()}
                  optionToString={(item) => (item ? `${item.name}` : '')}
                  optionToValue={(item) => item.id}
                  options={[
                    { id: 'DIRECT', name: 'Direkt' },
                    { id: 'INDIRECT', name: 'Indirekt' },
                    { id: 'INTERNAL', name: 'Praxis' },
                  ]}
                  selected={values.appointmentType}
                />

                {/* <Dropdown
                  id='appointmentType'
                  name='appointmentType'
                  label='Terminart'
                  errorLabel={terminArtErr.text}
                  error={terminArtErr.invalid}
                  value={values.appointmentType || ''}
                  options={[
                    { id: 'DIRECT', name: 'Direkt' },
                    { id: 'INDIRECT', name: 'Indirekt' },
                    { id: 'INTERNAL', name: 'Praxis' },
                  ]}
                  optionToValue={(item) => item.id}
                  optionToString={(item) => item.name}
                  onChange={({ target }) => {
                    const selectedObj = [
                      'DIRECT',
                      'INDIRECT',
                      'INTERNAL',
                    ].filter((item) => item == target.value)[0];
                    if (selectedObj) {
                      setValues({ ...values, appointmentType: selectedObj });
                    } else {
                      setValues({ ...values, appointmentType: null });
                    }
                    setTerminArtErr({
                      ...terminArtErr,
                      invalid: !FormValidator.validateText(selectedObj),
                    });
                  }}
                  onBlur={() => {
                    setTerminArtErr({
                      ...terminArtErr,
                      invalid: !FormValidator.validateText(
                        values.appointmentType
                      ),
                    });
                  }}
                /> */}

                <TextInput
                  type='date'
                  id='date'
                  name='date'
                  error={datumErr.invalid}
                  errorLabel={datumErr.text}
                  label='Datum'
                  value={
                    values.date
                      ? values.id
                        ? DateTime.fromISO(values.date).toSQLDate()
                        : values.date
                      : ''
                  }
                  onChange={({ target }) => {
                    setValues({
                      ...values,
                      [target.name]: target.value,
                    });
                    setDatumErr({
                      ...datumErr,
                      invalid: !FormValidator.validateDate(target.value),
                    });
                  }}
                  onBlur={() => {
                    setDatumErr({
                      ...datumErr,
                      invalid: !FormValidator.validateDate(values.date),
                    });
                  }}
                />

                <TextInput
                  type='time'
                  id='startTime'
                  name='startTime'
                  label='Termin Begin'
                  errorLabel={terminBeginErr.text}
                  error={terminBeginErr.invalid}
                  value={values.startTime}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                    setTerminBeginErr({
                      ...terminBeginErr,
                      invalid: !FormValidator.validateTimeQuarterly(
                        target.value
                      ),
                    });
                  }}
                  onBlur={() => {
                    setTerminBeginErr({
                      ...terminBeginErr,
                      invalid: !FormValidator.validateTimeQuarterly(
                        values.startTime
                      ),
                    });
                  }}
                />

                <TextInput
                  type='time'
                  id='endTime'
                  name='endTime'
                  label='Termin Ende'
                  errorLabel={terminEndeErr.text}
                  error={terminEndeErr.invalid}
                  value={values.endTime}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                    setTerminEndeErr({
                      ...terminEndeErr,
                      invalid: !FormValidator.validateTimeQuarterly(
                        target.value
                      ),
                    });
                  }}
                  onBlur={() => {
                    setTerminEndeErr({
                      ...terminEndeErr,
                      invalid: !FormValidator.validateTimeQuarterly(
                        values.endTime
                      ),
                    });
                  }}
                />

                <TextInput
                  type='text'
                  id='location'
                  name='location'
                  label='Ort'
                  value={values.location}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='pointOfDeparture'
                  name='pointOfDeparture'
                  label='Abfahrtort'
                  value={values.pointOfDeparture}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='number'
                  id='kilometers'
                  name='kilometers'
                  label='Kilometer'
                  errorLabel={kilometerErr.text}
                  error={kilometerErr.invalid}
                  value={values.kilometers}
                  onChange={({ target }) => {
                    setValues({
                      ...values,
                      [target.name]: Number.parseFloat(target.value),
                    });
                    setKilometerErr({
                      ...kilometerErr,
                      invalid: !FormValidator.validateNumber(target.value),
                    });
                  }}
                  onBlur={() => {
                    setKilometerErr({
                      ...kilometerErr,
                      invalid: !FormValidator.validateNumber(values.kilometers),
                    });
                  }}
                />

                <Checkbox
                  id='isCommute'
                  name='isCommute'
                  label='Als Arbeitsweg markieren?'
                  checked={values.isCommute}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.checked });
                  }}
                />

                <Textarea
                  id='contactActivity'
                  name='contactActivity'
                  label='Kontaktart'
                  value={values.contactActivity}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <Textarea
                  id='activity'
                  name='activity'
                  label='Tätigkeit'
                  value={values.activity}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <Textarea
                  id='documentation'
                  name='documentation'
                  label='Kurz Dokumentation'
                  value={values.documentation}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />
              </div>
              <div className='bg-gray-50 px-4 py-3 sm:flex sm:items-center sm:px-6'>
                <button
                  type='button'
                  onClick={() => history.push(values.id ? '..' : '.')}
                  className='mt-3 w-full inline-flex justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:w-auto sm:text-sm'
                >
                  Abbrechen
                </button>
                <button
                  type='submit'
                  className='mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                >
                  Speichern
                </button>
                <div className='ml-3'>
                  {onSubmit.isLoading ? (
                    <span>In Bearbeitung...</span>
                  ) : onSubmit.isSuccess ? (
                    <span>Erfolgreich gespeichert.</span>
                  ) : onSubmit.isError ? (
                    <span>Fehler aufgetretten.</span>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
          {/* ANALYTICS */}
          <div id='appointment-statistics' className='mt-5'>
            <StatisticsPanel user={user} />
          </div>
        </div>

        {/* Terminübersicht */}
        <div className='flex flex-col mt-5 md:mt-0 md:col-span-1 gap-6'>
          <EfficacyratePanel
            youthWelfareCaseId={values.youthWelfareCaseId}
            specialistId={values.specialistId}
            date={values.date || moment().format('YYYY-MM-DD')}
            durationToBeAdded={appointmentDuration}
            appointmentTypeToBeAdded={values.appointmentType}
          />
          <AppointmentPanel
            user={user}
            specialistId={values.specialistId}
            youthWelfareCaseId={values.youthWelfareCaseId}
          />
        </div>
      </div>
    </>
  );
}

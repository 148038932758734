import 'regenerator-runtime/runtime';
import React from 'react';
import { useAsyncDebounce } from 'react-table';
import { matchSorter } from 'match-sorter';
import TextInput from '../Form/TextInput/TextInput';

/**
 * Filter UI element for text based filtering and
 * filters through all rows regardless of already
 * active filters
 * @param {Array<Object>} preGlobalFilteredRows
 * @param {String} globalFilter
 * @param {Function} setGlobalFilter
 * @returns
 */
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <TextInput
      type='text'
      placeholder={`Durchsuchen von ${count} Datensätze ...`}
      value={value || ''}
      fullWidth={true}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    />

    // <input

    //   className='px-5 py-2 overflow-hidden border-b border-gray-200 sm:rounded-lg'
    //   placeholder={`Durchsuchen von ${count} Datensätze ...`}

    // />
  );
}

/**
 * Filter UI element for text based filtering and filters
 * through a specific column
 *
 * @param {*} param0
 * @returns JSX.Element
 */
function ColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const [value, setValue] = React.useState('');

  return (
    <div className='flex'>
      <input
        type='text'
        className='w-full'
        value={
          filterValue
            ? filterValue.replace(/\|startsWith|\|startsNotWith/, '')
            : ''
        }
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          setValue(e.target.value);
        }}
        placeholder={`Spaltenfilter...`}
      />
      <select
        onChange={(e) => {
          setFilter(`${value}|${e.target.value}`);
        }}
      >
        <option value='startsWith'>==</option>
        <option value='startsNotWith'>!=</option>
      </select>
    </div>
  );
}

/**
 * Filter UI element for selecting a unique option
 * from a list and filters trhough a specific column
 *
 * @param {*} param0
 * @returns JSX.Element
 */
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value=''>All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, {
    keys: [(row) => row.values[id]],
  });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

export default {
  GlobalFilter,
  ColumnFilter,
  SelectColumnFilter,
  fuzzyTextFilterFn,
};

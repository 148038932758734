import React, { useState } from 'react';
import Cookies from 'js-cookie';
import {
  signInRequest,
  signOutRequest,
} from '../../_api/authentication.services';
import { AuthenticationContext } from './AuthenticationContext';

export default function AuthenticationProvider({ children }) {
  const cookie = Cookies.get('x-remote-user');
  const slicedCookie = cookie?.slice(2);
  let [user, setUser] = useState(
    slicedCookie ? { ...JSON.parse(slicedCookie) } : null
  );
  let value = { user, signIn, signOut, isAdmin };

  /**
   *
   */
  async function signIn(username, password) {
    const response = await signInRequest({ username, password });
    if (response.status) {
      const cookie = Cookies.get('x-remote-user');
      if (cookie != undefined) {
        const slicedCookie = cookie.slice(2);
        setUser({ ...JSON.parse(slicedCookie) });
      }
    }
    return response;
  }

  /**
   *
   * @returns
   */
  async function signOut() {
    const response = await signOutRequest();
    if (response.status) {
      setUser(null);
    }
    return response;
  }

  function isAdmin() {
    if (user?.role == 'ADMIN') {
      return true;
    } else {
      return false;
    }
  }

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
}

import {
  faFilePen,
  faPlus,
  faSort,
  faSortDown,
  faSortUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/solid';
import moment from 'moment';
import React, { useState } from 'react';
import { useGlobalFilter, useTable } from 'react-table';
import { PrimaryButton } from '../_components/Button';
import { ConfirmationDialog } from '../_components/Dialog';
import BerichtDialog from '../_components/Dialog/BerichtDialog/BerichtDialog';
import TableFilter from '../_components/Table/TableFilter';
import TableFilterDialog from '../_components/Table/TableFilterDialog';
import TablePrimaryButton from '../_components/Table/TablePrimaryButton';
import endpoints from '../_helpers/endpoints';

/**
 *
 * @param {{title: string, data: Array, columns: Array, history: History<unknown>}} param0
 * @returns
 */
export default function PatietenTerminTabelle({
  user,
  title,
  data,
  exportRow,
  deleteRow,
  columns,
  setWhere,
  setLimit,
  limit,
  pageCount,
  setPage,
  page,
  setOrder,
  order,
  match,
  history,
}) {
  const { path } = match;
  const [density, setDensity] = useState(2);

  function handleSetOrder(headerId) {
    if (headerId == order.id) {
      if (order.sortDirection == undefined) {
        setOrder({ id: headerId, sortDirection: 'desc' });
      } else if (order.sortDirection == 'desc') {
        setOrder({ id: headerId, sortDirection: 'asc' });
      } else {
        setOrder({ id: headerId, sortDirection: undefined });
      }
    } else {
      setOrder({ id: headerId, sortDirection: 'desc' });
    }
  }

  const sortTypes = React.useMemo(() => ({
    date: (row1, row2, columnName) => {
      const rowOneColumn = moment(row1.values[columnName], 'DD.MM.YYYY');
      const rowTwoColumn = moment(row2.values[columnName], 'DD.MM.YYYY');
      // console.log(row1.values[columnName], row2.values[columnName]);
      // console.log('sorting date');
      if (rowOneColumn.isValid() && rowTwoColumn.isValid()) {
        // console.log(rowOneColumn > rowTwoColumn);
        return rowOneColumn > rowTwoColumn ? 1 : -1;
      }
    },
  }));

  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const initialSortBy = React.useMemo(() => [{ id: 'Datum', desc: true }]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useGlobalFilter,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: 'actions',
          Header: 'Aktionen',
          canSort: false,
          Cell: ({ data, row }) => (
            <div className='flex w-min flex-row items-center justify-end gap-x-1 py-0'>
              <TablePrimaryButton
                onClick={() => history.push(`${path}/edit/${data[row.id].id}`)}
              >
                <FontAwesomeIcon icon={faFilePen} />
              </TablePrimaryButton>
              <ConfirmationDialog
                title='Datensatz Löschung'
                text='Sind Sie sicher, dass Sie folgenden Datensatz löschen wollen?'
                data={data[row.id]}
                primaryButtonText={<FontAwesomeIcon icon={faTrash} />}
                primaryAction={() => deleteRow.mutate(data[row.id].id)}
                primaryActionButtonText='Löschen'
              />
            </div>
          ),
        },
      ]);
    }
  );

  return (
    <>
      <div className='mb-4 flex flex-row text-xl font-semibold'>
        Klienten Termine
      </div>
      <div className='mb-2 flex flex-col gap-2 xl:flex-row'>
        <TableFilter.GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state?.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div className='flex flex-row gap-1'>
          <TableFilterDialog setWhereFn={setWhere} />
          <BerichtDialog endpoint={endpoints.appointment} user={user} />
          <PrimaryButton
            type='button'
            onClick={() => history.push(`${path}/add`)}
          >
            <FontAwesomeIcon className='mr-2' icon={faPlus} />
            <span>Neu</span>
          </PrimaryButton>
        </div>
      </div>
      <div className='w-full overflow-x-auto rounded-lg border border-gray-300 bg-gray-100 shadow-sm'>
        <table className='w-full' {...getTableProps()}>
          <thead className='w-full border-b border-gray-300 text-left'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  if (column.id == 'actions') {
                    return (
                      <th scope='col' className={`w-12`}>
                        <div
                          className={`flex flex-row w-full items-center px-2 py-2`}
                        >
                          <span className='flex flex-row line-clamp-1'>
                            {column.render('Header')}
                          </span>
                        </div>
                      </th>
                    );
                  } else {
                    return (
                      <th
                        className={`w-min cursor-pointer select-none`}
                        onClick={() => {
                          handleSetOrder(column.id);
                        }}
                      >
                        <div className={`flex flex-row items-center px-2 py-2`}>
                          <span className='flex flex-row line-clamp-1'>
                            {column.render('Header')}
                          </span>

                          {column.canSort == false ? null : order.id ==
                            column.id ? (
                            order.sortDirection == 'desc' ? (
                              <FontAwesomeIcon
                                className='ml-2'
                                icon={faSortDown}
                              />
                            ) : order.sortDirection == 'asc' ? (
                              <FontAwesomeIcon
                                className='ml-2'
                                icon={faSortUp}
                              />
                            ) : (
                              <FontAwesomeIcon className='ml-2' icon={faSort} />
                            )
                          ) : (
                            <FontAwesomeIcon className='ml-2' icon={faSort} />
                          )}
                        </div>
                      </th>
                    );
                  }
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i}
                  className={`hover:bg-gray-200 ${
                    i % 2 == 0 ? 'bg-white' : 'bg-gray-100'
                  }`}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={`px-2 text-sm py-${density}`}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
        <div className='flex flex-row px-2 items-center gap-4 w-full border-t border-gray-300'>
          <div className='flex flex-row items-center'>
            Anzahl Einträge pro Seite
            <select
              className='ml-2'
              value={limit}
              onChange={(e) => {
                setLimit(Number(e.target.value));
              }}
            >
              {[25, 50, 75, 100].map((limit) => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
            </select>
          </div>

          <div className='flex flex-row items-center gap-1 ml-auto'>
            <button
              className='p-3 hover:bg-gray-200 rounded-lg'
              onClick={() => setPage(0)}
            >
              <ChevronDoubleLeftIcon className='w-4 h-4' />
            </button>
            <button
              className='p-3 hover:bg-gray-200 rounded-lg'
              onClick={() => setPage((page - 1) % pageCount)}
            >
              <ChevronLeftIcon className='w-4 h-4' />
            </button>
            <button
              className='p-3 hover:bg-gray-200 rounded-lg'
              onClick={() => setPage((page + 1) % pageCount)}
            >
              <ChevronRightIcon className='w-4 h-4' />
            </button>
            <button
              className='p-3 hover:bg-gray-200 rounded-lg'
              onClick={() => setPage(pageCount - 1)}
            >
              <ChevronDoubleRightIcon className='w-4 h-4' />
            </button>
            <span className='-ml-1'>
              Seite{' '}
              <strong>
                {page + 1} von {pageCount}
              </strong>{' '}
            </span>
          </div>

          <span>
            Sprung zu Seite:{' '}
            <input
              type='number'
              defaultValue={page + 1}
              onChange={(e) => {
                const limit = e.target.value ? Number(e.target.value) - 1 : 0;
                setPage(limit);
              }}
              style={{ width: '100px' }}
            />
          </span>
        </div>
      </div>
    </>
  );
}

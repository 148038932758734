export default function createObjectFromDotNotation(str, value) {
  const keys = str.split('.');
  const lastIndex = keys.length - 1;
  let obj = {};

  keys.reduce((acc, key, index) => {
    if (index === lastIndex) {
      acc[key] = value;
    } else {
      acc[key] = {};
    }

    return acc[key];
  }, obj);

  return obj;
}

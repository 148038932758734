import { Menu } from '@headlessui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavigationMenuLink({ children, to }) {
  return (
    <Menu.Item>
      <NavLink
        to={to}
        className={({ isActive }) =>
          `flex w-full flex-row items-center px-4 py-2 hover:bg-emerald-700 ${
            isActive ? 'bg-emerald-700' : ''
          }`
        }
      >
        {children}
      </NavLink>
    </Menu.Item>
  );
}

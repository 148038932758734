import endpoints from '../_helpers/endpoints';
import { get, patch, post, _create, _delete } from './_axios';

/**
 *
 * @param {{}} body
 * @returns {{}}
 */
export async function create(body) {
  const { data } = await _create(`${endpoints.qualification}`, body);
  return data;
}

/**
 *
 * @returns {[{}]}
 */
export async function findAll() {
  const { data } = await get(`${endpoints.qualification}`);
  return data;
}

/**
 *
 * @param {number} id
 * @returns {{}}
 */
export async function findById(id) {
  const { data } = await post(`${endpoints.qualification}/findById`, {
    id,
  });
  return data;
}

/**
 *
 * @param {{}} body
 * @returns {{}}
 */
export async function updateById(body) {
  const { data } = patch(`${endpoints.qualification}/updateById`, body);
  return data;
}

/**
 *
 * NOTE: For some reason request with delete doesn't send session cookie
 * @param {number} id
 * @returns {{message: string}}
 */
export async function deleteById(id) {
  const { data } = _delete(`${endpoints.qualification}/deleteById`, {
    id,
  });
  return data;
}

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import {
  deleteById,
  findAllAsAdminOrBySpecialistId,
  getPageCountAppointment,
} from '../_api/appointment.services';
import FormWrapper from '../_components/Form/FormWrapper';
import endpoints from '../_helpers/endpoints';
import PatientenTerminForm from './PatientenTerminForm';
import Table from './PatientenTerminTabelle';

const columns = [
  {
    id: 'youthWelfareCase.identifier',
    accessor: 'youthWelfareCase.identifier',
    Header: 'Fallbezeichnung',
    canSort: true,
  },
  {
    id: 'specialist.lastname',
    accessor: (cell) =>
      `${cell.specialist.firstname} ${cell.specialist.lastname}`,
    Header: 'Fachkraft',
    canSort: true,
  },
  {
    id: 'date',
    accessor: (cell) => moment(cell.date).format('DD.MM.YYYY'),
    Header: 'Datum',
    sortType: 'date',
    canSort: true,
  },
  {
    id: 'startTime',
    accessor: 'startTime',
    Header: 'Termin Begin',
    canSort: true,
  },
  { id: 'endTime', accessor: 'endTime', Header: 'Termin Ende', canSort: true },
  {
    id: 'appointmentType',
    accessor: (cell) => {
      switch (cell.appointmentType) {
        case 'DIRECT':
          return 'Direkt';
        case 'INDIRECT':
          return 'Indirekt';
        case 'INTERNAL':
          return 'Praxis';
        default:
          return '';
      }
    },
    Header: 'Terminart',
    canSort: true,
  },
  {
    id: 'contactActivity',
    accessor: 'contactActivity',
    Header: 'Kontaktart',
    canSort: true,
  },
  // { accessor: 'activity', Header: 'Tätigkeit' },
  {
    id: 'createdAt',
    accessor: (cell) => moment(cell.createdAt).format('DD.MM.YYYY'),
    Header: 'Erstellt am',
    sortType: 'date',
    canSort: true,
  },
  {
    id: 'updatedAt',
    accessor: (cell) => moment(cell.updatedAt).format('DD.MM.YYYY'),
    Header: 'Geändert am',
    sortType: 'date',
    canSort: true,
  },
];

export default function Appointment({ match, history, user }) {
  const { path } = match;
  const queryClient = useQueryClient();
  const endpoint = endpoints.appointment;
  const queryKey = 'Appointments';
  const [where, setWhere] = useState(undefined);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [order, setOrder] = useState({
    id: 'date',
    sortDirection: 'desc',
  });

  const { status, data } = useQuery([queryKey, page, limit, order], () => {
    return findAllAsAdminOrBySpecialistId(where, page, limit, order);
  });

  const deleteRow = useMutation((id) => deleteById(Number.parseInt(id)), {
    onSuccess: () => {
      // alert('Löschung erfolgreich');
    },
    onError: (error) => {
      alert(error);
    },
    // After success or failure, refetch the query
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  useEffect(async () => {
    const data = await findAllAsAdminOrBySpecialistId(
      where,
      page,
      limit,
      order
    );
    const pageCount = await getPageCountAppointment(where, page, limit);
    setFilteredData(data);
    setPageCount(pageCount);
  }, [where, page, limit, order]);

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => {
          if (status == 'loading') {
            return <div>Datensätze werden geladen...</div>;
          }

          if (status == 'error') {
            return (
              <div>Beim Laden der Datensätze ist ein Fehler aufgetretten.</div>
            );
          }

          return (
            <Table
              user={user}
              title='Klienten Termine'
              data={where != undefined && filteredData ? filteredData : data}
              deleteRow={deleteRow}
              columns={columns}
              setWhere={setWhere}
              pageCount={pageCount}
              setPage={setPage}
              page={page}
              setLimit={setLimit}
              limit={limit}
              setOrder={setOrder}
              order={order}
              match={match}
              history={history}
            />
          );
        }}
      />
      <Route
        path={`${path}/add`}
        render={({ match, history }) => (
          <FormWrapper
            Form={PatientenTerminForm}
            endpoint={endpoint}
            queryKey='Appointments'
            match={match}
            history={history}
            user={user}
          />
        )}
      />
      <Route
        path={`${path}/edit/:id`}
        render={({ match, history }) => (
          <FormWrapper
            Form={PatientenTerminForm}
            endpoint={endpoint}
            queryKey='Appointments'
            match={match}
            history={history}
            user={user}
          />
        )}
      />
    </Switch>
  );
}

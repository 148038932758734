import axios from 'axios';
import endpoints from '../_helpers/endpoints';
import { _delete, _create, get, patch, post } from './_axios';
import qs from 'qs';
import createObjectFromDotNotation from '../_helpers/createObjectFromDotNotation';

/**
 *
 * @param {{}} body
 * @returns {{}}
 */
export async function create(body) {
  const { data } = await _create(`${endpoints.appointment}`, body);
  return data;
}

/**
 *
 * @returns {[{}]}
 */
export async function findAll() {
  const { data } = await get(`${endpoints.appointment}`);
  return data;
}

/**
 *
 * @returns {[{}]}
 */
export async function findAllAsAdminOrBySpecialistId(
  where,
  page,
  limit,
  order
) {
  const parsedOrderBy = createObjectFromDotNotation(
    order.id ? order.id : '',
    order.sortDirection
  );
  const query = qs.stringify({ ...where, page, limit, orderBy: parsedOrderBy });
  const { data } = await get(
    `${endpoints.appointment}/findAllAsAdminOrBySpecialistId?${query}`
  );
  return data;
}

/**
 *
 * @returns Number
 */
export async function getPageCountAppointment(where, page, limit) {
  const query = qs.stringify({ ...where, page, limit });
  const { data } = await get(`${endpoints.appointment}/getPageCount?${query}`);
  return data;
}

/**
 *
 * @returns {[{}]}
 */
export async function findAllAsAdminOrBySpecialistIdFiltered(where, limit) {
  const { data } = await post(
    `${endpoints.appointment}/findAllAsAdminOrBySpecialistIdFiltered`,
    where
  );
  return data;
}

/**
 *
 * @param {number} youthWelfareCaseId
 * @returns {[{}]}
 */
export async function findAllByYouthWelfareCaseId(youthWelfareCaseId) {
  const { data } = await post(
    `${endpoints.appointment}/findAllByYouthWelfareCaseId`,
    { youthWelfareCaseId }
  );
  return data;
}

/**
 *
 * @param {number} id
 * @returns {{}}
 */
export async function findById(id) {
  const { data } = await post(`${endpoints.appointment}/findById`, { id });
  return data;
}

/**
 *
 * @param {number} specialistId
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns
 */
export async function reportTravel(specialistId, startDate, endDate) {
  const { data } = await post(`${endpoints.appointment}/report/travel`, {
    specialistId,
    startDate,
    endDate,
  });
  return data;
}

/**
 *
 * @param {number} specialistId
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns
 */
export async function reportYouthWelfareCaseBySpecialistId(
  specialistId,
  youthWelfareCaseId,
  startDate,
  endDate
) {
  const { data } = await post(
    `${endpoints.appointment}/report/youthWelfareCaseBySpecialistId`,
    {
      specialistId,
      youthWelfareCaseId,
      startDate,
      endDate,
    }
  );
  return data;
}

/**
 *
 * @param {number} specialistId
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns
 */
export async function reportYouthWelfareCasesBySpecialistId(
  specialistId,
  startDate,
  endDate
) {
  const { data } = await post(
    `${endpoints.appointment}/report/youthWelfareCasesBySpecialistId`,
    { specialistId, startDate, endDate }
  );
  return data;
}

/**
 *
 * @param {number} specialistId
 * @param {number} youthWelfareCaseId
 * @param {Date} date
 * @returns
 */
export async function reportStatisticsBySpecialistIdAndYouthWelfareIdAndDate(
  specialistId,
  youthWelfareCaseId,
  date,
  selectedMonth
) {
  const { data } = await post(
    `${endpoints.appointment}/report/statisticsBySpecialistIdAndYouthWelfareIdAndDate`,
    { specialistId, youthWelfareCaseId, date, selectedMonth }
  );
  return data;
}

/**
 *
 * @param {number} specialistId
 * @param {number} youthWelfareCaseId
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns
 */
export async function reportHoursBySpecialistIdAndYouthWelfareCaseIdAndStartDateAndEndDate(
  specialistId,
  youthWelfareCaseId,
  startDate,
  endDate
) {
  const { data } = await post(
    `${endpoints.appointment}/report/hoursBySpecialistIdAndYouthWelfareCaseIdAndStartDateAndEndDate`,
    { specialistId, youthWelfareCaseId, startDate, endDate }
  );
  return data;
}

/**
 *
 * @param {number} specialistId
 * @param {number} youthWelfareCaseId
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns
 */
export async function reportHoursByStartDateAndEndDate(
  specialistId,
  youthWelfareCaseId,
  startDate,
  endDate
) {
  const { data } = await post(
    `${endpoints.appointment}/report/hoursByStartDateAndEndDate`,
    { specialistId, youthWelfareCaseId, startDate, endDate }
  );
  return data;
}

/**
 *
 * @param {number} specialistId
 * @param {number} youthWelfareCaseId
 * @returns
 */
export async function reportHoursByYear(specialistId, youthWelfareCaseId) {
  const { data } = await post(`${endpoints.appointment}/report/hoursByYear`, {
    specialistId,
    youthWelfareCaseId,
  });
  return data;
}

/**
 *
 * @param {{}} body
 * @returns {{}}
 */
export async function updateById(body) {
  const { data } = patch(`${endpoints.appointment}/updateById`, body);
  return data;
}

/**
 *
 * NOTE: For some reason request with delete doesn't send session cookie
 * @param {number} id
 * @returns {{message: string}}
 */
export async function deleteById(id) {
  const { data } = _delete(`${endpoints.appointment}/deleteById`, { id });
  return data;
}

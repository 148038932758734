import React from 'react';

/**
 *
 * @param {{type: React.HTMLInputTypeAttribute, id: string, name: string, label: string, autoComplete: string, value: string | number | readonly string[], onChange: React.ChangeEventHandler<HTMLInputElement>}} props
 * @returns {JSX.Element} JSX.Element
 */
export default function Textarea(props) {
  const { label, ...rest } = props;
  return (
    <div className='col-span-6 sm:col-span-3'>
      <label
        htmlFor={rest.id}
        className='block text-sm font-medium text-gray-700'
      >
        {label}
      </label>
      <textarea
        {...rest}
        className='mt-1 h-32 focus:ring-emerald-500 focus:border-emerald-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
      />
    </div>
  );
}

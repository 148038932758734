import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import {
  reportHoursByStartDateAndEndDate,
  reportHoursByYear,
} from '../_api/appointment.services';
import { findAllAsAdminOrBySpecialistId } from '../_api/youthWelfareCase.services';
import Dropdown from '../_components/Form/Dropdown/Dropdown';
import TextInput from '../_components/Form/TextInput/TextInput';
import endpoints from '../_helpers/endpoints';
import { findAll } from '../_api/specialist.services';
import StatisticsTable from '../PatientenTermin/StatisticsPanel/StatisticsTable';
import { reportHoursBySpecialistIdAndYouthWelfareCaseIdAndStartDateAndEndDate } from '../_api/appointment.services';

/**
 *
 * @param {{data: Object, onSubmit: UseMutationResult<AxiosResponse<unknown, any>, unknown, void, unknown>}} props
 * @returns
 */
export default function StatisticsPanel({ user }) {
  let [specialistId, setSpecialistId] = React.useState();
  let [youthWelfareCaseId, setYouthWelfareCaseId] = React.useState(null);
  let [identifier, setIdentifier] = React.useState(null);
  let [startDate, setStartDate] = React.useState(null);
  let [endDate, setEndDate] = React.useState(null);

  let [fallStatistikRange, setFallStatistikRange] = React.useState([]);
  let [fallStatistikYear, setFallStatistikYear] = React.useState([]);

  const {
    status: youthWelfareCaseQueryStatus,
    data: youthWelfareCaseDropdown,
  } = useQuery('YouthWelfareCaseDropdown', () =>
    findAllAsAdminOrBySpecialistId(specialistId)
  );

  const { status: specialistQueryStatus, data: specialistDropdown } = useQuery(
    'SpecialistDropdown',
    () => findAll()
  );

  React.useEffect(() => {
    if (
      moment(startDate, 'YYYY-MM-DD').isValid() &&
      moment(endDate, 'YYYY-MM-DD').isValid()
    ) {
      reportHoursBySpecialistIdAndYouthWelfareCaseIdAndStartDateAndEndDate(
        specialistId,
        youthWelfareCaseId,
        startDate,
        endDate
      ).then((data) => {
        setFallStatistikRange(data);
      });
    }

    if (youthWelfareCaseId) {
      reportHoursByYear(specialistId, youthWelfareCaseId).then((data) => {
        setFallStatistikYear(data);
      });
    }
  }, [specialistId, youthWelfareCaseId, startDate, endDate]);

  return (
    <div className='px-4'>
      <div className='shadow sm:rounded-md sm:overflow-hidden'>
        <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
          <div className='overflow-hidden'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              Statistik
            </h3>
            <p className='mt-1 text-sm text-gray-600'>
              Wählen Sie eine Fachkraft oder/und einen Fall aus um entsprechende
              Statistiken einsehen zu können.
            </p>
          </div>
          <hr />
          <div className='flex flex-col lg:flex-row gap-6'>
            <div className='flex lg:flex-row flex-col gap-6 w-full'>
              {specialistQueryStatus == 'success' ? (
                <div className='w-full'>
                  <Dropdown
                    id='specialistId'
                    name='specialistId'
                    label='Fachkraft'
                    value={specialistId || ''}
                    options={specialistDropdown || []}
                    optionToValue={(item) => item.id}
                    optionToString={(item) =>
                      `${item.firstname} ${item.lastname}`
                    }
                    onChange={({ target }) => {
                      const selectedObj = specialistDropdown.filter(
                        (item) => item.id == target.value
                      )[0];
                      if (selectedObj) {
                        setSpecialistId(selectedObj.id);
                      } else {
                        setSpecialistId(null);
                      }
                    }}
                  />
                </div>
              ) : null}
              {youthWelfareCaseQueryStatus == 'success' ? (
                <div className='w-full'>
                  <Dropdown
                    id='statistics_fall'
                    name='statistics_fall'
                    label='Fall'
                    value={youthWelfareCaseId || ''}
                    options={youthWelfareCaseDropdown || []}
                    optionToValue={(item) => item.id}
                    optionToString={(item) => item.identifier}
                    onChange={({ target }) => {
                      const selectedObj = youthWelfareCaseDropdown.filter(
                        (item) => item.id == target.value
                      )[0];
                      if (selectedObj) {
                        setYouthWelfareCaseId(selectedObj.id);
                      } else {
                        setYouthWelfareCaseId(null);
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>

            <div className='flex flex-col lg:flex-row gap-6 w-full'>
              <div className='w-full'>
                <TextInput
                  type='date'
                  id='startDate'
                  name='startDate'
                  label='Anfangsdatum'
                  value={startDate || ''}
                  onChange={({ target }) => {
                    setStartDate(moment(target.value).format('YYYY-MM-DD'));
                  }}
                />
              </div>

              <div className='w-full'>
                <TextInput
                  type='date'
                  id='endDate'
                  name='endDate'
                  label='Enddatum'
                  value={endDate || ''}
                  onChange={({ target }) => {
                    setEndDate(moment(target.value).format('YYYY-MM-DD'));
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className='overflow-x-auto'>
              Stunden zum Fall {identifier || ''} im Zeitraum{' '}
              {moment(startDate).isValid()
                ? moment(startDate).format('DD.MM.')
                : 'n.a.'}{' '}
              -{' '}
              {moment(endDate).isValid()
                ? moment(endDate).format('DD.MM.')
                : 'n.a.'}
              <StatisticsTable
                data={[fallStatistikRange]}
                columns={[
                  { accessor: 'billable', Header: 'Abrechenbar' },
                  { accessor: 'nonbillable', Header: 'N. Abrechenbar' },
                  { accessor: 'direct', Header: 'Direkt' },
                  { accessor: 'indirect', Header: 'Indirekt' },
                  { accessor: 'internal', Header: 'Praxis' },
                  { accessor: 'used', Header: 'Gesamt Dauer' },
                ]}
              />
            </div>
            {/* Jahres Ansicht
            <div className='overflow-x-auto'>
              <StatisticsTable
                data={fallStatistikYear}
                columns={[
                  { accessor: 'month', Header: 'Monat' },
                  { accessor: 'billable', Header: 'Abrechenbar' },
                  { accessor: 'nonbillable', Header: 'N. Abrechenbar' },
                  { accessor: 'direct', Header: 'Direkt' },
                  { accessor: 'indirect', Header: 'Indirekt' },
                  { accessor: 'internal', Header: 'Praxis' },
                  { accessor: 'used', Header: 'Gesamt Dauer' },
                ]}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

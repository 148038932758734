import React, { useEffect } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Combobox, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import { Fragment, useState } from 'react';
import { useQuery } from 'react-query';

export default function FormComboboxWithQuery({
  error,
  errormsg,
  filterFn,
  label,
  name,
  onBlur,
  onChange,
  optionToKey,
  optionToString,
  optionToValue,
  queryFn,
  queryKeys,
  selected,
}) {
  const [query, setQuery] = useState('');

  const { data, isLoading, isError } = useQuery(queryKeys, () => queryFn(), {
    enabled: !!queryFn,
  });

  const filteredOptions =
    query === ''
      ? data || []
      : data?.filter((item) => filterFn(item, query)) || [];

  function getObject(id) {
    return data?.filter((item) => item.id == id)?.[0];
  }

  return (
    <div className=''>
      <Combobox value={selected} onChange={onChange}>
        <div className='relative mt-1'>
          <label
            htmlFor={name}
            className={`${
              label ? 'block' : 'hidden'
            } text-sm font-medium text-gray-700`}
          >
            {label}
          </label>
          <div className={`relative`}>
            <Combobox.Input
              name={name}
              className={`${label ? 'mt-1' : ''} ${
                error
                  ? 'border-red-500 ring-red-500 focus:border-red-500 focus:ring-red-500'
                  : ''
              } block w-full rounded-lg border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm`}
              displayValue={(value) => {
                // console.log(value);
                const selected = getObject(value);
                if (selected) {
                  return optionToString(selected);
                } else {
                  return '';
                }
              }}
              onChange={(event) => setQuery(event.target.value)}
              onBlur={onBlur}
            />
            <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
              <SelectorIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </Combobox.Button>
          </div>
          <label
            htmlFor={name}
            className={`${
              error ? 'absolute block' : 'hidden'
            } text-sm font-medium text-red-700`}
          >
            {errormsg}
          </label>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {filteredOptions.length === 0 && query !== '' ? (
                <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                  Nothing found.
                </div>
              ) : (
                filteredOptions.map((option) => (
                  <Combobox.Option
                    key={optionToKey(option)}
                    value={optionToValue(option)}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-emerald-600 text-white' : 'text-gray-900'
                      }`
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {optionToString(option)}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-emerald-700'
                            }`}
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}

import React from 'react';

/**
 *
 * @param {{type: React.HTMLInputTypeAttribute, id: string, name: string, label: string, errorLabel: string, error: boolean, autoComplete: string, value: string | number | readonly string[], onChange: React.ChangeEventHandler<HTMLInputElement>}} props
 * @returns {JSX.Element} JSX.Element
 */
export default function TextInput(props) {
  const {
    error,
    errorLabel,
    fullWidth,
    label,
    name,
    onChange,
    onBlur,
    placeholder,
    type,
    value,
    ...rest
  } = props;
  return (
    <div className={`${fullWidth ? 'w-full' : ''}`}>
      <label
        htmlFor={name}
        className={`${
          label ? 'block' : 'hidden'
        } text-sm font-medium text-gray-700`}
      >
        {label}
      </label>
      <input
        type={type ? type : 'text'}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className={`${label ? 'mt-1' : ''} ${
          error
            ? 'border-red-500 ring-red-500 focus:border-red-500 focus:ring-red-500'
            : ''
        } block w-full rounded-lg border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm`}
      />
      <label
        htmlFor={name}
        className={`${
          error ? 'block' : 'hidden'
        } text-sm font-medium text-red-700`}
      >
        {errorLabel}
      </label>
    </div>
  );
}

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

export default function NavigationMenu({ children, text }) {
  return (
    <Menu as='div' className='relative w-full text-left xl:w-min'>
      {({ open }) => (
        <>
          <Menu.Button
            className={`flex w-full flex-row items-center px-4 py-2 hover:bg-emerald-700 ${
              open ? 'bg-emerald-700' : ''
            }`}
          >
            {text}
            <span className='pl-2'>
              {open ? (
                <FontAwesomeIcon icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} />
              )}
            </span>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              className={`flex w-full origin-top-left flex-col bg-emerald-600 px-4 focus:outline-none xl:absolute xl:left-0 xl:z-50 xl:w-min xl:px-0 xl:shadow-lg`}
            >
              {children}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import Dropdown from '../_components/Form/Dropdown/Dropdown';
import TextInput from '../_components/Form/TextInput/TextInput';
import endpoints from '../_helpers/endpoints';
import Textarea from '../_components/Form/Textarea/Textarea';
import { create, findById, updateById } from '../_api/referenceData.services';

/**
 *
 * @param {{data: Object, onSubmit: UseMutationResult<AxiosResponse<unknown, any>, unknown, void, unknown>}} props
 * @returns
 */
export default function PraxisForm({ history, onSubmit }) {
  const initialFormValues = {
    name: '',
    streetaddress: '',
    zipcode: '',
    city: '',
    landline: '',
    fax: '',
    website: '',
    email: '',
    taxId: '',
    bank: '',
    iban: '',
  };
  let [values, setValues] = React.useState(initialFormValues);
  const queryClient = useQueryClient();

  const { status, data } = useQuery(['ReferenceData', 1], () => findById(1));

  const addMutation = useMutation(
    (data) => {
      if (data.id) {
        return create(data);
      } else {
        return create({ ...data, id: 1 });
      }
    },
    {
      onSuccess: (data) => {
        // history.push(match.path.replace('add', `edit/${data.data.id}`));
        // history.push('.');
      },
      // After success or failure, refetch the todos query
      onSettled: () => {
        queryClient.invalidateQueries('ReferenceData');
      },
    }
  );

  const updateMutation = useMutation((data) => updateById(data), {
    // Optimistically update the cache value on mutate, but store
    // the old value and return it so that it's accessible in case of
    // an error
    onSuccess: (data, variables) => {
      // console.log(data.data, variables);
      queryClient.setQueryData(['ReferenceData', variables.id], data.data);
    },
    // After success or failure, refetch the todos query
    onSettled: () => {
      queryClient.invalidateQueries('ReferenceData');
    },
  });

  React.useEffect(() => {
    if (data) {
      setValues({ ...values, ...data });
    }
  }, [data]);

  return (
    <>
      <div className='md:grid md:grid-cols-3 md:gap-6'>
        <div className='mt-5 md:mt-0 md:col-span-2'>
          <form
            autoComplete='off'
            onSubmit={(event) => {
              event.preventDefault();
              if (values.id) {
                updateMutation.mutate(values);
              } else {
                addMutation.mutate(values);
              }
            }}
          >
            <div className='shadow sm:rounded-md sm:overflow-hidden'>
              <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                <div className='flex'>
                  <div>
                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                      Praxisdaten
                    </h3>
                    <p className='mt-1 text-sm text-gray-600'>
                      Diese Daten werden bei der Erstellung von Dokumenten
                      verwendet.
                    </p>
                  </div>
                </div>
                <hr />
                <TextInput
                  type='text'
                  id='name'
                  name='name'
                  label='Praxisname'
                  value={values.name}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='streetaddress'
                  name='streetaddress'
                  label={`Straße & Hausnummer`}
                  value={values.streetaddress}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='zipcode'
                  name='zipcode'
                  label='Postleitzahl'
                  value={values.zipcode}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='city'
                  name='city'
                  label='Ort'
                  value={values.city}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='tel'
                  id='landline'
                  name='landline'
                  label='Telefon'
                  value={values.landline}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='tel'
                  id='fax'
                  name='fax'
                  label='Fax'
                  value={values.fax}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='website'
                  name='website'
                  label='Webseite'
                  value={values.website}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='email'
                  id='email'
                  name='email'
                  label='E-Mail'
                  value={values.email}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='taxId'
                  name='taxId'
                  label='Steuernummer'
                  value={values.taxId}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='bank'
                  name='bank'
                  label='Bankname'
                  value={values.bank}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='iban'
                  name='iban'
                  label='IBAN'
                  value={values.iban}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />
              </div>
              <div className='bg-gray-50 px-4 py-3 sm:flex sm:items-center sm:px-6'>
                <button
                  type='submit'
                  className='mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:w-auto sm:text-sm'
                >
                  Speichern
                </button>
                <div className='ml-3'>
                  {onSubmit.isLoading ? (
                    <span>In Bearbeitung...</span>
                  ) : onSubmit.isSuccess ? (
                    <span>Erfolgreich gespeichert.</span>
                  ) : onSubmit.isError ? (
                    <span>Fehler aufgetretten.</span>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

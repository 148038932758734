import {
  faFilePen,
  faPlus,
  faSort,
  faSortDown,
  faSortUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlusIcon, PlusSmIcon } from '@heroicons/react/solid';
import moment from 'moment';
import React, { useState } from 'react';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import { PrimaryButton } from '../Button';
import { ConfirmationDialog } from '../Dialog';
import TableFilter from './TableFilter';
import TablePrimaryButton from './TablePrimaryButton';

/**
 *
 * @param {{title: string, data: Array, columns: Array, history: History<unknown>}} param0
 * @returns
 */
export default function Table({
  title,
  data,
  exportRow,
  deleteRow,
  columns,
  match,
  history,
}) {
  const { path } = match;
  const [density, setDensity] = useState(2);

  const sortTypes = React.useMemo(() => ({
    date: (row1, row2, columnName) => {
      const rowOneColumn = moment(row1.values[columnName], 'DD.MM.YYYY');
      const rowTwoColumn = moment(row2.values[columnName], 'DD.MM.YYYY');
      // console.log(row1.values[columnName], row2.values[columnName]);
      // console.log('sorting date');
      if (rowOneColumn.isValid() && rowTwoColumn.isValid()) {
        // console.log(rowOneColumn > rowTwoColumn);
        return rowOneColumn > rowTwoColumn ? 1 : -1;
      }
    },
  }));

  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const initialSortBy = React.useMemo(() => [{ id: 'Datum', desc: true }]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      sortTypes,
      initialState: {
        sortBy: initialSortBy,
      },
    },
    useGlobalFilter,
    useSortBy,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: 'actions',
          Header: 'Aktionen',
          canSort: false,
          Cell: ({ data, row }) => (
            <div className='flex w-min flex-row items-center justify-end gap-x-1 py-0'>
              <TablePrimaryButton
                onClick={() => history.push(`${path}/edit/${data[row.id].id}`)}
              >
                <FontAwesomeIcon icon={faFilePen} />
              </TablePrimaryButton>
              <ConfirmationDialog
                title='Datensatz Löschung'
                text='Sind Sie sicher, dass Sie folgenden Datensatz löschen wollen?'
                data={data[row.id]}
                primaryButtonText={<FontAwesomeIcon icon={faTrash} />}
                primaryAction={() => deleteRow.mutate(data[row.id].id)}
                primaryActionButtonText='Löschen'
              />
            </div>
          ),
        },
      ]);
    }
  );

  return (
    <>
      <div className='mb-4 flex flex-row text-xl font-semibold'>{title}</div>
      <div className='mb-2 flex flex-col gap-2 xl:flex-row'>
        <TableFilter.GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div className='flex flex-row gap-1'>
          <PrimaryButton
            type='button'
            onClick={() => history.push(`${path}/add`)}
          >
            <FontAwesomeIcon className='mr-2' icon={faPlus} />
            <span>Neu</span>
          </PrimaryButton>
        </div>
      </div>
      {/* <div className='px-4'>
        <h3 className='text-lg font-medium leading-6 text-gray-900 mb-2'>
          {title}
        </h3>
        <div className='flex flex-col gap-3'>
          <div>
            <PrimaryButton
              type='button'
              onClick={() => history.push(`${path}/add`)}
            >
              <PlusSmIcon className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />
              <span>Neu</span>
            </PrimaryButton>
          </div>

          <TableFilter.GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div> */}
      <div className='w-full overflow-x-auto rounded-lg border border-gray-300 bg-gray-100 shadow-sm'>
        <table className='w-full' {...getTableProps()}>
          <thead className='w-full border-b border-gray-300 text-left'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  if (column.id == 'actions') {
                    return (
                      <th
                        scope='col'
                        className={`w-12`}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <div
                          className={`flex flex-row w-full items-center px-2 py-2`}
                        >
                          <span className='flex flex-row line-clamp-1'>
                            {column.render('Header')}
                          </span>

                          {column.canSort == false ? null : column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon
                                className='ml-2'
                                icon={faSortDown}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className='ml-2'
                                icon={faSortUp}
                              />
                            )
                          ) : (
                            <FontAwesomeIcon className='ml-2' icon={faSort} />
                          )}
                        </div>
                      </th>
                    );
                  } else {
                    return (
                      <th
                        scope='col'
                        className={`w-min`}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <div className={`flex flex-row items-center px-2 py-2`}>
                          <span className='flex flex-row line-clamp-1'>
                            {column.render('Header')}
                          </span>

                          {column.canSort == false ? null : column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon
                                className='ml-2'
                                icon={faSortDown}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className='ml-2'
                                icon={faSortUp}
                              />
                            )
                          ) : (
                            <FontAwesomeIcon className='ml-2' icon={faSort} />
                          )}
                        </div>
                      </th>
                    );
                  }
                })}
              </tr>
            ))}
          </thead>
          <tbody
            className='bg-white divide-y divide-gray-200'
            {...getTableBodyProps()}
          >
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i}
                  className={`hover:bg-gray-200 ${
                    i % 2 == 0 ? 'bg-white' : 'bg-gray-100'
                  }`}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={`px-2 text-sm py-${density}`}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

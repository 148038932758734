import { DateTime } from 'luxon';
import React from 'react';
import { useQuery } from 'react-query';
import { findAll as findAllYouthWelfareCases } from '../_api/youthWelfareCase.services';
import Dropdown from '../_components/Form/Dropdown/Dropdown';
import TextInput from '../_components/Form/TextInput/TextInput';

/**
 *
 * @param {{data: Object, onSubmit: UseMutationResult<AxiosResponse<unknown, any>, unknown, void, unknown>}} props
 * @returns
 */
export default function BewilligungForm({ data, history, onSubmit }) {
  const initialFormValues = {
    startDate: null,
    endDate: null,
    chargeOutRate: 0,
    chargeOutRateFormula: '',
    budgetInHours: 0,
    budgetAllocation: 'OVERALL',
    youthWelfareCaseId: null,
  };
  let [values, setValues] = React.useState(initialFormValues);

  const {
    status: youthWelfareCaseQueryStatus,
    data: youthWelfareCasesDropdown,
  } = useQuery('YouthWelfareCasesDropdown', () => findAllYouthWelfareCases());

  React.useEffect(() => {
    if (data) {
      setValues({ ...values, ...data });
    }
  }, [data]);

  return (
    <>
      <div className='md:grid md:grid-cols-3 md:gap-6'>
        <div className='mt-5 md:mt-0 md:col-span-2'>
          <form
            autoComplete='off'
            onSubmit={(event) => {
              event.preventDefault();
              onSubmit.mutate(values);
            }}
          >
            <div className='shadow sm:rounded-md sm:overflow-hidden'>
              <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                <div className='flex'>
                  <div>
                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                      Berechnungsgrundlage
                    </h3>
                    <p className='mt-1 text-sm text-gray-600'></p>
                  </div>
                </div>
                <hr />
                {youthWelfareCaseQueryStatus != 'loading' ? (
                  youthWelfareCaseQueryStatus != 'error' ? (
                    <Dropdown
                      id='youthWelfareCaseId'
                      name='youthWelfareCaseId'
                      label='Fall'
                      value={values.youthWelfareCaseId || ''}
                      options={
                        youthWelfareCasesDropdown
                          ? youthWelfareCasesDropdown
                          : []
                      }
                      optionToValue={(item) => item.id}
                      optionToString={(item) => item.identifier}
                      onChange={({ target }) => {
                        const selectedObj = youthWelfareCasesDropdown.filter(
                          (item) => item.id == target.value
                        )[0];
                        if (selectedObj) {
                          setValues({
                            ...values,
                            youthWelfareCaseId: selectedObj.id,
                          });
                        } else {
                          setValues({ ...values, youthWelfareCaseId: null });
                        }
                      }}
                    />
                  ) : null
                ) : null}

                <TextInput
                  type='date'
                  id='startDate'
                  name='startDate'
                  label='Anfangsdatum'
                  value={
                    values.startDate
                      ? values.id
                        ? DateTime.fromISO(values.startDate).toSQLDate()
                        : values.startDate
                      : ''
                  }
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='date'
                  id='endDate'
                  name='endDate'
                  label='Enddatum'
                  value={
                    values.endDate
                      ? values.id
                        ? DateTime.fromISO(values.endDate).toSQLDate()
                        : values.endDate
                      : ''
                  }
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='number'
                  id='chargeOutRate'
                  name='chargeOutRate'
                  label='Stundensatz'
                  value={values.chargeOutRate}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='chargeOutRateFormula'
                  name='chargeOutRateFormula'
                  label='Formel zur Berechnung mit Stundensatz'
                  value={values.chargeOutRateFormula}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='number'
                  id='budgetInHours'
                  name='budgetInHours'
                  label='Stundenbudget'
                  value={values.budgetInHours}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <Dropdown
                  id='budgetAllocation'
                  name='budgetAllocation'
                  label='Stundenzuweisung'
                  value={values.budgetAllocation || ''}
                  options={['MONTHLY', 'YEARLY', 'OVERALL']}
                  optionToValue={(item) => item}
                  optionToString={(item) => {
                    switch (item) {
                      case 'MONTHLY':
                        return 'Monatlich';
                      case 'YEARLY':
                        return 'Jährlich';
                      case 'OVERALL':
                        return 'Zwischen Start- und Enddatum der Berechnungsgrundlage';
                      default:
                        return '';
                    }
                  }}
                  onChange={({ target }) => {
                    const selectedObj = ['MONTHLY', 'OVERALL'].filter(
                      (item) => item == target.value
                    )[0];
                    if (selectedObj) {
                      setValues({ ...values, budgetAllocation: selectedObj });
                    } else {
                      setValues({ ...values, budgetAllocation: null });
                    }
                  }}
                />
              </div>
              <div className='bg-gray-50 px-4 py-3 sm:flex sm:items-center sm:px-6'>
                <button
                  type='button'
                  onClick={() => history.push(values.id ? '..' : '.')}
                  className='mt-3 w-full inline-flex justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:w-auto sm:text-sm'
                >
                  Abbrechen
                </button>
                <button
                  type='submit'
                  className='mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                >
                  Speichern
                </button>
                <div className='ml-3'>
                  {onSubmit.isLoading ? (
                    <span>In Bearbeitung...</span>
                  ) : onSubmit.isSuccess ? (
                    <span>Erfolgreich gespeichert.</span>
                  ) : onSubmit.isError ? (
                    <span>Fehler aufgetretten.</span>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

import { Menu, Transition } from '@headlessui/react';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import JSZip from 'jszip';
import React, { Fragment } from 'react';
import { useQuery } from 'react-query';
import {
  useAsyncDebounce,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import Dropdown from '../_components/Form/Dropdown/Dropdown';
import TextInput from '../_components/Form/TextInput/TextInput';
import TableFilter from '../_components/Table/TableFilter';

import {
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import moment from 'moment';
import FormComboboxWithQuery from '../components/Form/Input/FormComboboxWithQuery';
import {
  exportFigureToCsv,
  findAll,
  findAllAsAdminOrBySpecialistId as findAllYouthWelfareCasesAsAdminOrBySpecialistId,
  reportFigure,
} from '../_api/youthWelfareCase.services';
import { PrimaryButton } from '../_components/Button';
import createAnlage from './print/createAnlage';
import createRechnung from './print/createRechnung';

/**
 *
 * @param {{title: string, data: Array, columns: Array, history: History<unknown>}} param0
 * @returns
 */
export default function SelectableTable({
  title,
  exportRow,
  deleteRow,
  columns,
  match,
  history,
  setDateForCalculationPrinciple,
}) {
  const { path } = match;
  let [youthWelfareCaseId, setYouthWelfareCaseId] = React.useState(null);
  let [startDate, setStartDate] = React.useState('');
  let [endDate, setEndDate] = React.useState('');
  let [startDateValue, setStartDateValue] = React.useState('');
  let [endDateValue, setEndDateValue] = React.useState('');
  let [refreshData, setRefreshData] = React.useState(false);
  let [printDate, setPrintDate] = React.useState(null);
  let [data, setData] = React.useState([]);

  let [selected, setSelected] = React.useState([]);

  const [density, setDensity] = React.useState(2);

  const sortTypes = React.useMemo(() => ({
    date: (row1, row2, columnName) => {
      const rowOneColumn = moment(row1.values[columnName], 'DD.MM.YYYY');
      const rowTwoColumn = moment(row2.values[columnName], 'DD.MM.YYYY');
      // console.log(row1.values[columnName], row2.values[columnName]);
      // console.log('sorting date');
      if (rowOneColumn.isValid() && rowTwoColumn.isValid()) {
        // console.log(rowOneColumn > rowTwoColumn);
        return rowOneColumn > rowTwoColumn ? 1 : -1;
      }
    },
  }));

  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  async function onExportToCsv(array) {
    const ids = array.map((element) => element.original.id);
    const { data: csv } = await exportFigureToCsv(ids, startDate, endDate);

    const a = document.createElement('a');
    a.style = 'display: none';
    a.href = `data:text/charset=utf-8,${encodeURI(csv)}`;
    a.download = `fall_export_${moment(startDate, 'YYYY-MM-DD').format(
      'DDMMYYYY'
    )}${moment(endDate, 'YYYY-MM-DD').format('DDMMYYYY')}.csv`;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async function onCreateRechnungAnlage(array) {
    let zip = new JSZip();

    if (array.length == 1) {
      let doc = new jsPDF();
      const youthWelfareCase = array[0].original;
      doc = await createRechnung(
        youthWelfareCase,
        startDate,
        endDate,
        printDate,
        doc
      );
      doc.addPage('a4', 'portrait');
      doc = await createAnlage(youthWelfareCase, doc);
      saveDoc(youthWelfareCase, doc, 'RechnungMitAnlage');
    } else {
      for (let index = 0; index < array.length; index++) {
        let doc = new jsPDF();
        const element = array[index].original;
        doc = await createRechnung(element, startDate, endDate, printDate, doc);
        doc.addPage('a4', 'portrait');
        doc = await createAnlage(element, doc);
        zip = saveDocToZip(element, doc, zip, 'RechnungMitAnlage');
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'RechnungenMitAnlagen.zip');
      });
    }
  }

  async function onCreateRechnung(array) {
    let zip = new JSZip();

    if (array.length == 1) {
      let doc = new jsPDF();
      const youthWelfareCase = array[0].original;
      doc = await createRechnung(
        youthWelfareCase,
        startDate,
        endDate,
        printDate,
        doc
      );
      saveDoc(youthWelfareCase, doc, 'Rechnung');
    } else {
      for (let index = 0; index < array.length; index++) {
        let doc = new jsPDF();
        const element = array[index].original;
        doc = await createRechnung(
          element,
          startDate,
          endDate,
          printDate,
          doc,
          'Rechnung'
        );
        zip = saveDocToZip(element, doc, zip, 'Rechnung');
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Rechnungen.zip');
      });
    }
  }
  async function onCreateAnlage(array) {
    let zip = new JSZip();

    if (array.length == 1) {
      let doc = new jsPDF();
      const youthWelfareCase = array[0].original;
      doc = await createAnlage(youthWelfareCase, doc);
      saveDoc(youthWelfareCase, doc, 'Anlage');
    } else if (array.length > 1) {
      for (let index = 0; index < array.length; index++) {
        let doc = new jsPDF();
        const element = array[index].original;
        doc = await createAnlage(element, doc, 'Anlage');
        zip = saveDocToZip(element, doc, zip, 'Anlage');
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Anlagen.zip');
      });
    }
  }

  function saveDoc(youthWelfareCase, doc, prefix) {
    if (youthWelfareCase.calculationPrinciples[0]) {
      doc.save(
        `${prefix}_${youthWelfareCase.identifier}_${moment(
          youthWelfareCase.calculationPrinciples[0].startDate
        ).format('DDMMYYYY')}_${moment(
          youthWelfareCase.calculationPrinciples[0].endDate
        ).format('DDMMYYYY')}`
      );
    } else {
      doc.save(`${prefix}_${youthWelfareCase.identifier}`);
    }
  }

  function saveDocToZip(youthWelfareCase, doc, zip, prefix) {
    if (youthWelfareCase.calculationPrinciples[0]) {
      zip.file(
        `${prefix}_${youthWelfareCase.identifier}_${moment(
          youthWelfareCase.calculationPrinciples[0].startDate
        ).format('DDMMYYYY')}_${moment(
          youthWelfareCase.calculationPrinciples[0].endDate
        ).format('DDMMYYYY')}.pdf`,
        doc.output('blob')
      );
    } else {
      zip.file(
        `${prefix}_${youthWelfareCase.identifier}.pdf`,
        doc.output('blob')
      );
    }

    return zip;
  }

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type='checkbox' ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  const { data: youthWelfareCasesDropdown } = useQuery(
    'YouthWelfareCaseDropdown',
    () => findAll()
  );

  React.useEffect(() => {
    const enteredStartDate = DateTime.fromFormat(startDate, 'yyyy-LL-dd');
    const enteredEndDate = DateTime.fromFormat(endDate, 'yyyy-LL-dd');
    if (enteredStartDate.isValid && enteredEndDate.isValid) {
      reportFigure(youthWelfareCaseId, startDate, endDate).then((data) => {
        setData(data);
      });
    } else {
      setData([]);
    }
  }, [youthWelfareCaseId, startDate, endDate, refreshData]);

  const onStartDateChange = useAsyncDebounce((value) => {
    console.log(value);
    setStartDate(value);
    setDateForCalculationPrinciple(DateTime.fromFormat(value, 'yyyy-MM-dd'));
  }, 200);

  const onEndDateChange = useAsyncDebounce((value) => {
    setEndDate(value);
  }, 200);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    selectedFlatRows,
    state: { selectedRowIds },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          width: 'w-8',
          isSorted: false,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  return (
    <>
      <div className='mb-4 flex flex-row text-xl font-semibold'>{title}</div>
      <div className='mb-2 flex flex-col gap-2'>
        <div className='mb-2 flex w-full flex-col gap-2 xl:flex-row items-end'>
          <div className='w-96'>
            <FormComboboxWithQuery
              filterFn={(item, query) =>
                `${item.identifier?.toLowerCase()}`.includes(
                  query.toLowerCase().replace(/\s+/g, '')
                )
              }
              label='Fall'
              name='youthWelfareCaseId'
              onChange={(selected) => {
                if (selected) {
                  setYouthWelfareCaseId(selected);
                } else {
                  setYouthWelfareCaseId(null);
                }
              }}
              optionToKey={(item) => item.id.toString()}
              optionToString={(item) => (item ? `${item.identifier}` : '')}
              optionToValue={(item) => item.id}
              selected={youthWelfareCaseId}
              queryFn={findAllYouthWelfareCasesAsAdminOrBySpecialistId}
              queryKeys={['YouthWelfareCasesDropdown']}
            />
          </div>

          <TextInput
            type='date'
            id='startDate'
            name='startDate'
            label='Anfangsdatum'
            value={startDateValue || ''}
            onChange={({ target }) => {
              setStartDateValue(moment(target.value).format('YYYY-MM-DD'));
              onStartDateChange(moment(target.value).format('YYYY-MM-DD'));
            }}
          />

          <TextInput
            type='date'
            id='endDate'
            name='endDate'
            label='Enddatum'
            value={endDateValue || ''}
            onChange={({ target }) => {
              setEndDateValue(moment(target.value).format('YYYY-MM-DD'));
              onEndDateChange(moment(target.value).format('YYYY-MM-DD'));
              // setEndDate(moment(target.value).format('YYYY-MM-DD'));
            }}
          />

          <TextInput
            type='date'
            id='printDate'
            name='printDate'
            label='Druckdatum'
            value={printDate || ''}
            onChange={({ target }) => {
              setPrintDate(moment(target.value).format('YYYY-MM-DD'));
            }}
          />
          <div className=''>
            <Menu as='div' className='relative inline-block text-left'>
              <div>
                <Menu.Button className='w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:w-auto sm:text-sm'>
                  Dokumente Erstellen
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className='absolute flex flex-col right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                  <Menu.Item>
                    {() => (
                      <button
                        onClick={() => {
                          if (startDate && endDate) {
                            onCreateRechnungAnlage(selectedFlatRows);
                          } else {
                            alert('Bitte geben Sie einen Zeitraum an.');
                          }
                        }}
                        className='w-full inline-flex justify-center px-4 py-2 rounded-b-md text-base font-medium text-gray-900 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:w-auto sm:text-sm'
                      >
                        {`Rechnung & Anlage`}
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {() => (
                      <div>
                        <button
                          onClick={() => {
                            if (startDate && endDate) {
                              onCreateRechnung(selectedFlatRows);
                            } else {
                              alert('Bitte geben Sie einen Zeitraum an.');
                            }
                          }}
                          className='w-full inline-flex justify-center px-4 py-2 rounded-t-md text-base font-medium text-gray-900 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:w-auto sm:text-sm'
                        >
                          Rechnung
                        </button>
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {() => (
                      <button
                        onClick={() => {
                          if (startDate && endDate) {
                            onCreateAnlage(selectedFlatRows);
                          } else {
                            alert('Bitte geben Sie einen Zeitraum an.');
                          }
                        }}
                        className='w-full inline-flex justify-center px-4 py-2 text-base font-medium text-gray-900 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:w-auto sm:text-sm'
                      >
                        Anlage
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {() => (
                      <button
                        onClick={() => {
                          if (startDate && endDate) {
                            onExportToCsv(selectedFlatRows);
                          } else {
                            alert('Bitte geben Sie einen Zeitraum an.');
                          }
                        }}
                        className='w-full inline-flex justify-center px-4 py-2 text-base font-medium text-gray-900 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:w-auto sm:text-sm'
                      >
                        CSV
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className='flex flex-row gap-1'>
            <PrimaryButton
              onClick={() => {
                setRefreshData(!refreshData);
              }}
            >
              Neu Laden
            </PrimaryButton>
          </div>
        </div>

        <div className='mb-2 flex flex-col gap-2 xl:flex-row'>
          <TableFilter.GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div>

      <div className='w-full overflow-x-auto rounded-lg border border-gray-300 bg-gray-100 shadow-sm'>
        <table className='w-full' {...getTableProps()}>
          <thead className='w-full border-b border-gray-300 text-left'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  if (column.id == 'actions') {
                    return (
                      <th
                        scope='col'
                        className={`w-12`}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <div
                          className={`flex flex-row w-full items-center px-2 py-2`}
                        >
                          <span className='flex flex-row line-clamp-1'>
                            {column.render('Header')}
                          </span>

                          {column.canSort == false ? null : column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon
                                className='ml-2'
                                icon={faSortDown}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className='ml-2'
                                icon={faSortUp}
                              />
                            )
                          ) : (
                            <FontAwesomeIcon className='ml-2' icon={faSort} />
                          )}
                        </div>
                      </th>
                    );
                  } else {
                    return (
                      <th
                        scope='col'
                        className={`w-min`}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <div className={`flex flex-row items-center px-2 py-2`}>
                          <span className='flex flex-row line-clamp-1'>
                            {column.render('Header')}
                          </span>

                          {column.canSort == false ? null : column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon
                                className='ml-2'
                                icon={faSortDown}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className='ml-2'
                                icon={faSortUp}
                              />
                            )
                          ) : (
                            <FontAwesomeIcon className='ml-2' icon={faSort} />
                          )}
                        </div>
                      </th>
                    );
                  }
                })}
              </tr>
            ))}
          </thead>
          <tbody
            className='bg-white divide-y divide-gray-200'
            {...getTableBodyProps()}
          >
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i}
                  className={`hover:bg-gray-200 ${
                    i % 2 == 0 ? 'bg-white' : 'bg-gray-100'
                  }`}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={`px-2 text-sm py-${density}`}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { DateTime } from 'luxon';
import moment from 'moment';

export default async function createAnlage(youthWelfareCase, doc) {
  const marginL = 15;
  const marginR = 195;

  doc.setFont('times', 'normal');
  doc.setFontSize(16);
  doc.text('Anlage', marginL, 10);

  const earliestDate =
    youthWelfareCase.appointments && youthWelfareCase.appointments.length > 0
      ? youthWelfareCase.appointments[0].date
      : null;

  const latestDate =
    youthWelfareCase.appointments && youthWelfareCase.appointments.length > 0
      ? youthWelfareCase.appointments[youthWelfareCase.appointments.length - 1]
          .date
      : null;

  doc.setFontSize(9);
  doc.autoTable({
    // html: infoTable,
    // includeHiddenHtml: true,
    theme: 'grid',
    body: [
      [
        'Anlage zu Rechnung für',
        {
          content: youthWelfareCase.identifier,
          styles: { fontStyle: 'bold' },
        },
        {
          colSpan: 2,
          content: `${moment(earliestDate).locale('de').format('MMMM YYYY')}`,
          styles: { fontStyle: 'bold' },
        },
      ],
      [
        'zuständige Fachkraft',
        youthWelfareCase.primarySpecialist
          ? `${youthWelfareCase.primarySpecialist.firstname} ${youthWelfareCase.primarySpecialist.lastname}`
          : '',
        {
          colSpan: 2,
          content: youthWelfareCase.primarySpecialist
            ? youthWelfareCase.primarySpecialist.qualification.name
            : '',
        },
      ],
      [
        '2. Fachkraft (z.B. Vertretung)',
        youthWelfareCase.secondarySpecialist
          ? `${youthWelfareCase.secondarySpecialist.firstname} ${youthWelfareCase.secondarySpecialist.lastname}`
          : '',
        {
          colSpan: 2,
          content: youthWelfareCase.secondarySpecialist
            ? youthWelfareCase.secondarySpecialist.qualification.name
            : '',
        },
      ],
      [
        'ASD-Mitarbeiter',
        youthWelfareCase.contact
          ? `${youthWelfareCase.contact.firstname} ${youthWelfareCase.contact.lastname}`
          : '',
        {
          rowSpan: 2,
          content: `Stundenbudget: ${
            youthWelfareCase.calculationPrinciples[0]
              ? youthWelfareCase.calculationPrinciples[0].budgetInHours
              : 0
          }`,
          styles: { fontStyle: 'bold' },
        },
        {
          rowSpan: 2,
          content: `Anzahl FLS: ${youthWelfareCase.computedDuration
            .toString()
            .replace('.', ',')}`,
          styles: { fontStyle: 'bold' },
        },
      ],
      ['Aktenzeichen', youthWelfareCase.referenceNumberOnAnnexure],
    ],
    columnStyles: {
      0: { halign: 'right', fillColor: [224, 224, 224], fontStyle: 'bold' },
      2: { halign: 'center', valign: 'middle' },
      3: { halign: 'center', valign: 'middle' },
    },
  });
  doc.autoTable({
    body: youthWelfareCase.appointments,
    headStyles: {
      textColor: 80,
      fillColor: [224, 224, 224],
      lineWidth: 0.1,
    },
    bodyStyles: { valign: 'middle', lineWidth: 0.1 },
    alternateRowStyles: { fillColor: [244, 244, 244] },
    columns: [
      { header: 'Datum', dataKey: 'date' },
      { header: 'von', dataKey: 'startTime' },
      { header: 'bis', dataKey: 'endTime' },
      { header: 'Dauer', dataKey: 'duration' },
      { header: 'Art des Kontakts', dataKey: 'contactActivity' },
      { header: 'Fachkraft', dataKey: 'specialist' },
    ],
    didParseCell: function (data) {
      if (data.section === 'body') {
        if (data.column.dataKey === 'date') {
          data.cell.text = moment(data.cell.raw, 'YYYY-MM-DD').format(
            'DD.MM.YYYY'
          );
        }
        if (data.column.dataKey === 'duration') {
          data.cell.text = data.cell.raw.toString().replace('.', ',');
        }
        if (data.column.dataKey === 'specialist') {
          data.cell.text = `${data.cell.raw.firstname} ${data.cell.raw.lastname}`;
        }
      }
    },
  });

  doc.text(
    `Summe FLS direkter Kontakt: ${youthWelfareCase.billable
      .toString()
      .replace('.', ',')}`,
    marginL,
    doc.lastAutoTable.finalY + 10
  );
  if (youthWelfareCase.calculationPrinciples.length > 0) {
    doc.text(
      `FLS insgesamt (${
        youthWelfareCase.calculationPrinciples[0].chargeOutRateFormula
      }): ${youthWelfareCase.computedDuration.toString().replace('.', ',')}`,
      marginL,
      doc.lastAutoTable.finalY + 15
    );
    doc.text(
      `Fachleistungsstundensatz: ${Number.parseFloat(
        youthWelfareCase.calculationPrinciples[0].chargeOutRate
      )
        .toFixed(2)
        .replace('.', ',')} €`,
      marginL,
      doc.lastAutoTable.finalY + 20
    );
    doc.text(
      `Betrag: ${youthWelfareCase.computedDuration
        .toString()
        .replace('.', ',')} * ${Number.parseFloat(
        youthWelfareCase.calculationPrinciples[0].chargeOutRate
      )
        .toFixed(2)
        .replace('.', ',')} €`,
      marginL,
      doc.lastAutoTable.finalY + 25
    );
    doc.text(
      `${(
        youthWelfareCase.computedDuration *
        youthWelfareCase.calculationPrinciples[0].chargeOutRate
      )
        .toFixed(2)
        .replace('.', ',')} €`,
      marginR,
      doc.lastAutoTable.finalY + 25,
      { align: 'right' }
    );
  }

  return doc;
}

import axios from 'axios';

/**
 *
 * @param {string} url
 * @param {{}} data
 * @param {AxiosRequestConfig<any>} additionalConfig
 * @returns
 */
export async function _create(url, data, additionalConfig) {
  return axios.create({
    url,
    data,
    withCredentials: true,
    ...additionalConfig,
  });
}

/**
 *
 * @param {string} url
 * @param {AxiosRequestConfig<any>} additionalConfig
 * @returns
 */
export async function get(url, additionalConfig) {
  return axios.get(url, { withCredentials: true, ...additionalConfig });
}

/**
 *
 * @param {string} url
 * @param {{}} data
 * @param {AxiosRequestConfig<any>} additionalConfig
 * @returns
 */
export async function post(url, data, additionalConfig) {
  return axios.post(url, data, { withCredentials: true, ...additionalConfig });
}

/**
 *
 * @param {string} url
 * @param {{}} data
 * @param {AxiosRequestConfig<any>} additionalConfig
 * @returns
 */
export async function patch(url, data, additionalConfig) {
  return axios.patch(url, data, { withCredentials: true, ...additionalConfig });
}

export async function _delete(url, data, additionalConfig) {
  return axios.delete(url, {
    data,
    withCredentials: true,
    ...additionalConfig,
  });
}

import axios from 'axios';
import endpoints from '../_helpers/endpoints';
import { _delete, _create, get, patch, post } from './_axios';

/**
 *
 * @param {{}} body
 * @returns {{}}
 */
export async function create(body) {
  const { data } = await _create(`${endpoints.calculationPrinciple}`, body);
  return data;
}

/**
 *
 * @returns {[{}]}
 */
export async function findAll() {
  const { data } = await get(`${endpoints.calculationPrinciple}`);
  return data;
}

/**
 *
 * @param {number} id
 * @returns {{}}
 */
export async function findById(id) {
  const { data } = await post(`${endpoints.calculationPrinciple}/findById`, {
    id,
  });
  return data;
}

/**
 *
 * @param {number} id
 * @returns {{}}
 */
export async function findByYouthWelfareCaseIdAndDate(
  youthWelfareCaseId,
  selectedDate
) {
  const { data } = await post(
    `${endpoints.calculationPrinciple}/findByYouthWelfareCaseIdAndDate`,
    {
      youthWelfareCaseId,
      selectedDate,
    }
  );
  return data;
}

/**
 *
 * @param {{}} body
 * @returns {{}}
 */
export async function updateById(body) {
  const { data } = patch(`${endpoints.calculationPrinciple}/updateById`, body);
  return data;
}

/**
 *
 * NOTE: For some reason request with delete doesn't send session cookie
 * @param {number} id
 * @returns {{message: string}}
 */
export async function deleteById(id) {
  const { data } = _delete(`${endpoints.calculationPrinciple}/deleteById`, {
    id,
  });
  return data;
}

import 'jspdf-autotable';
import moment from 'moment';
import praxisHeader from '../../images/praxis-header.png';
import { post } from '../../_api/_axios';
import endpoints from '../../_helpers/endpoints';

export default async function createRechnung(
  youthWelfareCase,
  startDate,
  endDate,
  createdAt,
  doc
) {
  const { data: referenceData } = await post(
    `${endpoints.referenceData}/findById`,
    { id: 1 }
  );
  const marginL = 15;
  const marginR = 197;

  doc.addImage(praxisHeader, 'PNG', 13, 10, 185, 35);

  doc.setFont('times', 'normal');
  doc.setFontSize(9);
  doc.text(
    `${referenceData.name}, ${referenceData.streetaddress}, ${referenceData.zipcode} ${referenceData.city}`,
    marginL,
    59
  );
  doc.line(marginL, 60, 110, 60);

  doc.setFontSize(11);
  if (youthWelfareCase.invoiceAddress && youthWelfareCase.contact) {
    doc.text(`${youthWelfareCase.invoiceAddress.recipient}`, marginL, 65);
    doc.text(`${youthWelfareCase.invoiceAddress.additional}`, marginL, 70);
    doc.text(
      `${youthWelfareCase.contact.salutation} ${youthWelfareCase.contact.lastname}`,
      marginL,
      80
    );
    doc.text(`${youthWelfareCase.invoiceAddress.streetaddress}`, marginL, 85);
    doc.text(
      `${youthWelfareCase.invoiceAddress.zipcode} ${youthWelfareCase.invoiceAddress.city}`,
      marginL,
      90
    );
  }

  doc.text(`${referenceData.streetaddress}`, marginR, 65, {
    align: 'right',
  });
  doc.text(`${referenceData.zipcode} ${referenceData.city}`, marginR, 70, {
    align: 'right',
  });
  doc.text(`Tel.: ${referenceData.landline}`, marginR, 80, {
    align: 'right',
  });
  doc.text(`Fax.: ${referenceData.fax}`, marginR, 85, { align: 'right' });
  doc.text(`${referenceData.website}`, marginR, 95, { align: 'right' });
  doc.text(`${referenceData.email}`, marginR, 100, {
    align: 'right',
  });

  doc.text(
    `Datum: ${
      createdAt
        ? moment(createdAt).format('DD.MM.YYYY')
        : moment().format('DD.MM.YYYY')
    }`,
    marginR,
    110,
    {
      align: 'right',
    }
  );
  doc.setFont('times', 'bold');
  doc.text(
    `Rechnungs-Nr.: ${youthWelfareCase.internalReferenceNumber}-${moment(
      startDate
    ).format('MM')}-${moment(startDate).format('YYYY')}`,
    marginR,
    120,
    {
      align: 'right',
    }
  );
  doc.setFont('times', 'normal');
  doc.text(`Steuernummer: ${referenceData.taxId}`, marginR, 130, {
    align: 'right',
  });

  doc.setFont('times', 'bold');
  doc.text(`Hilfefall: ${youthWelfareCase.identifier}`, marginL, 140);
  doc.text(
    `Aktenzeichen: ${youthWelfareCase.referenceNumberOnAnnexure}`,
    marginL,
    145
  );
  doc.text(`Art der Hilfe: ${youthWelfareCase.guidance.name}`, marginL, 150);

  doc.setFont('times', 'normal');
  doc.text('Sehr geehrte Damen und Herren,', marginL, 170);
  doc.text(
    `für den Zeitraum von ${moment(startDate).format(
      'DD.MM.YYYY'
    )} bis zum ${moment(endDate).format(
      'DD.MM.YYYY'
    )} stelle ich folgende Kosten in Rechnung.`,
    marginL,
    180
  );
  doc.setFont('times', 'bold');
  if (youthWelfareCase.calculationPrinciples[0]) {
    doc.text(
      `${youthWelfareCase.computedDuration
        .toString()
        .replace('.', ',')} Fachleistungsstunden à ${Number.parseFloat(
        youthWelfareCase.calculationPrinciples[0].chargeOutRate
      )
        .toFixed(2)
        .replace('.', ',')} €`,
      marginL,
      185
    );
  }
  doc.text('Gesamtbetrag', marginL, 195);
  doc.text(
    `${Number.parseFloat(youthWelfareCase.figure)
      .toFixed(2)
      .replace('.', ',')} €`,
    marginR,
    195,
    {
      align: 'right',
    }
  );

  doc.setFont('times', 'normal');
  doc.text(
    'Den ausgewiesenen Betrag bitte ich innerhalb von 14 Tagen auf das u.g. Konto zu überweisen.\nDie von mir erbrachten Leistungen sind von der Umsatzsteuer gemäß §4 Nr. 25 UstG befreit.',
    marginL,
    205
  );
  doc.text(
    'Herzlichen Dank für Ihren Auftrag\nMit freundlichen Grüßen',
    marginL,
    225
  );

  doc.line(marginL, 285, marginR, 285);
  doc.text(`${referenceData.bank}`, 45, 290);
  doc.text(`IBAN ${referenceData.iban}`, 167, 290, {
    align: 'right',
  });

  return doc;
}

import moment from 'moment';
import endpoints from '../_helpers/endpoints';
import { get, patch, post, _create, _delete } from './_axios';

/**
 *
 * @param {{}} body
 * @returns {{}}
 */
export async function create(body) {
  const { data } = await _create(`${endpoints.youthWelfareCase}`, body);
  return data;
}

/**
 *
 * @returns {[{}]}
 */
export async function findAll() {
  const { data } = await get(`${endpoints.youthWelfareCase}`);
  return data;
}

/**
 *
 * @param {number} id
 * @returns {[{}]}
 */
export async function findAllAsAdminOrBySpecialistId() {
  const { data } = await get(
    `${endpoints.youthWelfareCase}/findAllAsAdminOrBySpecialistId`
  );
  return data;
}

/**
 *
 * @param {number} specialistId
 * @returns {[{}]}
 */
export async function findAllBySpecialistId(specialistId) {
  const { data } = await post(
    `${endpoints.youthWelfareCase}/findAllBySpecialistId`,
    { specialistId }
  );
  return data;
}

/**
 *
 * @param {number} id
 * @returns {{}}
 */
export async function findById(id) {
  const { data } = await post(`${endpoints.youthWelfareCase}/findById`, {
    id,
  });
  return data;
}

/**
 *
 * @param {number} id
 * @returns {[{}]}
 */
export async function reportFigure(youthWelfareCaseId, startDate, endDate) {
  const { data } = await post(`${endpoints.youthWelfareCase}/report/figure`, {
    youthWelfareCaseId: youthWelfareCaseId
      ? Number.parseInt(youthWelfareCaseId)
      : undefined,
    startDate: startDate,
    endDate: endDate,
  });
  return data;
}

/**
 *
 * @param {[number]} youthWelfareCaseIds
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {Promise<>}
 */
export async function exportFigureToCsv(
  youthWelfareCaseIds,
  startDate,
  endDate
) {
  return await post(`${endpoints.youthWelfareCase}/export/figureToCsv`, {
    youthWelfareCaseIds: youthWelfareCaseIds,
    startDate: startDate,
    endDate: endDate,
  });
}

/**
 *
 * @param {{}} body
 * @returns {{}}
 */
export async function updateById(body) {
  const { data } = patch(`${endpoints.youthWelfareCase}/updateById`, body);
  return data;
}

/**
 *
 * NOTE: For some reason request with delete doesn't send session cookie
 * @param {number} id
 * @returns {{message: string}}
 */
export async function deleteById(id) {
  const { data } = _delete(`${endpoints.youthWelfareCase}/deleteById`, {
    id,
  });
  return data;
}

import React from 'react';
import { useQuery } from 'react-query';
import { findAll } from '../_api/youthWelfareOffice.services';
import Dropdown from '../_components/Form/Dropdown/Dropdown';
import TextInput from '../_components/Form/TextInput/TextInput';

/**
 *
 * @param {{data: Object, onSubmit: UseMutationResult<AxiosResponse<unknown, any>, unknown, void, unknown>}} props
 * @returns
 */
export default function ContactForm({ id, data, history, onSubmit }) {
  const initialFormValues = {
    salutation: null,
    firstname: '',
    lastname: '',
    referenceNumber: '',
    youthWelfareOfficeId: null,
  };
  let [values, setValues] = React.useState(initialFormValues);

  const {
    status: youthWelfareOfficeQueryStatus,
    data: YouthWelfareOfficesDropdown,
  } = useQuery('YouthWelfareOfficesDropdown', () => findAll());

  React.useEffect(() => {
    if (data && youthWelfareOfficeQueryStatus == 'success') {
      setValues({ ...values, ...data });
    }
  }, [data, youthWelfareOfficeQueryStatus]);

  return (
    <>
      <div className='md:grid md:grid-cols-3 md:gap-6'>
        <div className='mt-5 md:mt-0 md:col-span-2'>
          <form
            autoComplete='off'
            onSubmit={(event) => {
              event.preventDefault();
              onSubmit.mutate(values);
            }}
          >
            <div className='shadow sm:rounded-md sm:overflow-hidden'>
              <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                <div className='flex'>
                  <div>
                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                      Ansprechpartner
                    </h3>
                    <p className='mt-1 text-sm text-gray-600'>Formular.</p>
                  </div>
                </div>
                <hr />
                <Dropdown
                  id='salutation'
                  name='salutation'
                  label='Anrede'
                  value={values.salutation ? values.salutation : ''}
                  options={['Herr', 'Frau', 'Divers']}
                  optionToValue={(item) => item}
                  optionToString={(item) => item}
                  onChange={({ target }) => {
                    setValues({ ...values, salutation: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='firstname'
                  name='firstname'
                  label='Vorname'
                  value={values.firstname}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='lastname'
                  name='lastname'
                  label='Nachname'
                  value={values.lastname}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                {youthWelfareOfficeQueryStatus != 'loading' ? (
                  youthWelfareOfficeQueryStatus != 'error' ? (
                    <Dropdown
                      id='youthWelfareOffice'
                      name='youthWelfareOffice'
                      label='Jugendamt'
                      value={
                        values.youthWelfareOfficeId
                          ? values.youthWelfareOfficeId
                          : ''
                      }
                      options={YouthWelfareOfficesDropdown}
                      optionToValue={(item) => item.id}
                      optionToString={(item) => item.name}
                      onChange={({ target }) => {
                        const selectedObj = YouthWelfareOfficesDropdown.filter(
                          (item) => item.id == target.value
                        )[0];
                        if (selectedObj) {
                          setValues({
                            ...values,
                            youthWelfareOfficeId: selectedObj.id,
                          });
                        } else {
                          setValues({ ...values, youthWelfareOfficeId: null });
                        }
                      }}
                    />
                  ) : null
                ) : null}

                <TextInput
                  type='text'
                  id='referenceNumber'
                  name='referenceNumber'
                  label='Aktenzeichen (Ansprechpartner)'
                  value={values.referenceNumber}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />
              </div>
              <div className='bg-gray-50 px-4 py-3 sm:flex sm:items-center sm:px-6'>
                <button
                  type='button'
                  onClick={() => history.push(values.id ? '..' : '.')}
                  className='mt-3 w-full inline-flex justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:w-auto sm:text-sm'
                >
                  Abbrechen
                </button>
                <button
                  type='submit'
                  className='mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                >
                  Speichern
                </button>
                <div className='ml-3'>
                  {onSubmit.isLoading ? (
                    <span>In Bearbeitung...</span>
                  ) : onSubmit.isSuccess ? (
                    <span>Erfolgreich gespeichert.</span>
                  ) : onSubmit.isError ? (
                    <span>Fehler aufgetretten.</span>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

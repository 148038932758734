import {
  faArrowRightFromBracket,
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useAuthenticationContext } from '../../components/Authentication/AuthenticationContext';
import { logout } from '../../_api/auth.services';
import NavigationLink from './NavigationLink';
import NavigationMenu from './NavigationMenu';
import NavigationMenuLink from './NavigationMenuLink';

export default function Navbar() {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const auth = useAuthenticationContext();

  function handleClick() {
    setActive(!active);
  }

  async function handleLogout() {
    logout().then((res) => {
      if (res.status == 200) {
        window.location.replace('/login');
      }
    });
  }

  useEffect(() => {
    if (auth?.user) {
      setUser(auth?.user);
    } else {
      setUser(null);
    }
  }, [auth]);

  return (
    <div className='mb-12 flex flex-col items-center bg-emerald-600 px-4 text-white xl:flex-row'>
      <div className='flex w-full flex-row items-center xl:w-min'>
        <span className='mr-1 flex w-max flex-row items-center uppercase'>
          Praxis Herchet
        </span>
        <span className='font-semibold'>Datenverwaltung</span>
        <button
          className='ml-auto flex items-center justify-center p-4 xl:hidden'
          onClick={() => setOpen(!open)}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>
      <nav
        className={`absolute left-0 z-50 m-0 mt-12 w-full bg-emerald-600 p-0 xl:static xl:mt-0 xl:ml-12 ${
          open ? 'flex' : 'hidden xl:flex'
        }`}
      >
        <div className={`flex w-full flex-col xl:flex-row`}>
          {user?.id ? (
            <>
              <NavigationLink to='/patientenTermin'>Termine</NavigationLink>
              <NavigationLink to='/internerTermin'>
                Interne Termine
              </NavigationLink>
              {/* Menu */}
              {user?.role == 'ADMIN' ? (
                <>
                  <NavigationMenu text='Stammdaten'>
                    <>
                      <NavigationMenuLink to='/patient'>
                        Klienten
                      </NavigationMenuLink>
                      <NavigationMenuLink to='/hilfeart'>
                        Hilfearten
                      </NavigationMenuLink>
                      <NavigationMenuLink to='/jugendamt'>
                        Jugendämter
                      </NavigationMenuLink>
                      <NavigationMenuLink to='/ansprechpartner'>
                        Ansprechpartner
                      </NavigationMenuLink>
                      <NavigationMenuLink to='/rechnungsadresse'>
                        Rechnungsadressen
                      </NavigationMenuLink>
                      <NavigationMenuLink to='/fall'>Fälle</NavigationMenuLink>
                      <NavigationMenuLink to='/berechnungsgrundlage'>
                        Berechnungsgrundlage
                      </NavigationMenuLink>
                    </>
                  </NavigationMenu>

                  <NavigationMenu text='Team'>
                    <>
                      <NavigationMenuLink to='/therapeut'>
                        Fachkräfte
                      </NavigationMenuLink>
                      <NavigationMenuLink to='/qualifikation'>
                        Qualifikationen
                      </NavigationMenuLink>
                    </>
                  </NavigationMenu>
                  {/* Menu */}
                  <NavigationLink to='/rechnung'>Rechnungen</NavigationLink>
                  <NavigationLink to='/statistics'>Statistiken</NavigationLink>
                  <NavigationLink to='/praxis'>Praxis</NavigationLink>
                </>
              ) : null}
              <button
                onClick={handleLogout}
                className={`flex xl:hidden w-full flex-row items-center px-4 py-2 hover:bg-red-700 xl:w-max xl:justify-center`}
              >
                Abmelden
              </button>
            </>
          ) : null}
        </div>
      </nav>
      <button
        className='hidden xl:inline-flex items-center rounded-lg px-4 py-2 text-sm font-medium hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2'
        onClick={handleLogout}
      >
        <FontAwesomeIcon icon={faArrowRightFromBracket} />
      </button>
    </div>
  );
}

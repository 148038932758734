import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import Dropdown from '../_components/Form/Dropdown/Dropdown';
import TextInput from '../_components/Form/TextInput/TextInput';
import endpoints from '../_helpers/endpoints';
import Textarea from '../_components/Form/Textarea/Textarea';
import Checkbox from '../_components/Form/Checkbox/Checkbox';
import { findAll as findAllSpecialists } from '../_api/specialist.services';
import moment from 'moment';
import { DateTime } from 'luxon';

/**
 *
 * @param {{data: Object, onSubmit: UseMutationResult<AxiosResponse<unknown, any>, unknown, void, unknown>}} props
 * @returns
 */
export default function InternerTerminForm({ data, history, onSubmit, user }) {
  const initialFormValues = {
    date: null,
    startTime: null,
    endTime: null,
    appointmentType: '',
    location: '',
    pointOfDeparture: '',
    kilometers: 0,
    isCommute: false,
    contactActivity: '',
    activity: '',
    documentation: '',
  };
  let [values, setValues] = React.useState(initialFormValues);

  async function get(endpoint) {
    const { data } = await axios.get(endpoint, {
      withCredentials: true,
    });
    return data;
  }

  const { status: specialistQueryStatus, data: specialistDropdownData } =
    useQuery('specialistDropdownData', () => findAllSpecialists());

  React.useEffect(() => {
    if (data && specialistQueryStatus == 'success') {
      setValues({ ...values, ...data });
    }
  }, [data, specialistQueryStatus]);

  return (
    <>
      <div className='md:grid md:grid-cols-3 md:gap-6'>
        <div className='mt-5 md:mt-0 md:col-span-2'>
          <form
            autoComplete='off'
            onSubmit={(event) => {
              event.preventDefault();
              onSubmit.mutate(values);
            }}
          >
            <div className='shadow sm:rounded-md sm:overflow-hidden'>
              <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                <div className='flex'>
                  <div>
                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                      Interner Termin
                    </h3>
                    <p className='mt-1 text-sm text-gray-600'>Formular.</p>
                  </div>
                </div>
                <hr />
                <Dropdown
                  id='appointmentType'
                  name='appointmentType'
                  label='Terminart'
                  value={values.appointmentType || ''}
                  options={[
                    { id: 'DIRECT', name: 'Direkt' },
                    { id: 'INDIRECT', name: 'Indirekt' },
                    { id: 'INTERNAL', name: 'Praxis' },
                  ]}
                  optionToValue={(item) => item.id}
                  optionToString={(item) => item.name}
                  onChange={({ target }) => {
                    const selectedObj = [
                      'DIRECT',
                      'INDIRECT',
                      'INTERNAL',
                    ].filter((item) => item == target.value)[0];
                    if (selectedObj) {
                      setValues({ ...values, appointmentType: selectedObj });
                    } else {
                      setValues({ ...values, appointmentType: '' });
                    }
                  }}
                />

                <TextInput
                  type='date'
                  id='date'
                  name='date'
                  label='Datum'
                  value={
                    values.date
                      ? values.id
                        ? DateTime.fromISO(values.date).toSQLDate()
                        : values.date
                      : ''
                  }
                  onChange={({ target }) => {
                    setValues({
                      ...values,
                      [target.name]: target.value,
                    });
                  }}
                />

                <TextInput
                  type='time'
                  id='startTime'
                  name='startTime'
                  label='Termin Begin'
                  value={values.startTime || ''}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='time'
                  id='endTime'
                  name='endTime'
                  label='Termin Ende'
                  value={values.endTime || ''}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='location'
                  name='location'
                  label='Ort'
                  value={values.location}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='pointOfDeparture'
                  name='pointOfDeparture'
                  label='Abfahrtort'
                  value={values.pointOfDeparture}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='number'
                  id='kilometers'
                  name='kilometers'
                  label='Kilometer'
                  value={values.kilometers}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <Checkbox
                  id='isCommute'
                  name='isCommute'
                  label='Als Arbeitsweg markieren?'
                  checked={values.isCommute}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.checked });
                  }}
                />

                <Textarea
                  id='contactActivity'
                  name='contactActivity'
                  label='Kontaktart'
                  value={values.contactActivity}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <Textarea
                  id='activity'
                  name='activity'
                  label='Tätigkeit'
                  value={values.activity}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <Textarea
                  id='documentation'
                  name='documentation'
                  label='Kurz Dokumentation'
                  value={values.documentation}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />
              </div>
              <div className='bg-gray-50 px-4 py-3 sm:flex sm:items-center sm:px-6'>
                <button
                  type='button'
                  onClick={() => history.push(values.id ? '..' : '.')}
                  className='mt-3 w-full inline-flex justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:w-auto sm:text-sm'
                >
                  Abbrechen
                </button>
                <button
                  type='submit'
                  className='mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                >
                  Speichern
                </button>
                <div className='ml-3'>
                  {onSubmit.isLoading ? (
                    <span>In Bearbeitung...</span>
                  ) : onSubmit.isSuccess ? (
                    <span>Erfolgreich gespeichert.</span>
                  ) : onSubmit.isError ? (
                    <span>Fehler aufgetretten.</span>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import { useQuery } from 'react-query';
import { findAll as findAllQualifications } from '../_api/qualification.services';
import { updatePasswordById } from '../_api/specialist.services';
import { ConfirmationDialog } from '../_components/Dialog';
import Checkbox from '../_components/Form/Checkbox/Checkbox';
import Dropdown from '../_components/Form/Dropdown/Dropdown';
import TextInput from '../_components/Form/TextInput/TextInput';

/**
 *
 * @param {{data: Object, onSubmit: UseMutationResult<AxiosResponse<unknown, any>, unknown, void, unknown>}} props
 * @returns
 */
export default function TherapeutForm({ data, history, onSubmit }) {
  const initialFormValues = {
    firstname: '',
    lastname: '',
    username: null,
    password: null,
    passwordRepeat: '',
    email: '',
    role: 'SPECIALIST',
    // foreign keys
    qualificationId: null,
  };
  let [values, setValues] = React.useState(initialFormValues);

  const { status: qualifikationListStatus, data: qualifikationList } = useQuery(
    'QualificationDropdown',
    () => findAllQualifications()
  );

  React.useEffect(() => {
    if (data) {
      setValues({ ...values, ...data });
    }
  }, [data]);

  return (
    <>
      <div className='md:grid md:grid-cols-3 md:gap-6'>
        <div className='mt-5 md:mt-0 md:col-span-2'>
          <form
            autoComplete='off'
            onSubmit={(event) => {
              event.preventDefault();
              onSubmit.mutate(values);
            }}
          >
            <div className='shadow sm:rounded-md sm:overflow-hidden'>
              <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                <div className='flex'>
                  <div>
                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                      Fachkraft
                    </h3>
                  </div>
                </div>
                <hr />
                <TextInput
                  type='text'
                  id='firstname'
                  name='firstname'
                  label='Vorname'
                  value={values.firstname}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='lastname'
                  name='lastname'
                  label='Nachname'
                  value={values.lastname}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                {qualifikationListStatus != 'loading' ? (
                  qualifikationListStatus != 'error' ? (
                    <Dropdown
                      id='qualificationId'
                      name='qualificationId'
                      label='Qualifikation'
                      value={values.qualificationId || ''}
                      options={qualifikationList || []}
                      optionToValue={(item) => item.id}
                      optionToString={(item) => item.name}
                      onChange={({ target }) => {
                        const selectedObj = qualifikationList.filter(
                          (item) => item.id == target.value
                        )[0];
                        if (selectedObj) {
                          setValues({
                            ...values,
                            qualificationId: selectedObj.id,
                          });
                        } else {
                          setValues({ ...values, qualificationId: null });
                        }
                      }}
                    />
                  ) : null
                ) : null}

                <TextInput
                  type='text'
                  id='email'
                  name='email'
                  label='E-Mail'
                  value={values.email}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <Checkbox
                  id='role'
                  name='role'
                  label='Admin?'
                  checked={values.role == 'ADMIN'}
                  onChange={({ target }) => {
                    setValues({
                      ...values,
                      [target.name]: target.checked ? 'ADMIN' : 'SPECIALIST',
                    });
                  }}
                />
              </div>
              <div className='bg-gray-50 px-4 py-3 sm:flex sm:items-center sm:px-6'>
                <button
                  type='button'
                  onClick={() => history.push(values.id ? '..' : '.')}
                  className='mt-3 w-full inline-flex justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:w-auto sm:text-sm'
                >
                  Abbrechen
                </button>
                <button
                  type='submit'
                  className='mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                >
                  Speichern
                </button>
                <div className='ml-3'>
                  {onSubmit.isLoading ? (
                    <span>In Bearbeitung...</span>
                  ) : onSubmit.isSuccess ? (
                    <span>Erfolgreich gespeichert.</span>
                  ) : onSubmit.isError ? (
                    <span>Fehler aufgetretten.</span>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className='flex flex-col mt-5 md:mt-0 md:col-span-1 gap-6'>
          <div className='shadow sm:rounded-md sm:overflow-hidden'>
            <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
              <div className='overflow-hidden'>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                  Anmeldedaten
                </h3>
              </div>
              <hr />
              <TextInput
                type='text'
                id='username'
                name='username'
                label='Benutzername'
                value={values.username || ''}
                onChange={({ target }) => {
                  setValues({ ...values, [target.name]: target.value });
                }}
              />

              <TextInput
                type='password'
                id='password'
                name='password'
                label='Passwort'
                value={values.password || ''}
                onChange={({ target }) => {
                  setValues({ ...values, [target.name]: target.value });
                }}
              />

              <TextInput
                type='password'
                id='passwordRepeat'
                name='passwordRepeat'
                label='Passwort wiederholen'
                value={values.passwordRepeat || ''}
                onChange={({ target }) => {
                  setValues({ ...values, [target.name]: target.value });
                }}
              />
            </div>
            <div className='bg-gray-50 px-4 py-3 sm:flex sm:items-center sm:px-6'>
              <ConfirmationDialog
                primaryAction={() => {
                  if (values.password == values.passwordRepeat) {
                    updatePasswordById({
                      id: data.id,
                      username: values.username,
                      password: values.password,
                    }).then((res) => {
                      // console.log(res);
                    });
                  } else {
                    alert('Passwort stimmt nicht mit Wiederholung überein.');
                  }
                }}
                title={'Achtung!'}
                text={
                  'Mit dieser Operation überscheiben Sie die aktuellen Anmeldedaten mit den hier angegebenen Benutzernamen und Passwort.'
                }
                primaryActionButtonText={'Ja überschreiben'}
                primaryButtonText={'Speichern'}
              />
              {/* <button
                onClick={async }
                className='mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:w-auto sm:text-sm'
              >
                Speichern
              </button> */}
              <div className='ml-3'>
                {onSubmit.isLoading ? (
                  <span>In Bearbeitung...</span>
                ) : onSubmit.isSuccess ? (
                  <span>Erfolgreich gespeichert.</span>
                ) : onSubmit.isError ? (
                  <span>Fehler aufgetretten.</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import axios from 'axios';
import FormWrapper from '../_components/Form/FormWrapper';
import Table from '../_components/Table/Table';
import endpoints from '../_helpers/endpoints';
import PraxisForm from './PraxisForm';
import moment from 'moment';

const columns = [
  { accessor: 'name', Header: 'Name' },
  { accessor: 'adresse', Header: 'Adresse' },
  { accessor: 'ort', Header: 'Ort' },
  { accessor: 'partielleIBAN', Header: 'IBAN' },
  {
    accessor: (cell) => moment(cell.createdAt).format('DD.MM.YYYY'),
    Header: 'Erstellt am',
  },
  {
    accessor: (cell) => moment(cell.updatedAt).format('DD.MM.YYYY'),
    Header: 'Geändert am',
  },
];

export default function ReferenceData({ match, history, user }) {
  const { path } = match;
  const queryClient = useQueryClient();
  const endpoint = endpoints.referenceData;
  const queryKey = 'ReferenceData';

  return (
    <Switch>
      <Route
        path={`${path}`}
        render={({ match, history }) => (
          <FormWrapper
            Form={PraxisForm}
            endpoint={endpoint}
            queryKey='ReferenceData'
            match={match}
            history={history}
          />
        )}
      />
    </Switch>
  );
}

import React from 'react';

/**
 *
 * @param {React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>} props
 * @returns
 */
export default function TablePrimaryButton(props) {
  const { children } = props;
  return (
    <button
      className='inline-flex items-center rounded-lg px-4 py-2 text-sm font-medium text-emerald-600 hover:bg-emerald-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2'
      {...props}
    >
      {children}
    </button>
  );
}

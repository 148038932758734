import moment from 'moment';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import { deleteById, findAll } from '../_api/internalAppointment.services';
import FormWrapper from '../_components/Form/FormWrapper';
import Table from '../_components/Table/Table';
import endpoints from '../_helpers/endpoints';
import InternerTerminForm from './InternerTerminForm';

const columns = [
  {
    accessor: (cell) => moment(cell.date).format('DD.MM.YYYY'),
    Header: 'Datum',
    sortType: 'date',
  },
  { accessor: 'startTime', Header: 'Termin Begin' },
  { accessor: 'endTime', Header: 'Termin Ende' },
  {
    accessor: (cell) => {
      switch (cell.appointmentType) {
        case 'DIRECT':
          return 'Direkt';
        case 'INDIRECT':
          return 'Indirekt';
        case 'INTERNAL':
          return 'Praxis';
        default:
          return '';
      }
    },
    Header: 'Terminart',
  },
  { accessor: 'contanctActivity', Header: 'Kontaktart' },
  { accessor: 'activity', Header: 'Tätigkeit' },
  {
    accessor: (cell) => moment(cell.createdAt).format('DD.MM.YYYY'),
    Header: 'Erstellt am',
    sortType: 'date',
  },
  {
    accessor: (cell) => moment(cell.updatedAt).format('DD.MM.YYYY'),
    Header: 'Geändert am',
    sortType: 'date',
  },
];

export default function InternalAppointment({ match, history, user }) {
  const { path } = match;
  const queryClient = useQueryClient();
  const endpoint = endpoints.internalAppointment;
  const queryKey = 'InternalAppointments';

  const { status, data } = useQuery(queryKey, () => findAll());

  const deleteRow = useMutation((id) => deleteById(Number.parseInt(id)), {
    onSuccess: () => {
      // alert('Löschung erfolgreich');
    },
    onError: (error) => {
      alert(error);
    },
    // After success or failure, refetch the query
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => {
          if (status == 'loading') {
            return <div>Datensätze werden geladen...</div>;
          }

          if (status == 'error') {
            return (
              <div>Beim Laden der Datensätze ist ein Fehler aufgetretten.</div>
            );
          }

          return (
            <Table
              title='Interne Termine'
              data={data}
              deleteRow={deleteRow}
              columns={columns}
              match={match}
              history={history}
            />
          );
        }}
      />
      <Route
        path={`${path}/add`}
        render={({ match, history }) => (
          <FormWrapper
            Form={InternerTerminForm}
            endpoint={endpoint}
            queryKey='InternalAppointments'
            match={match}
            history={history}
            user={user}
          />
        )}
      />
      <Route
        path={`${path}/edit/:id`}
        render={({ match, history }) => (
          <FormWrapper
            Form={InternerTerminForm}
            endpoint={endpoint}
            queryKey='InternalAppointments'
            match={match}
            history={history}
            user={user}
          />
        )}
      />
    </Switch>
  );
}

import axios from 'axios';
import { DateTime } from 'luxon';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import endpoints from '../_helpers/endpoints';
import SelectableTable from './SelectableTable';

export default function Rechnung({ match, history, user }) {
  const { path } = match;
  const queryClient = useQueryClient();
  const endpoint = endpoints.youthWelfareCase;
  const queryKey = 'ReportFigure';

  const [dateForCalculationPrinciple, setDateForCalculationPrinciple] =
    React.useState(DateTime.now());

  const columns = React.useMemo(() => [
    { accessor: 'identifier', Header: 'Bezeichnung' },
    { accessor: 'computedDuration', Header: 'Berechnete Dauer' },
    { accessor: 'figure', Header: 'Betrag' },
    { accessor: 'directDuration', Header: 'Gesamtdauer (D)' },
    { accessor: 'indirectDuration', Header: 'Gesamtdauer (I)' },
    { accessor: 'internalDuration', Header: 'Gesamtdauer (P)' },
    { accessor: 'appointmentCount', Header: 'Anzahl der Termine' },
    {
      accessor: (cell) => {
        if (cell.latestCalculationPrincipleEndDate) {
          const calcPrinciplesEndDate = DateTime.fromISO(
            cell.latestCalculationPrincipleEndDate
          );
          const calcPrinciplesMonthBeforeEndDate = calcPrinciplesEndDate.plus({
            month: -1,
          });
          const dateToday = dateForCalculationPrinciple;

          if (
            dateToday < calcPrinciplesMonthBeforeEndDate &&
            dateToday < calcPrinciplesEndDate
          ) {
            return (
              <div className='w-max px-2 py-2 border border-emerald-500 rounded-lg bg-emerald-400 flex flex-row justify-center'>
                Aktiv
              </div>
            );
          } else if (
            dateToday > calcPrinciplesMonthBeforeEndDate &&
            dateToday < calcPrinciplesEndDate
          ) {
            return (
              <div className='w-max px-2 py-2 border border-yellow-500 rounded-lg bg-yellow-400 flex flex-row justify-center'>
                Läuft ab
              </div>
            );
          } else if (
            dateToday > calcPrinciplesMonthBeforeEndDate &&
            dateToday > calcPrinciplesEndDate
          ) {
            return (
              <div className='w-max px-2 py-2 border border-red-500 rounded-lg bg-red-400 flex flex-row justify-center'>
                Abgelaufen
              </div>
            );
          }
        } else {
          return (
            <div className='w-max px-2 py-2 border border-red-500 rounded-lg bg-red-400 flex flex-row justify-center'>
              Keine Berechnungsgrundlage
            </div>
          );
        }
      },
      Header: 'Berechnungsg...',
    },
  ]);

  const deleteRow = useMutation(
    (id) =>
      axios.delete(`${endpoint}/${id}`, {
        withCredentials: true,
      }),
    {
      onSuccess: () => {
        // alert('Löschung erfolgreich');
      },
      onError: (error) => {
        alert(error);
      },
      // After success or failure, refetch the query
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => {
          return (
            <div>
              <SelectableTable
                title='Rechnungen erstellen'
                deleteRow={deleteRow}
                columns={columns}
                match={match}
                history={history}
                setDateForCalculationPrinciple={setDateForCalculationPrinciple}
              />
            </div>
          );
        }}
      />
    </Switch>
  );
}

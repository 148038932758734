import axios from 'axios';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

/**
 * A form factory component. Generates forms according to given configuration
 *
 * Pattern: Factory Pattern
 */
export default function FormWrapper({
  Form,
  queryKey,
  endpoint,
  match,
  history,
  user,
}) {
  const { id } = match.params;
  const queryClient = useQueryClient();

  async function getDataById(id) {
    const { data } = await axios.post(
      `${endpoint}/findById`,
      { id: Number.parseInt(id) },
      { withCredentials: true }
    );
    return data;
  }

  const { status, data } = useQuery([queryKey, id], () => getDataById(id), {
    enabled: !!id,
  });

  const addMutation = useMutation(
    (data) => axios.post(endpoint, data, { withCredentials: true }),
    {
      onSuccess: (data) => {
        // history.push(match.path.replace('add', `edit/${data.data.id}`));
        history.push('.');
      },
      // After success or failure, refetch the todos query
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  const updateMutation = useMutation(
    (data) =>
      axios.patch(`${endpoint}/updateById`, data, { withCredentials: true }),
    {
      // Optimistically update the cache value on mutate, but store
      // the old value and return it so that it's accessible in case of
      // an error
      onSuccess: (data, variables) => {
        queryClient.setQueryData([queryKey, variables.id], data.data);
        history.push('.');
      },
      // After success or failure, refetch the todos query
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  if (status == 'loading') {
    return <div>Datensatz wird geladen...</div>;
  }

  if (status == 'error') {
    return <div>Beim Laden des Datensatzes ist ein Fehler aufgetretten.</div>;
  }

  return (
    <div className='px-4'>
      <Form
        id={id}
        data={data}
        history={history}
        onSubmit={!!id ? updateMutation : addMutation}
        user={user}
      />
    </div>
  );
}

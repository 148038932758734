import React from 'react';
import { useSortBy, useTable } from 'react-table';

/**
 *
 * @param {{data: Array, columns: Array}} param0
 * @returns
 */
export default function AppointmentTable({ data, columns }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div className='overflow-hidden overflow-x-scroll'>
      <table
        className='min-w-full divide-y divide-gray-200'
        {...getTableProps()}
      >
        <thead className='bg-gray-100'>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  scope='col'
                  className={`${column.width} px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className='flex flex-row items-end'>
                    {column.render('Header')}
                    <span className='ml-4'>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-5 w-5 -my-1'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              d='M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4'
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-5 w-5 -my-1'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              d='M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12'
                            />
                          </svg>
                        )
                      ) : (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-5 w-5 -my-1 invisible'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12'
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className='bg-white divide-y divide-gray-200'
          {...getTableBodyProps()}
        >
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} className='hover:bg-gray-50' {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      className='px-6 py-4 whitespace-nowrap'
                      {...cell.getCellProps()}
                    >
                      <div className='text-sm text-gray-500'>
                        {cell.render('Cell')}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

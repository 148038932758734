import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Tab, Transition } from '@headlessui/react';
import { DocumentReportIcon } from '@heroicons/react/solid';
import axios from 'axios';
import moment from 'moment';
import React, { Fragment } from 'react';
import { useQuery } from 'react-query';
import fahrtkostenBericht from '../../../Berichte/fahrtkostenBericht';
import fallBericht from '../../../Berichte/fallBericht';
import mitarbeiterBericht from '../../../Berichte/mitarbeiterBericht';
import {
  reportTravel,
  reportYouthWelfareCaseBySpecialistId,
  reportYouthWelfareCasesBySpecialistId,
} from '../../../_api/appointment.services';
import { findAll as findAllSpecialists } from '../../../_api/specialist.services';
import { findAll as findAllYouthWelfareCases } from '../../../_api/youthWelfareCase.services';
import { PrimaryButton } from '../../Button';
import Dropdown from '../../Form/Dropdown/Dropdown';
import TextInput from '../../Form/TextInput/TextInput';

export default function BerichtDialog({
  user,
  title,
  endpoint,
  text,
  primaryAction,
  primaryActionButtonText,
  primaryButtonText,
}) {
  let [isOpen, setIsOpen] = React.useState(false);
  let [reportType, setReportType] = React.useState(0);
  let [youthWelfareCaseId, setYouthWelfareCaseId] = React.useState(null);
  let [specialistId, setSpecialistId] = React.useState(user.id);
  let [startDate, setStartDate] = React.useState(
    moment().startOf('month').format('YYYY-MM-DD')
  );
  let [endDate, setEndDate] = React.useState(
    moment().endOf('month').format('YYYY-MM-DD')
  );
  let [pauschale, setPauschale] = React.useState(0);
  let [appointments, setAppointments] = React.useState([]);

  const {
    status: youthWelfareCasesQueryStatus,
    data: youthWelfareCasesDropdown,
  } = useQuery('YouthWelfareCasesDropdown', () => findAllYouthWelfareCases());
  const { status: specialistsQueryStatus, data: specialistsDropdown } =
    useQuery('SpecialistsDropdown', () => findAllSpecialists());

  function handleAction() {
    if (appointments.length > 0) {
      if (reportType == 0) {
        const specialist = specialistsDropdown.filter(
          (item) => item.id == specialistId
        )[0];
        fahrtkostenBericht(
          appointments,
          startDate,
          endDate,
          pauschale,
          `${specialist.firstname} ${specialist.lastname}`
        );
      } else if (reportType == 1) {
        const specialist = specialistsDropdown.filter(
          (item) => item.id == specialistId
        )[0];
        mitarbeiterBericht(
          appointments,
          `${specialist.firstname} ${specialist.lastname}`,
          startDate,
          endDate
        );
      } else if (reportType == 2) {
        const youthWelfareCase = youthWelfareCasesDropdown.filter(
          (item) => item.id == youthWelfareCaseId
        )[0];
        fallBericht(
          appointments,
          youthWelfareCase.identifier,
          startDate,
          endDate
        );
      }
      closeModal();
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  React.useEffect(() => {
    if (
      moment(startDate, 'YYYY-MM-DD').isValid() &&
      moment(endDate, 'YYYY-MM-DD').isValid()
    ) {
      switch (reportType) {
        case 0:
          reportTravel(specialistId || undefined, startDate, endDate).then(
            (data) => setAppointments(data)
          );
          break;
        case 1:
          reportYouthWelfareCasesBySpecialistId(
            specialistId || undefined,
            startDate,
            endDate
          ).then((data) => setAppointments(data));
          break;
        case 2:
          reportYouthWelfareCaseBySpecialistId(
            specialistId || undefined,
            youthWelfareCaseId || undefined,
            startDate,
            endDate
          ).then((data) => setAppointments(data));
          break;

        default:
          setAppointments([]);
          break;
      }
    } else {
      setAppointments([]);
    }
  }, [reportType, youthWelfareCaseId, specialistId, startDate, endDate]);

  return (
    <>
      <PrimaryButton type='button' onClick={openModal}>
        <FontAwesomeIcon className='mr-2' icon={faFileLines} />
        <span>Bericht</span>
      </PrimaryButton>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto'
          onClose={closeModal}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md  my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='p-6 space-y-6'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900'
                  >
                    {title} Hier können Sie Ihre Berichte erstellen.
                  </Dialog.Title>
                  <hr />
                  <div className='w-full max-w-md px-2 sm:px-0'>
                    <Tab.Group
                      defaultIndex={'FahrtkostenBericht'}
                      onChange={(index) => setReportType(index)}
                    >
                      <Tab.List className='flex p-1 space-x-1 bg-blue-900/20 rounded-xl'>
                        <Tab
                          key={'FahrtkostenBericht'}
                          className={({ selected }) =>
                            classNames(
                              'w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg',
                              'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                              selected
                                ? 'bg-white shadow'
                                : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                            )
                          }
                        >
                          Fahrtkosten
                        </Tab>
                        <Tab
                          key={'Fachkraftbericht'}
                          className={({ selected }) =>
                            classNames(
                              'w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg',
                              'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                              selected
                                ? 'bg-white shadow'
                                : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                            )
                          }
                        >
                          Fachkraft
                        </Tab>
                        <Tab
                          key={'Fallbericht'}
                          className={({ selected }) =>
                            classNames(
                              'w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg',
                              'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                              selected
                                ? 'bg-white shadow'
                                : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                            )
                          }
                        >
                          Fall
                        </Tab>
                      </Tab.List>
                      <Tab.Panels>
                        {[
                          'Fahrtkostenbericht',
                          'Fachkraftbericht',
                          'Fallbericht',
                        ].map((bericht) => (
                          <Tab.Panel
                            key={`${bericht}Panel`}
                            className={`bg-white rounded-xl py-4 space-y-4 focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60`}
                          >
                            {bericht == 'Fallbericht' ? (
                              <div>
                                {youthWelfareCasesQueryStatus == 'success' ? (
                                  <Dropdown
                                    id='youthWelfareCase'
                                    name='youthWelfareCase'
                                    label='Fall'
                                    value={youthWelfareCaseId || ''}
                                    options={youthWelfareCasesDropdown || []}
                                    optionToValue={(item) => item.id}
                                    optionToString={(item) => item.identifier}
                                    onChange={({ target }) => {
                                      const selectedObj =
                                        youthWelfareCasesDropdown.filter(
                                          (item) => item.id == target.value
                                        )[0];
                                      if (selectedObj) {
                                        setYouthWelfareCaseId(selectedObj.id);
                                      } else {
                                        setYouthWelfareCaseId(null);
                                      }
                                    }}
                                  />
                                ) : null}
                              </div>
                            ) : null}

                            <div>
                              {specialistsQueryStatus == 'success' ? (
                                <Dropdown
                                  id='specialist'
                                  name='specialist'
                                  label='Fachkraft'
                                  value={specialistId || ''}
                                  options={specialistsDropdown || []}
                                  optionToValue={(item) => item.id}
                                  optionToString={(item) =>
                                    `${item.firstname} ${item.lastname}`
                                  }
                                  onChange={({ target }) => {
                                    const selectedObj =
                                      specialistsDropdown.filter(
                                        (item) => item.id == target.value
                                      )[0];
                                    if (selectedObj) {
                                      setSpecialistId(selectedObj.id);
                                    } else {
                                      setSpecialistId(null);
                                    }
                                  }}
                                />
                              ) : null}

                              <div>
                                <TextInput
                                  type='date'
                                  id='startDate'
                                  name='startDate'
                                  label='Anfangsdatum'
                                  value={startDate || ''}
                                  onChange={({ target }) => {
                                    setStartDate(
                                      moment(target.value).format('YYYY-MM-DD')
                                    );
                                  }}
                                />
                              </div>

                              <div>
                                <TextInput
                                  type='date'
                                  id='endDate'
                                  name='endDate'
                                  label='Enddatum'
                                  value={endDate || ''}
                                  onChange={({ target }) => {
                                    setEndDate(
                                      moment(target.value).format('YYYY-MM-DD')
                                    );
                                  }}
                                />
                              </div>

                              {bericht == 'Fahrtkostenbericht' ? (
                                <div>
                                  <TextInput
                                    type='number'
                                    id='pauschale'
                                    name='pauschale'
                                    label='Fahrtkostenpauschale'
                                    value={pauschale || ''}
                                    onChange={({ target }) => {
                                      setPauschale(target.value);
                                    }}
                                  />
                                </div>
                              ) : null}
                            </div>
                            <div className='text-sm font-medium text-gray-700'>
                              Es wurden {appointments.length} Termine gefunden.
                            </div>
                          </Tab.Panel>
                        ))}
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                </div>

                <div className='bg-gray-50 px-4 py-3 sm:flex sm:px-6'>
                  <button
                    type='button'
                    onClick={closeModal}
                    className='mt-3 w-full inline-flex justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:w-auto sm:text-sm'
                  >
                    Abbrechen
                  </button>
                  <button
                    type='submit'
                    onClick={handleAction}
                    className='mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                  >
                    Bericht Erstellen
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

import { DateTime } from 'luxon';
import moment from 'moment';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import { deleteById, findAll } from '../_api/youthWelfareCase.services';
import FormWrapper from '../_components/Form/FormWrapper';
import Table from '../_components/Table/Table';
import endpoints from '../_helpers/endpoints';
import FallForm from './FallForm';

const columns = [
  { accessor: 'identifier', Header: 'Bezeichnung' },
  { accessor: 'internalReferenceNumber', Header: 'Aktenzeichen (Praxis)' },
  {
    accessor: 'referenceNumberOnAnnexure',
    Header: 'Aktenzeichen (Jugendamt)',
  },
  {
    accessor: 'referenceNumberOnCSV',
    Header: 'Aktenzeichen (Wirt. Jugendhilfe)',
  },
  {
    accessor: (cell) => {
      if (cell.primarySpecialist) {
        return `${cell.primarySpecialist.firstname} ${cell.primarySpecialist.lastname}`;
      } else {
        return '';
      }
    },
    Header: '1. Fachkraft',
  },
  {
    accessor: (cell) => {
      if (cell.secondarySpecialist) {
        return `${cell.secondarySpecialist.firstname} ${cell.secondarySpecialist.lastname}`;
      } else {
        return '';
      }
    },
    Header: '2. Fachkraft',
  },
  { accessor: 'remark', Header: 'Bemerkung' },
  {
    accessor: (cell) => {
      if (cell.calculationPrinciples?.length == 0) {
        return (
          <div className='px-2 py-2 rounded-lg bg-red-400 items-center flex flex-row justify-center'>
            Abgelaufen
          </div>
        );
      } else if (cell.calculationPrinciples?.length > 0) {
        const calcPrinciplesEndDate = DateTime.fromISO(
          cell.calculationPrinciples[0].endDate
        );
        const calcPrinciplesMonthBeforeEndDate = calcPrinciplesEndDate.plus({
          month: -1,
        });
        const dateToday = DateTime.now();

        if (
          dateToday > calcPrinciplesMonthBeforeEndDate &&
          dateToday < calcPrinciplesEndDate
        ) {
          return (
            <div className='px-2 py-2 rounded-lg bg-yellow-400 items-center flex flex-row justify-center'>
              Läuft ab
            </div>
          );
        } else if (
          dateToday > calcPrinciplesMonthBeforeEndDate &&
          dateToday > calcPrinciplesEndDate
        ) {
          return (
            <div className='px-2 py-2 rounded-lg bg-red-400 items-center flex flex-row justify-center'>
              Abgelaufen
            </div>
          );
        }
      }
    },
    Header: 'Ber.G.',
  },
  {
    accessor: (cell) => moment(cell.createdAt).format('DD.MM.YYYY'),
    Header: 'Erstellt am',
  },
  {
    accessor: (cell) => moment(cell.updatedAt).format('DD.MM.YYYY'),
    Header: 'Geändert am',
  },
];

export default function YouthWelfareCase({ match, history, user }) {
  const { path } = match;
  const queryClient = useQueryClient();
  const endpoint = endpoints.youthWelfareCase;
  const queryKey = 'YouthWelfareCase';

  const { status, data } = useQuery(queryKey, () => findAll());

  const deleteRow = useMutation((id) => deleteById(Number.parseInt(id)), {
    onSuccess: () => {
      // alert('Löschung erfolgreich');
    },
    onError: (error) => {
      alert(error);
    },
    // After success or failure, refetch the query
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => {
          if (status == 'loading') {
            return <div>Datensätze werden geladen...</div>;
          }

          if (status == 'error') {
            return (
              <div>Beim Laden der Datensätze ist ein Fehler aufgetretten.</div>
            );
          }

          return (
            <Table
              title='Fall'
              data={data}
              deleteRow={deleteRow}
              columns={columns}
              match={match}
              history={history}
            />
          );
        }}
      />
      <Route
        path={`${path}/add`}
        render={({ match, history }) => (
          <FormWrapper
            Form={FallForm}
            endpoint={endpoint}
            queryKey='YouthWelfareCase'
            match={match}
            history={history}
          />
        )}
      />
      <Route
        path={`${path}/edit/:id`}
        render={({ match, history }) => (
          <FormWrapper
            Form={FallForm}
            endpoint={endpoint}
            queryKey='YouthWelfareCase'
            match={match}
            history={history}
          />
        )}
      />
    </Switch>
  );
}

import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavigationLink({ children, to }) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex w-full flex-row items-center px-4 py-2 hover:bg-emerald-700 xl:w-max xl:justify-center ${
          isActive ? 'bg-emerald-700' : ''
        }`
      }
    >
      {children}
    </NavLink>
  );
}

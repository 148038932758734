import React from 'react';
import { useQuery } from 'react-query';
import { findAll as findAllClients } from '../_api/client.services';
import { findAll as findAllContacts } from '../_api/contact.services';
import { findAll as findAllGuidances } from '../_api/guidance.services';
import { findAll as findAllInvoiceAddresss } from '../_api/invoiceAddress';
import { findAll as findAllSpecialists } from '../_api/specialist.services';
import { findById } from '../_api/youthWelfareCase.services';
import { findAll as findAllYouthWelfareOffices } from '../_api/youthWelfareOffice.services';
import Checkbox from '../_components/Form/Checkbox/Checkbox';
import Dropdown from '../_components/Form/Dropdown/Dropdown';
import Textarea from '../_components/Form/Textarea/Textarea';
import TextInput from '../_components/Form/TextInput/TextInput';
import FormComboboxWithQuery from '../components/Form/Input/FormComboboxWithQuery';

/**
 *
 * @param {{data: Object, onSubmit: UseMutationResult<AxiosResponse<unknown, any>, unknown, void, unknown>}} props
 * @returns
 */
export default function FallForm({ id, data, history, onSubmit }) {
  const initialFormValues = {
    internalReferenceNumber: '',
    referenceNumberOnCSV: '',
    referenceNumberOnAnnexure: '',
    identifier: '',
    startYear: '',
    remark: '',
    areIndirectAppointmentsBillable: false,
    isYouthWelfareCaseClosed: false,

    // foreign keys
    contactId: null,
    guidanceId: null,
    youthWelfareOfficeId: null,
    clientId: null,
    invoiceAddressId: null,
    primarySpecialistId: null,
    secondarySpecialistId: null,

    //
    createdBy: '',
    updatedBy: '',
    inUseBy: '',
  };
  let [values, setValues] = React.useState(initialFormValues);

  const { status: contactQueryStatus, data: contactDropdownData } = useQuery(
    'ContactDropdown',
    () => findAllContacts()
  );
  const { status: guidanceQueryStatus, data: guidanceDropdownData } = useQuery(
    'GuidanceDropdown',
    () => findAllGuidances()
  );
  const {
    status: youthWelfareOfficeQueryStatus,
    data: youthWelfareOfficeDropdownData,
  } = useQuery('YouthWelfareOfficeDropdown', () =>
    findAllYouthWelfareOffices()
  );
  const { status: clientQueryStatus, data: clientDropdownData } = useQuery(
    'ClientDropdown',
    () => findAllClients()
  );
  const {
    status: invoiceAddressQueryStatus,
    data: invoiceAddressDropdownData,
  } = useQuery('InvoiceAddressDropdown', () => findAllInvoiceAddresss());
  const { status: specialistQueryStatus, data: specialistDropdownData } =
    useQuery('SpecialistDropdown', () => findAllSpecialists());

  React.useEffect(() => {
    if (
      !!id &&
      contactQueryStatus == 'success' &&
      guidanceQueryStatus == 'success' &&
      youthWelfareOfficeQueryStatus == 'success' &&
      clientQueryStatus == 'success' &&
      invoiceAddressQueryStatus == 'success' &&
      specialistQueryStatus == 'success'
    ) {
      findById(Number.parseInt(id)).then((data) => {
        setValues({ ...values, ...data });
      });
    }
  }, [
    id,
    contactQueryStatus,
    guidanceQueryStatus,
    youthWelfareOfficeQueryStatus,
    clientQueryStatus,
    invoiceAddressQueryStatus,
    specialistQueryStatus,
  ]);

  // Generates internalReferenceNumber
  React.useEffect(() => {
    if (
      values.youthWelfareOfficeId &&
      values.startYear &&
      values.guidanceId &&
      values.clientId
    ) {
      const youthWelfareOffice = youthWelfareOfficeDropdownData.filter(
        (element) => element.id == values.youthWelfareOfficeId
      )[0];
      const guidance = guidanceDropdownData.filter(
        (element) => element.id == values.guidanceId
      )[0];
      const client = clientDropdownData.filter(
        (element) => element.id == values.clientId
      )[0];
      setValues({
        ...values,
        internalReferenceNumber: `${
          youthWelfareOffice.abbreviation
        }${values.startYear.slice(-2)}-${guidance.abbreviation}-${
          client.firstname.charAt(0).toUpperCase() +
          client.firstname.charAt(1).toLowerCase() +
          client.lastname.charAt(0).toUpperCase() +
          client.lastname.charAt(1).toLowerCase()
        }`,
      });
    } else {
      setValues({
        ...values,
        internalReferenceNumber: ``,
      });
    }
  }, [
    values.youthWelfareOfficeId,
    values.startYear,
    values.guidanceId,
    values.clientId,
  ]);

  // Generates identifier
  React.useEffect(() => {
    if (values.clientId && values.guidanceId) {
      const guidance = guidanceDropdownData.filter(
        (element) => element.id == values.guidanceId
      )[0];
      const client = clientDropdownData.filter(
        (element) => element.id == values.clientId
      )[0];
      setValues({
        ...values,
        identifier: `${client.lastname}, ${client.firstname} - ${guidance.abbreviation}`,
      });
    } else {
      setValues({
        ...values,
        identifier: ``,
      });
    }
  }, [values.clientId, values.guidanceId]);

  return (
    <>
      <div className='md:grid md:grid-cols-3 md:gap-6'>
        <div className='mt-5 md:mt-0 md:col-span-2'>
          <form
            autoComplete='off'
            onSubmit={(event) => {
              event.preventDefault();
              onSubmit.mutate(values);
            }}
          >
            <div className='shadow sm:rounded-md sm:overflow-hidden'>
              <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                <div className='flex'>
                  <div>
                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                      Fall
                    </h3>
                    <p className='mt-1 text-sm text-gray-600'>Formular.</p>
                  </div>
                </div>
                <hr />
                <FormComboboxWithQuery
                  filterFn={(item, query) =>
                    `${item.firstname?.toLowerCase()}${item.lastname?.toLowerCase()}`.includes(
                      query.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                  label={'Klient'}
                  name={'client'}
                  onChange={(selected) => {
                    if (selected) {
                      setValues({ ...values, clientId: selected });
                    } else {
                      setValues({ ...values, clientId: null });
                    }
                  }}
                  optionToKey={(item) => item.id.toString()}
                  optionToString={(item) =>
                    item
                      ? `${item.firstname} ${item.lastname}`
                      : 'nicht angegeben'
                  }
                  optionToValue={(item) => item.id}
                  selected={values.clientId || ''}
                  queryFn={findAllClients}
                  queryKeys={['ClientsDropdown']}
                />
                {/* {clientQueryStatus != 'loading' ? (
                  clientQueryStatus != 'error' ? (
                    <Dropdown
                      id='client'
                      name='client'
                      label='Klient'
                      value={values.clientId || ''}
                      options={clientDropdownData || []}
                      optionToValue={(item) => item.id}
                      optionToString={(item) =>
                        `${item.firstname} ${item.lastname}`
                      }
                      onChange={({ target }) => {
                        const selectedObj = clientDropdownData.filter(
                          (item) => item.id == target.value
                        )[0];
                        if (selectedObj) {
                          setValues({ ...values, clientId: selectedObj.id });
                        } else {
                          setValues({ ...values, clientId: null });
                        }
                      }}
                    />
                  ) : null
                ) : null} */}

                <FormComboboxWithQuery
                  filterFn={(item, query) =>
                    `${item.name?.toLowerCase()}${item.abbreviation?.toLowerCase()}`.includes(
                      query.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                  label={'Hilfeart'}
                  name={'guidance'}
                  onChange={(selected) => {
                    if (selected) {
                      setValues({ ...values, guidanceId: selected });
                    } else {
                      setValues({ ...values, guidanceId: null });
                    }
                  }}
                  optionToKey={(item) => item.id.toString()}
                  optionToString={(item) =>
                    item
                      ? `${item.name} ${item.abbreviation}`
                      : 'nicht angegeben'
                  }
                  optionToValue={(item) => item.id}
                  selected={values.guidanceId || ''}
                  queryFn={findAllGuidances}
                  queryKeys={['GuidancesDropdown']}
                />

                {/* {guidanceQueryStatus != 'loading' ? (
                  guidanceQueryStatus != 'error' ? (
                    <Dropdown
                      id='guidance'
                      name='guidance'
                      label='Hilfart'
                      value={values.guidanceId || ''}
                      options={guidanceDropdownData || []}
                      optionToValue={(item) => item.id}
                      optionToString={(item) =>
                        `${item.name} ${item.abbreviation}`
                      }
                      onChange={({ target }) => {
                        const selectedObj = guidanceDropdownData.filter(
                          (item) => item.id == target.value
                        )[0];
                        if (selectedObj) {
                          setValues({ ...values, guidanceId: selectedObj.id });
                        } else {
                          setValues({ ...values, guidanceId: null });
                        }
                      }}
                    />
                  ) : null
                ) : null} */}

                <TextInput
                  type='text'
                  id='internalReferenceNumber'
                  name='internalReferenceNumber'
                  label='Aktenzeichen (Praxis)'
                  value={values.internalReferenceNumber}
                  readOnly
                />

                <FormComboboxWithQuery
                  filterFn={(item, query) =>
                    `${item.name?.toLowerCase()}${item.abbreviation?.toLowerCase()}`.includes(
                      query.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                  label={'Jugendamt'}
                  name={'youthWelfareOffice'}
                  onChange={(selected) => {
                    if (selected) {
                      setValues({ ...values, youthWelfareOfficeId: selected });
                    } else {
                      setValues({ ...values, youthWelfareOfficeId: null });
                    }
                  }}
                  optionToKey={(item) => item.id.toString()}
                  optionToString={(item) =>
                    item
                      ? `${item.name} ${item.abbreviation}`
                      : 'nicht angegeben'
                  }
                  optionToValue={(item) => item.id}
                  selected={values.youthWelfareOfficeId || ''}
                  queryFn={findAllYouthWelfareOffices}
                  queryKeys={['YouthWelfareOfficesDropdown']}
                />

                {/* {youthWelfareOfficeQueryStatus != 'loading' ? (
                  youthWelfareOfficeQueryStatus != 'error' ? (
                    <Dropdown
                      id='youthWelfareOffice'
                      name='youthWelfareOffice'
                      label='Jugendamt'
                      value={values.youthWelfareOfficeId || ''}
                      options={youthWelfareOfficeDropdownData || []}
                      optionToValue={(item) => item.id}
                      optionToString={(item) =>
                        `${item.name} ${item.abbreviation}`
                      }
                      onChange={({ target }) => {
                        const selectedObj =
                          youthWelfareOfficeDropdownData.filter(
                            (item) => item.id == target.value
                          )[0];
                        if (selectedObj) {
                          setValues({
                            ...values,
                            youthWelfareOfficeId: selectedObj.id,
                          });
                        } else {
                          setValues({ ...values, youthWelfareOfficeId: null });
                        }
                      }}
                    />
                  ) : null
                ) : null} */}

                <FormComboboxWithQuery
                  filterFn={(item, query) =>
                    `${item.firstname?.toLowerCase()}${item.lastname?.toLowerCase()}`.includes(
                      query.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                  label={'Ansprechpartner'}
                  name={'contact'}
                  onChange={(selected) => {
                    if (selected) {
                      setValues({ ...values, contactId: selected });
                    } else {
                      setValues({ ...values, contactId: null });
                    }
                  }}
                  optionToKey={(item) => item.id.toString()}
                  optionToString={(item) =>
                    item
                      ? `${item.firstname} ${item.lastname} ${
                          item.jugendamt ? item.jugendamt.kuerzel : ''
                        }`
                      : 'nicht angegeben'
                  }
                  optionToValue={(item) => item.id}
                  selected={values.contactId || ''}
                  queryFn={findAllContacts}
                  queryKeys={['ContactsDropdown']}
                />

                {/* {contactQueryStatus != 'loading' ? (
                  contactQueryStatus != 'error' ? (
                    <Dropdown
                      id='contact'
                      name='contact'
                      label='Ansprechpartner'
                      value={values.contactId || ''}
                      options={contactDropdownData || []}
                      optionToValue={(item) => item.id}
                      optionToString={(item) =>
                        `${item.firstname} ${item.lastname} ${
                          item.jugendamt ? item.jugendamt.kuerzel : ''
                        }`
                      }
                      onChange={({ target }) => {
                        const selectedObj = contactDropdownData.filter(
                          (item) => item.id == target.value
                        )[0];
                        if (selectedObj) {
                          setValues({ ...values, contactId: selectedObj.id });
                        } else {
                          setValues({ ...values, contactId: null });
                        }
                      }}
                    />
                  ) : null
                ) : null} */}

                <FormComboboxWithQuery
                  filterFn={(item, query) =>
                    `${item.recipient?.toLowerCase()}${item.streetaddress?.toLowerCase()}, ${
                      item.zipcode
                    } ${item.city}`.includes(
                      query.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                  label={'Rechnungsadresse'}
                  name={'invoiceAddress'}
                  onChange={(selected) => {
                    if (selected) {
                      setValues({ ...values, invoiceAddressId: selected });
                    } else {
                      setValues({ ...values, invoiceAddressId: null });
                    }
                  }}
                  optionToKey={(item) => item.id.toString()}
                  optionToString={(item) =>
                    item
                      ? `${item.recipient}, ${item.streetaddress}, ${item.zipcode} ${item.city}`
                      : 'nicht angegeben'
                  }
                  optionToValue={(item) => item.id}
                  selected={values.invoiceAddressId || ''}
                  queryFn={findAllInvoiceAddresss}
                  queryKeys={['InvoiceAddresssDropdown']}
                />

                {/* {invoiceAddressQueryStatus != 'loading' ? (
                  invoiceAddressQueryStatus != 'error' ? (
                    <Dropdown
                      id='invoiceAddress'
                      name='invoiceAddress'
                      label='Rechnungsadresse'
                      value={values.invoiceAddressId || ''}
                      options={invoiceAddressDropdownData || []}
                      optionToValue={(item) => item.id}
                      optionToString={(item) =>
                        `${item.recipient}, ${item.streetaddress}, ${item.zipcode} ${item.city}`
                      }
                      onChange={({ target }) => {
                        const selectedObj = invoiceAddressDropdownData.filter(
                          (item) => item.id == target.value
                        )[0];
                        if (selectedObj) {
                          setValues({
                            ...values,
                            invoiceAddressId: selectedObj.id,
                          });
                        } else {
                          setValues({ ...values, invoiceAddressId: null });
                        }
                      }}
                    />
                  ) : null
                ) : null} */}

                <TextInput
                  type='text'
                  id='referenceNumberOnAnnexure'
                  name='referenceNumberOnAnnexure'
                  label='Aktenzeichen (Jugendamt, Anlage)'
                  value={values.referenceNumberOnAnnexure}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='referenceNumberOnCSV'
                  name='referenceNumberOnCSV'
                  label='Aktenzeichen (Wirt. Jugendhilfe, CSV)'
                  value={values.referenceNumberOnCSV}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <TextInput
                  type='text'
                  id='identifier'
                  name='identifier'
                  label='Bezeichnung'
                  value={values.identifier}
                  readOnly
                />

                <TextInput
                  type='number'
                  id='startYear'
                  name='startYear'
                  label='Startjahr'
                  value={values.startYear}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />

                <FormComboboxWithQuery
                  filterFn={(item, query) =>
                    `${item.firstname?.toLowerCase()}${item.firstname?.toLowerCase()}`.includes(
                      query.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                  label={'1. Fachkraft'}
                  name={'primarySpecialist'}
                  onChange={(selected) => {
                    if (selected) {
                      setValues({ ...values, primarySpecialistId: selected });
                    } else {
                      setValues({ ...values, primarySpecialistId: null });
                    }
                  }}
                  optionToKey={(item) => item.id.toString()}
                  optionToString={(item) =>
                    item
                      ? `${item.firstname} ${item.lastname}`
                      : 'nicht angegeben'
                  }
                  optionToValue={(item) => item.id}
                  selected={values.primarySpecialistId || ''}
                  queryFn={findAllSpecialists}
                  queryKeys={['SpecialistsDropdown']}
                />

                {/* {specialistQueryStatus != 'loading' ? (
                  specialistQueryStatus != 'error' ? (
                    <Dropdown
                      id='primarySpecialist'
                      name='primarySpecialist'
                      label='1. Fachkraft'
                      value={values.primarySpecialistId || ''}
                      options={specialistDropdownData || []}
                      optionToValue={(item) => item.id}
                      optionToString={(item) =>
                        `${item.firstname} ${item.lastname}`
                      }
                      onChange={({ target }) => {
                        const selectedObj = specialistDropdownData.filter(
                          (item) => item.id == target.value
                        )[0];
                        if (selectedObj) {
                          setValues({
                            ...values,
                            primarySpecialistId: selectedObj.id,
                          });
                        } else {
                          setValues({ ...values, primarySpecialistId: null });
                        }
                      }}
                    />
                  ) : null
                ) : null} */}

                <FormComboboxWithQuery
                  filterFn={(item, query) =>
                    `${item.firstname?.toLowerCase()}${item.firstname?.toLowerCase()}`.includes(
                      query.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                  label={'2. Fachkraft'}
                  name={'secondarySpecialist'}
                  onChange={(selected) => {
                    if (selected) {
                      setValues({ ...values, secondarySpecialistId: selected });
                    } else {
                      setValues({ ...values, secondarySpecialistId: null });
                    }
                  }}
                  optionToKey={(item) => item.id.toString()}
                  optionToString={(item) =>
                    item
                      ? `${item.firstname} ${item.lastname}`
                      : 'nicht angegeben'
                  }
                  optionToValue={(item) => item.id}
                  selected={values.secondarySpecialistId || ''}
                  queryFn={findAllSpecialists}
                  queryKeys={['SpecialistsDropdown']}
                />

                {/* {specialistQueryStatus != 'loading' ? (
                  specialistQueryStatus != 'error' ? (
                    <Dropdown
                      id='secondarySpecialist'
                      name='secondarySpecialist'
                      label='2. Fachkraft'
                      value={values.secondarySpecialistId || ''}
                      options={specialistDropdownData || []}
                      optionToValue={(item) => item.id}
                      optionToString={(item) =>
                        `${item.firstname} ${item.lastname}`
                      }
                      onChange={({ target }) => {
                        const selectedObj = specialistDropdownData.filter(
                          (item) => item.id == target.value
                        )[0];
                        if (selectedObj) {
                          setValues({
                            ...values,
                            secondarySpecialistId: selectedObj.id,
                          });
                        } else {
                          setValues({ ...values, secondarySpecialistId: null });
                        }
                      }}
                    />
                  ) : null
                ) : null} */}

                <Checkbox
                  id='areIndirectAppointmentsBillable'
                  name='areIndirectAppointmentsBillable'
                  label='Indirekte Termine Abrechnen'
                  checked={values.areIndirectAppointmentsBillable}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.checked });
                  }}
                />

                <Checkbox
                  id='isYouthWelfareCaseClosed'
                  name='isYouthWelfareCaseClosed'
                  label='Ist Abgeschlossen'
                  checked={values.isYouthWelfareCaseClosed}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.checked });
                  }}
                />

                <Textarea
                  id='remark'
                  name='remark'
                  label='Bemerkung'
                  value={values.remark}
                  onChange={({ target }) => {
                    setValues({ ...values, [target.name]: target.value });
                  }}
                />
              </div>
              <div className='bg-gray-50 px-4 py-3 sm:flex sm:items-center sm:px-6'>
                <button
                  type='button'
                  onClick={() => history.push(values.id ? '..' : '.')}
                  className='mt-3 w-full inline-flex justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:w-auto sm:text-sm'
                >
                  Abbrechen
                </button>
                <button
                  type='submit'
                  className='mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                >
                  Speichern
                </button>
                <div className='ml-3'>
                  {onSubmit.isLoading ? (
                    <span>In Bearbeitung...</span>
                  ) : onSubmit.isSuccess ? (
                    <span>Erfolgreich gespeichert.</span>
                  ) : onSubmit.isError ? (
                    <span>Fehler aufgetretten.</span>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

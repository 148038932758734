import config from './config';

export default {
  // contact: `${config.API_URL}/contact`,
  bewilligung: `${config.API_URL}/bewilligung`,
  fall: `${config.API_URL}/fall`,
  hilfeart: `${config.API_URL}/hilfeart`,
  internerTermin: `${config.API_URL}/interner_termin`,
  jugendamt: `${config.API_URL}/jugendamt`,
  kontingent: `${config.API_URL}/kontingent`,
  patient: `${config.API_URL}/patient`,
  patientenTermin: `${config.API_URL}/patienten_termin`,
  praxis: `${config.API_URL}/praxis`,
  rechnungsadresse: `${config.API_URL}/rechnungsadresse`,
  therapeut: `${config.API_URL}/therapeut`,
  qualifikation: `${config.API_URL}/qualifikation`,
  rechnung: `${config.API_URL}/rechnung`,
  bericht: `${config.API_URL}/bericht`,
  authentication: `${config.API_URL}`,
  api: `${config.API_URL}`,

  //
  appointment: `${config.API_URL}/appointment`,
  auth: `${config.API_URL}/auth`,
  calculationPrinciple: `${config.API_URL}/calculationPrinciple`,
  client: `${config.API_URL}/client`,
  contact: `${config.API_URL}/contact`,
  guidance: `${config.API_URL}/guidance`,
  internalAppointment: `${config.API_URL}/internalAppointment`,
  invoiceAddress: `${config.API_URL}/invoiceAddress`,
  qualification: `${config.API_URL}/qualification`,
  referenceData: `${config.API_URL}/referenceData`,
  specialist: `${config.API_URL}/specialist`,
  youthWelfareCase: `${config.API_URL}/youthWelfareCase`,
  youthWelfareOffice: `${config.API_URL}/youthWelfareOffice`,
};

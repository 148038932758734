import Cookies from 'js-cookie';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuthenticationContext } from '../../components/Authentication/AuthenticationContext';
import { login } from '../../_api/auth.services';
import { PrimaryButton } from '../Button';
import TextInput from '../Form/TextInput/TextInput';

const initialFormData = {
  username: '',
  password: '',
};

export default function Login({ setUser }) {
  const [data, setData] = React.useState(initialFormData);
  const [redirect, setRedirect] = React.useState(false);
  const auth = useAuthenticationContext();

  async function handleSubmit(event) {
    event.preventDefault();
    const res = await auth.signIn(data.username, data.password);
    if (res.status == 200) {
      setUser({ ...JSON.parse(Cookies.get('x-remote-user').slice(2)) });
      setRedirect(true);
    } else {
      alert('Überprüfen Sie bitte ihre Anmeldedaten.');
    }
    // console.log(res);
    // login(data.username, data.password)
    //   .then((res) => {
    //     if (res.status == 200) {
    //       setUser({ ...JSON.parse(Cookies.get('x-remote-user').slice(2)) });
    //     } else {
    //       alert('Überprüfen Sie bitte ihre Anmeldedaten.');
    //     }
    //   })
    //   .catch((err) => {
    //     alert(err.response.data.message);
    //   });
  }

  return (
    <>
      {redirect ? <Redirect to={'/'} /> : null}
      <div className='flex px-4 lg:p-0'>
        <div className=' lg:ml-auto lg:mr-auto w-full lg:w-96 bg-white rounded-lg shadow-md'>
          <form autoComplete='off' onSubmit={handleSubmit}>
            <div className='flex flex-col p-4 gap-4'>
              <h1 className='font-semibold'>Anmeldebereich</h1>

              <TextInput
                id='username'
                name='username'
                type='text'
                label='Benutzername'
                value={data.username}
                onChange={(event) => {
                  const { name, value } = event.target;
                  setData({ ...data, [name]: value });
                }}
              />

              <TextInput
                id='password'
                name='password'
                type='password'
                label='Passwort'
                value={data.password}
                onChange={(event) => {
                  const { name, value } = event.target;
                  setData({ ...data, [name]: value });
                }}
              />
            </div>
            <div className='flex items-center bg-gray-50 px-4 py-3 gap-4 rounded-b-lg'>
              <PrimaryButton type='submit'>Anmelden</PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

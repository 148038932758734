import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import axios from 'axios';
import FormWrapper from '../_components/Form/FormWrapper';
import Table from '../_components/Table/Table';
import endpoints from '../_helpers/endpoints';
import QualifikationForm from './QualifikationForm';
import { deleteById, findAll } from '../_api/qualification.services';

const columns = [{ accessor: 'name', Header: 'Bezeichnung' }];

export default function Qualification({ match, history, user }) {
  const { path } = match;
  const queryClient = useQueryClient();
  const endpoint = endpoints.qualification;
  const queryKey = 'Qualification';

  const { status, data } = useQuery(queryKey, () => findAll());

  const deleteRow = useMutation((id) => deleteById(Number.parseInt(id)), {
    onSuccess: () => {
      // alert('Löschung erfolgreich');
    },
    onError: (error) => {
      alert(error);
    },
    // After success or failure, refetch the query
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => {
          if (status == 'loading') {
            return <div>Datensätze werden geladen...</div>;
          }

          if (status == 'error') {
            return (
              <div>Beim Laden der Datensätze ist ein Fehler aufgetretten.</div>
            );
          }

          return (
            <Table
              title='Qualifikationen'
              data={data}
              deleteRow={deleteRow}
              columns={columns}
              match={match}
              history={history}
            />
          );
        }}
      />
      <Route
        path={`${path}/add`}
        render={({ match, history }) => (
          <FormWrapper
            Form={QualifikationForm}
            endpoint={endpoint}
            queryKey='Qualification'
            match={match}
            history={history}
          />
        )}
      />
      <Route
        path={`${path}/edit/:id`}
        render={({ match, history }) => (
          <FormWrapper
            Form={QualifikationForm}
            endpoint={endpoint}
            queryKey='Qualification'
            match={match}
            history={history}
          />
        )}
      />
    </Switch>
  );
}

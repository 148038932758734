import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { PrimaryButton, SecondaryButton } from '../../Button';

export default function ConfirmationDialog({
  title,
  data,
  text,
  primaryAction,
  primaryActionButtonText,
  primaryButtonText,
}) {
  let [isOpen, setIsOpen] = React.useState(false);

  function handleAction() {
    if (primaryAction) {
      primaryAction();
    }
    closeModal();
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <button
        className='inline-flex items-center rounded-lg px-4 py-2 text-sm font-medium text-red-600 hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2'
        onClick={openModal}
      >
        {primaryButtonText}
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto'
          onClose={closeModal}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md  my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='p-6'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900'
                  >
                    {title}
                  </Dialog.Title>
                  <hr className='mt-2 mb-2' />
                  <div className='mb-4 overflow-hidden'>
                    <p className='text-sm text-gray-500'>{text}</p>
                    {data ? <code>{JSON.stringify(data, 2)}</code> : null}
                  </div>
                </div>

                <div className='bg-gray-50 px-4 py-3 sm:flex sm:px-6'>
                  <button
                    type='button'
                    onClick={closeModal}
                    className='mt-3 w-full inline-flex justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:w-auto sm:text-sm'
                  >
                    Abbrechen
                  </button>
                  <button
                    type='submit'
                    onClick={handleAction}
                    className='mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                  >
                    {primaryActionButtonText}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

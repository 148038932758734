import { post } from './_axios';
import endpoints from '../_helpers/endpoints';
/**
 *
 */
export async function signInRequest(data) {
  const response = await post(`${endpoints.auth}/login`, data, null);
  return response;
}

/**
 *
 */
export async function signOutRequest() {
  const response = await post(`${endpoints.auth}/logout`, {}, null);
  return response;
}

/**
 *
 */
export async function silentSignInRequest() {
  const response = await fetch(`${endpoints.auth}/silentLogin`, {
    credentials: 'same-origin',
    method: 'POST',
  });

  if (response.status == 200) {
    return true;
  } else {
    return false;
  }
}

import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import {
  reportHoursByStartDateAndEndDate,
  reportHoursByYear,
} from '../../_api/appointment.services';
import { findAllAsAdminOrBySpecialistId } from '../../_api/youthWelfareCase.services';
import Dropdown from '../../_components/Form/Dropdown/Dropdown';
import TextInput from '../../_components/Form/TextInput/TextInput';
import endpoints from '../../_helpers/endpoints';
import StatisticsTable from './StatisticsTable';

/**
 *
 * @param {{data: Object, onSubmit: UseMutationResult<AxiosResponse<unknown, any>, unknown, void, unknown>}} props
 * @returns
 */
export default function StatisticsPanel({ user }) {
  let [youthWelfareCaseId, setYouthWelfareCaseId] = React.useState(null);
  let [identifier, setIdentifier] = React.useState(null);
  let [startDate, setStartDate] = React.useState(null);
  let [endDate, setEndDate] = React.useState(null);

  let [fallStatistikRange, setFallStatistikRange] = React.useState([]);
  let [fallStatistikYear, setFallStatistikYear] = React.useState([]);

  async function get(endpoint) {
    const { data } = await axios.get(endpoint, {
      withCredentials: true,
    });
    return data;
  }

  const {
    status: youthWelfareCaseQueryStatus,
    data: youthWelfareCaseDropdown,
  } = useQuery('YouthWelfareCaseDropdown', () =>
    findAllAsAdminOrBySpecialistId(user.id)
  );

  React.useEffect(() => {
    // if (youthWelfareCaseId) {
    //   get(`${endpoints.fall}/${youthWelfareCaseId}/yearly_statistics`).then(
    //     (res) => {
    //       setFallStatistikYear(res);
    //     }
    //   );
    // }
    // if (
    //   youthWelfareCaseId &&
    //   moment(startDate, 'YYYY-MM-DD').isValid() &&
    //   moment(endDate, 'YYYY-MM-DD').isValid()
    // ) {
    //   get(
    //     `${endpoints.fall}/${youthWelfareCaseId}/daterange_statistics?startDate=${startDate}&endDate=${endDate}`
    //   ).then((res) => {
    //     setFallStatistikRange(res);
    //   });
    // }
    if (
      moment(startDate, 'YYYY-MM-DD').isValid() &&
      moment(endDate, 'YYYY-MM-DD').isValid()
    ) {
      reportHoursByStartDateAndEndDate(
        user.id,
        youthWelfareCaseId,
        startDate,
        endDate
      ).then((data) => {
        setFallStatistikRange(data);
      });
    }

    if (youthWelfareCaseId) {
      reportHoursByYear(user.id, youthWelfareCaseId).then((data) => {
        setFallStatistikYear(data);
      });
    }
  }, [youthWelfareCaseId, startDate, endDate]);

  return (
    <div className='shadow sm:rounded-md sm:overflow-hidden'>
      <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
        <div className='overflow-hidden'>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>
            Statistik
          </h3>
          <p className='mt-1 text-sm text-gray-600'>
            Wählen Sie einen Fall aus um entsprechende Statistiken einsehen zu
            können.
          </p>
        </div>
        <hr />
        <div className='grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-4'>
          <div className='col-span-1 md:col-span-2'>
            {youthWelfareCaseQueryStatus == 'success' ? (
              <Dropdown
                id='statistics_fall'
                name='statistics_fall'
                label='Fall'
                value={youthWelfareCaseId || ''}
                options={youthWelfareCaseDropdown || []}
                optionToValue={(item) => item.id}
                optionToString={(item) => item.identifier}
                onChange={({ target }) => {
                  const selectedObj = youthWelfareCaseDropdown.filter(
                    (item) => item.id == target.value
                  )[0];
                  if (selectedObj) {
                    setYouthWelfareCaseId(selectedObj.id);
                  } else {
                    setYouthWelfareCaseId(null);
                  }
                }}
              />
            ) : null}
          </div>

          <div>
            <TextInput
              type='date'
              id='startDate'
              name='startDate'
              label='Anfangsdatum'
              value={startDate || ''}
              onChange={({ target }) => {
                setStartDate(moment(target.value).format('YYYY-MM-DD'));
              }}
            />
          </div>

          <div>
            <TextInput
              type='date'
              id='endDate'
              name='endDate'
              label='Enddatum'
              value={endDate || ''}
              onChange={({ target }) => {
                setEndDate(moment(target.value).format('YYYY-MM-DD'));
              }}
            />
          </div>
        </div>
        <div>
          <div className='overflow-x-auto'>
            Stunden zum Fall {identifier || ''} im Zeitraum{' '}
            {moment(startDate).format('DD.MM.') || ''} -{' '}
            {moment(endDate).format('DD.MM.') || ''}
            <StatisticsTable
              data={[fallStatistikRange]}
              columns={[
                { accessor: 'billable', Header: 'Abrechenbar' },
                { accessor: 'nonbillable', Header: 'N. Abrechenbar' },
                { accessor: 'direct', Header: 'Direkt' },
                { accessor: 'indirect', Header: 'Indirekt' },
                { accessor: 'internal', Header: 'Praxis' },
                { accessor: 'used', Header: 'Gesamt Dauer' },
              ]}
            />
          </div>
          Jahres Ansicht
          <div className='overflow-x-auto'>
            <StatisticsTable
              data={fallStatistikYear}
              columns={[
                { accessor: 'month', Header: 'Monat' },
                { accessor: 'billable', Header: 'Abrechenbar' },
                { accessor: 'nonbillable', Header: 'N. Abrechenbar' },
                { accessor: 'direct', Header: 'Direkt' },
                { accessor: 'indirect', Header: 'Indirekt' },
                { accessor: 'internal', Header: 'Praxis' },
                { accessor: 'used', Header: 'Gesamt Dauer' },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import axios from 'axios';
import endpoints from '../_helpers/endpoints';

/**
 *
 * @param {string} username
 * @param {string} password
 * @returns {Promise<>}
 */
export async function login(username, password) {
  return axios.post(
    `${endpoints.auth}/login`,
    { username, password },
    { withCredentials: true }
  );
}

/**
 *
 * @returns {Promise<>}
 */
export async function logout() {
  return axios.post(`${endpoints.auth}/logout`, {}, { withCredentials: true });
}

/**
 *
 * @returns {Promise<>}
 */
export async function silentLogin() {
  return axios.get(`${endpoints.auth}/silentLogin`, {
    withCredentials: true,
  });
}

import React from 'react';

/**
 *
 * @param {React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>} props
 * @returns
 */
export default function Dropdown(props) {
  const {
    label,
    errorLabel,
    invalid,
    options,
    optionToValue,
    optionToString,
    ...rest
  } = props;

  return (
    <div className='col-span-6 sm:col-span-3'>
      <label
        htmlFor={rest.id}
        className='block text-sm font-medium text-gray-700'
      >
        {label}
      </label>
      <select
        {...rest}
        className={`${
          invalid ? 'ring-red-500 border-red-500' : ''
        } mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm`}
      >
        <option value=''></option>
        {options.map((obj) => {
          return (
            <option
              key={`${rest.name}:option:${optionToValue(obj)}`}
              value={optionToValue(obj)}
            >
              {optionToString(obj)}
            </option>
          );
        })}
      </select>
      <label
        htmlFor={rest.id}
        className={`${
          invalid ? 'block' : 'hidden'
        } text-sm font-medium text-red-700`}
      >
        {errorLabel}
      </label>
    </div>
  );
}

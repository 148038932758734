import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import axios from 'axios';
import FormWrapper from '../_components/Form/FormWrapper';
import Table from '../_components/Table/Table';
import endpoints from '../_helpers/endpoints';
import TherapeutForm from './TherapeutForm';
import { deleteById, findAll } from '../_api/specialist.services';

const columns = [
  { accessor: 'firstname', Header: 'Vorname' },
  { accessor: 'lastname', Header: 'Nachname' },
  { accessor: 'email', Header: 'Email' },
  { accessor: 'qualification.name', Header: 'Qualifikation' },
];

export default function Specialist({ match, history, user }) {
  const { path } = match;
  const queryClient = useQueryClient();
  const endpoint = endpoints.specialist;
  const queryKey = 'Specialist';

  async function get() {
    const { data } = await axios.get(endpoint, {
      withCredentials: true,
    });
    return data;
  }

  const { status, data } = useQuery(queryKey, () => findAll());

  const deleteRow = useMutation((id) => deleteById(Number.parseInt(id)), {
    onSuccess: () => {
      // alert('Löschung erfolgreich');
    },
    onError: (error) => {
      alert(error);
    },
    // After success or failure, refetch the query
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => {
          if (status == 'loading') {
            return <div>Datensätze werden geladen...</div>;
          }

          if (status == 'error') {
            return (
              <div>Beim Laden der Datensätze ist ein Fehler aufgetretten.</div>
            );
          }

          return (
            <Table
              title='Fachkräfte'
              data={data}
              deleteRow={deleteRow}
              columns={columns}
              match={match}
              history={history}
            />
          );
        }}
      />
      <Route
        path={`${path}/add`}
        render={({ match, history }) => (
          <FormWrapper
            Form={TherapeutForm}
            endpoint={endpoint}
            queryKey='Specialist'
            match={match}
            history={history}
          />
        )}
      />
      <Route
        path={`${path}/edit/:id`}
        render={({ match, history }) => (
          <FormWrapper
            Form={TherapeutForm}
            endpoint={endpoint}
            queryKey='Specialist'
            match={match}
            history={history}
          />
        )}
      />
    </Switch>
  );
}

import axios from 'axios';
import Cookies from 'js-cookie';
import React, { Fragment } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Bewilligung from '../../Bewilligung';
import AuthenticationProvider from '../../components/Authentication/AuthenticationProvider';
import Contact from '../../Contact';
import Fall from '../../Fall';
import Hilfeart from '../../Hilfeart';
import InternerTermin from '../../InternerTermin';
import Jugendamt from '../../Jugendamt';
import Patient from '../../Patient';
import PatientenTermin from '../../PatientenTermin';
import Praxis from '../../Praxis';
import Qualifikation from '../../Qualifikation';
import Rechnung from '../../Rechnung';
import Rechnungsadresse from '../../Rechnungsadresse';
import Statistics from '../../Statistics';
import Therapeut from '../../Therapeut';
import endpoints from '../../_helpers/endpoints';
import Login from '../Login/Login';
import Navbar from '../Navbar/Navbar';
import './App.css';

export default function App() {
  const [user, setUser] = React.useState(
    Cookies.get('x-remote-user')
      ? { ...JSON.parse(Cookies.get('x-remote-user').slice(2)) }
      : {
          specialistId: null,
          fullname: null,
          roles: null,
        }
  );

  React.useEffect(() => {
    axios
      .get(`${endpoints.auth}/silentLogin`, {
        withCredentials: true,
      })
      .catch(() => {
        if (window.location.pathname !== '/login') {
          window.location.replace('/login');
        }
        if (Cookies.get('x-remote-user')) {
          Cookies.remove('x-remote-user');
        }
      });
  }, [window.location]);

  return (
    <Fragment>
      <AuthenticationProvider>
        <ReactQueryDevtools />
        <Router>
          <Navbar user={user} />

          <main id='content' className='px-4 pb-4'>
            <Switch>
              <Route
                path='/login'
                render={({ match, history }) => (
                  <Login match={match} history={history} setUser={setUser} />
                )}
              />
              <Route
                path='/ansprechpartner'
                render={({ match, history }) => (
                  <Contact match={match} history={history} />
                )}
              />
              <Route
                path='/berechnungsgrundlage'
                render={({ match, history }) => (
                  <Bewilligung match={match} history={history} />
                )}
              />
              <Route
                path='/fall'
                render={({ match, history }) => (
                  <Fall match={match} history={history} />
                )}
              />
              <Route
                path='/hilfeart'
                render={({ match, history }) => (
                  <Hilfeart match={match} history={history} />
                )}
              />
              <Route
                path='/internerTermin'
                render={({ match, history }) => (
                  <InternerTermin match={match} history={history} user={user} />
                )}
              />
              <Route
                path='/jugendamt'
                render={({ match, history }) => (
                  <Jugendamt match={match} history={history} />
                )}
              />
              <Route
                path='/patient'
                render={({ match, history }) => (
                  <Patient match={match} history={history} />
                )}
              />
              <Route
                path='/patientenTermin'
                render={({ match, history }) => (
                  <PatientenTermin
                    match={match}
                    history={history}
                    user={user}
                  />
                )}
              />
              <Route
                path='/statistics'
                render={({ match, history }) => (
                  <Statistics match={match} history={history} />
                )}
              />
              <Route
                path='/praxis'
                render={({ match, history }) => (
                  <Praxis match={match} history={history} />
                )}
              />
              <Route
                path='/qualifikation'
                render={({ match, history }) => (
                  <Qualifikation match={match} history={history} />
                )}
              />
              <Route
                path='/rechnungsadresse'
                render={({ match, history }) => (
                  <Rechnungsadresse match={match} history={history} />
                )}
              />
              <Route
                path='/therapeut'
                render={({ match, history }) => (
                  <Therapeut match={match} history={history} />
                )}
              />
              <Route
                path='/rechnung'
                render={({ match, history }) => (
                  <Rechnung match={match} history={history} />
                )}
              />
            </Switch>
          </main>
        </Router>
      </AuthenticationProvider>
    </Fragment>
  );
}

import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { useQueryClient } from 'react-query';
import { findAllByYouthWelfareCaseId } from '../../_api/appointment.services';
import AppointmentTable from './AppointmentTable';

/**
 *
 * @param {{user:JSON}} props
 * @returns
 */
export default function AppointmentPanel({
  user,
  specialistId,
  youthWelfareCaseId,
}) {
  let [appointments, setAppointments] = React.useState([]);

  React.useEffect(() => {
    if (specialistId && youthWelfareCaseId) {
      findAllByYouthWelfareCaseId(youthWelfareCaseId).then((data) => {
        setAppointments(data);
      });
    }
  }, [specialistId, youthWelfareCaseId]);

  return (
    <div className='shadow sm:rounded-md sm:overflow-hidden'>
      <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
        <div className='overflow-hidden'>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>
            Terminübersicht
          </h3>
          <p className='mt-1 text-sm text-gray-600'>
            Wählen Sie einen Fall aus um entsprechende Termine zu sehen.
          </p>
        </div>
        <hr />

        {appointments.length > 0 ? (
          <>
            <AppointmentTable
              data={appointments || []}
              columns={[
                {
                  accessor: (cell) => moment(cell.date).format('DD.MM.YYYY'),
                  Header: 'Datum',
                },
                {
                  accessor: (cell) => `${cell.contactActivity}`,
                  Header: 'Kontaktart',
                },
                {
                  accessor: (cell) => `${cell.startTime}`,
                  Header: 'Termin Begin',
                },
                {
                  accessor: (cell) => `${cell.endTime}`,
                  Header: 'Termin Ende',
                },
                {
                  accessor: (cell) =>
                    `${cell.specialist.firstname} ${cell.specialist.lastname}`,
                  Header: 'Fachkraft',
                },
                { accessor: 'appointmentType', Header: 'Terminart' },
              ]}
            />
          </>
        ) : null}
      </div>
    </div>
  );
}

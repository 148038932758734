import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

export default function fallBericht(
  appointments,
  identifier,
  startDate,
  endDate
) {
  const marginL = 15;
  const marginR = 195;
  const doc = new jsPDF();

  doc.setFont('times', 'normal');
  doc.setFontSize(14);
  doc.text(
    `Fall Bericht ${identifier} von ${moment(startDate, 'YYYY-MM-DD').format(
      'DD.MM.YYYY'
    )} bis ${moment(endDate, 'YYYY-MM-DD').format('DD.MM.YYYY')}`,
    marginL,
    10
  );

  doc.setFontSize(10);
  doc.autoTable({
    body: appointments,
    headStyles: {
      textColor: 80,
      fillColor: [224, 224, 224],
      lineWidth: 0.1,
    },
    bodyStyles: { valign: 'middle', lineWidth: 0.1 },
    alternateRowStyles: { fillColor: [244, 244, 244] },
    columns: [
      { header: 'Fachkraft', dataKey: 'specialist' },
      { header: 'Datum', dataKey: 'date' },
      { header: 'Termin Begin', dataKey: 'startTime' },
      { header: 'Termin Ende', dataKey: 'endTime' },
      { header: 'Termin Dauer', dataKey: 'duration' },
      { header: 'Dokumentation', dataKey: 'documentation' },
    ],
    didParseCell: function (data) {
      if (data.section == 'body') {
        if (data.column.dataKey == 'specialist') {
          data.cell.text = `${data.cell.raw.firstname} ${data.cell.raw.lastname}`;
        }
        if (data.column.dataKey == 'date') {
          data.cell.text = data.cell.raw
            ? moment(data.cell.raw, 'YYYY-MM-DD').format('DD.MM.YYYY')
            : 'Kein Angabe';
        }
        if (data.column.dataKey == 'duration') {
          data.cell.text = data.cell.raw
            ? data.cell.raw.toString().replace('.', ',')
            : 'Keine Angabe';
        }
      }
    },
  });

  const blobPDF = new Blob([doc.output('blob')], { type: 'application/pdf' });
  const blobUrl = URL.createObjectURL(blobPDF);
  const a = document.createElement('a');

  a.style = 'display: none';
  a.href = blobUrl;
  a.target = '_blank';
  a.download = `fall_bericht_${identifier}_${moment(
    startDate,
    'YYYY.MM.DD'
  ).format('DDMMYYYY')}${moment(endDate, 'YYYY.MM.DD').format('DDMMYYYY')}.pdf`;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  // doc.save(
  // `fall_bericht_${identifier}_${moment(startDate, 'YYYY.MM.DD').format(
  //   'DDMMYYYY'
  // )}${moment(endDate, 'YYYY.MM.DD').format('DDMMYYYY')}`
  // );
}

import {
  faFilter,
  faPlusCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../Button';
import { findAllAsAdminOrBySpecialistId as findAllSpecialistsAsAdminOrBySpecialistId } from '../../_api/specialist.services';
import { findAllAsAdminOrBySpecialistId as findAllYouthWelfareCasesAsAdminOrBySpecialistId } from '../../_api/youthWelfareCase.services';
import { useQuery, useQueryClient } from 'react-query';
import Dropdown from '../Form/Dropdown/Dropdown';
import TextInput from '../Form/TextInput/TextInput';
import { DateTime } from 'luxon';

export default function TableFilterDialog({ setWhereFn }) {
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filterConcatOp, setFilterConcatOp] = useState('AND');
  const [selectedNewFilter, setSelectedNewFilter] = useState('');
  const queryClient = useQueryClient();

  const {
    status: youthWelfareCaseQueryStatus,
    data: youthWelfareCaseDropdownData,
  } = useQuery('YouthWelfareCasesDropdown', () => {
    return findAllYouthWelfareCasesAsAdminOrBySpecialistId();
  });
  const { status: specialistQueryStatus, data: specialistDropdownData } =
    useQuery('SpecialistsDropdown', () => {
      return findAllSpecialistsAsAdminOrBySpecialistId();
    });

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <SecondaryButton onClick={() => openModal()}>
        <FontAwesomeIcon className='mr-2' icon={faFilter} />
        Filter
      </SecondaryButton>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-50' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6'
                  >
                    Filter Einstellungen
                  </Dialog.Title>
                  <p className='text-sm font-medium text-gray-700'>
                    Sie können die Filter miteinander verknüpfen durch einen
                    'UND' oder einen 'ODER' Operator.
                  </p>
                  <div className='mt-4'>
                    <div className='flex flex-row'>
                      <Dropdown
                        id='filterConcatOp'
                        name='filterConcatOp'
                        value={filterConcatOp}
                        options={[
                          { id: 'AND', name: 'UND' },
                          { id: 'OR', name: 'ODER' },
                        ]}
                        optionToValue={(item) => item.id}
                        optionToString={(item) => item.name}
                        onChange={({ target }) => {
                          const selectedObj = ['AND', 'OR'].filter(
                            (item) => item == target.value
                          )[0];
                          if (selectedObj) {
                            setFilterConcatOp(selectedObj);
                          } else {
                            setFilterConcatOp('');
                          }
                        }}
                      />
                      {/* <select
                        value={filterConcatOp}
                        onChange={({ target }) => {
                          setFilterConcatOp(target.value);
                        }}
                        className={`block rounded-lg border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm`}
                      >
                        <option key={0} value='AND'>
                          UND
                        </option>
                        <option key={1} value='OR'>
                          ODER
                        </option>
                      </select> */}

                      <div className='flex flex-row ml-auto gap-1'>
                        <Dropdown
                          id='filterType'
                          name='filterType'
                          value={selectedNewFilter}
                          options={[
                            {
                              id: 'youthWelfareCaseId',
                              name: 'Fallbezeichnung',
                            },
                            { id: 'specialistId', name: 'Fachkraft' },
                            { id: 'date', name: 'Datum' },
                            { id: 'startTime', name: 'Termin Begin' },
                            { id: 'endTime', name: 'Termin Ende' },
                            { id: 'appointmentType', name: 'Terminart' },
                          ]}
                          optionToValue={(item) => item.id}
                          optionToString={(item) => item.name}
                          onChange={({ target }) => {
                            const selectedObj = [
                              'youthWelfareCaseId',
                              'specialistId',
                              'date',
                              'startTime',
                              'endTime',
                              'appointmentType',
                            ].filter((item) => item == target.value)[0];
                            if (selectedObj) {
                              setSelectedNewFilter(selectedObj);
                            } else {
                              setSelectedNewFilter('');
                            }
                          }}
                        />
                        <SecondaryButton
                          onClick={() => {
                            if (selectedNewFilter != '') {
                              setFilters([
                                ...filters,
                                { [selectedNewFilter]: undefined },
                              ]);
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            className='mr-2'
                            icon={faPlusCircle}
                          />
                          Filter
                        </SecondaryButton>
                      </div>
                    </div>
                  </div>
                  <div className='mt-4'>
                    {filters.map((filter, index) => {
                      const key = Object.keys(filter)[0];
                      return (
                        <div key={index} className='flex flex-row items-end'>
                          {key == 'youthWelfareCaseId' ? (
                            <Dropdown
                              id='youthWelfareCaseId'
                              name='youthWelfareCaseId'
                              label='Fallbezeichnung'
                              value={filter[key] || ''}
                              options={youthWelfareCaseDropdownData || []}
                              optionToValue={(item) => item.id}
                              optionToString={(item) => `${item.identifier}`}
                              onChange={({ target }) => {
                                const selectedObj =
                                  youthWelfareCaseDropdownData.filter(
                                    (item) => item.id == target.value
                                  )[0];
                                if (selectedObj) {
                                  setFilters([
                                    ...filters.filter((item, i) => i != index),
                                    { youthWelfareCaseId: selectedObj.id },
                                  ]);
                                } else {
                                  setFilters([
                                    ...filters.filter((item, i) => i != index),
                                  ]);
                                }
                              }}
                            />
                          ) : key == 'specialistId' ? (
                            <Dropdown
                              id='specialistId'
                              name='specialistId'
                              label='Fachkraft'
                              value={filter[key] || ''}
                              options={specialistDropdownData || []}
                              optionToValue={(item) => item.id}
                              optionToString={(item) =>
                                `${item.firstname} ${item.lastname}`
                              }
                              onChange={({ target }) => {
                                const selectedObj =
                                  specialistDropdownData.filter(
                                    (item) => item.id == target.value
                                  )[0];
                                if (selectedObj) {
                                  setFilters([
                                    ...filters.filter((item, i) => i != index),
                                    { specialistId: selectedObj.id },
                                  ]);
                                } else {
                                  setFilters([
                                    ...filters.filter((item, i) => i != index),
                                  ]);
                                }
                              }}
                            />
                          ) : key == 'date' ? (
                            <TextInput
                              type='date'
                              name='date'
                              label='Datum'
                              value={filter[key]?.equals || ''}
                              onChange={({ target }) => {
                                setFilters([
                                  ...filters.filter((item, i) => i != index),
                                  {
                                    date: {
                                      equals: DateTime.fromISO(
                                        target.value
                                      ).toSQLDate(),
                                    },
                                  },
                                ]);
                              }}
                            />
                          ) : key == 'startTime' ? (
                            <TextInput
                              type='time'
                              name='startTime'
                              label='Termin Begin'
                              value={filter[key] || ''}
                              onChange={({ target }) => {
                                setFilters([
                                  ...filters.filter((item, i) => i != index),
                                  { startTime: target.value },
                                ]);
                              }}
                            />
                          ) : key == 'endTime' ? (
                            <TextInput
                              type='time'
                              name='endTime'
                              label='Termin Ende'
                              value={filter[key] || ''}
                              onChange={({ target }) => {
                                setFilters([
                                  ...filters.filter((item, i) => i != index),
                                  { endTime: target.value },
                                ]);
                              }}
                            />
                          ) : key == 'appointmentType' ? (
                            <Dropdown
                              id='appointmentType'
                              name='appointmentType'
                              label='Terminart'
                              value={filter[key] || ''}
                              options={[
                                { id: 'DIRECT', name: 'Direkt' },
                                { id: 'INDIRECT', name: 'Indirekt' },
                                { id: 'INTERNAL', name: 'Praxis' },
                              ]}
                              optionToValue={(item) => item.id}
                              optionToString={(item) => item.name}
                              onChange={({ target }) => {
                                const selectedObj = [
                                  'DIRECT',
                                  'INDIRECT',
                                  'INTERNAL',
                                ].filter((item) => item == target.value)[0];
                                if (selectedObj) {
                                  setFilters([
                                    ...filters.filter((item, i) => i != index),
                                    { appointmentType: selectedObj },
                                  ]);
                                } else {
                                  setFilters([
                                    ...filters.filter((item, i) => i != index),
                                  ]);
                                }
                              }}
                            />
                          ) : null}
                          <div className='ml-auto'>
                            <SecondaryButton
                              onClick={() => {
                                setFilters([
                                  ...filters.filter((item, i) => i != index),
                                ]);
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </SecondaryButton>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className='mt-8 flex w-full flex-row items-center justify-end gap-2'>
                    <SecondaryButton onClick={closeModal}>
                      Schließen
                    </SecondaryButton>
                    <SecondaryButton
                      onClick={() => {
                        setFilters([]);
                        setWhereFn(undefined);
                      }}
                    >
                      Filter Aufheben
                    </SecondaryButton>
                    <PrimaryButton
                      onClick={() => {
                        setWhereFn({ where: { [filterConcatOp]: filters } });
                      }}
                    >
                      Filter Anwenden
                    </PrimaryButton>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

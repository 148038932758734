import { DateTime } from 'luxon';
import moment from 'moment';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import { deleteById, findAll } from '../_api/calculationPrinciple.services';
import FormWrapper from '../_components/Form/FormWrapper';
import Table from '../_components/Table/Table';
import endpoints from '../_helpers/endpoints';
import BewilligungForm from './BewilligungForm';
import CalculcationPrincipleTable from './CalculationPrincipleTable';

const columns = [
  { accessor: 'youthWelfareCase.identifier', Header: 'Fallbezeichnung' },
  {
    accessor: (cell) => moment(cell.startDate).format('DD.MM.YYYY'),
    Header: 'Anfangsdatum',
    sortType: 'date',
  },
  {
    accessor: (cell) => moment(cell.endDate).format('DD.MM.YYYY'),
    Header: 'Enddatum',
    sortType: 'date',
  },
  {
    accessor: (cell) => {
      if (cell.endDate) {
        const calcPrinciplesEndDate = DateTime.fromISO(cell.endDate);
        const calcPrinciplesMonthBeforeEndDate = calcPrinciplesEndDate.plus({
          month: -1,
        });
        const dateToday = DateTime.now();

        if (
          dateToday < calcPrinciplesMonthBeforeEndDate &&
          dateToday < calcPrinciplesEndDate
        ) {
          return (
            <div className='px-2 py-2 rounded-lg flex flex-row justify-center'>
              Aktiv
            </div>
          );
        } else if (
          dateToday > calcPrinciplesMonthBeforeEndDate &&
          dateToday < calcPrinciplesEndDate
        ) {
          return (
            <div className='px-2 py-2 border border-yellow-500 rounded-lg bg-yellow-400 flex flex-row justify-center'>
              Läuft ab
            </div>
          );
        } else if (
          dateToday > calcPrinciplesMonthBeforeEndDate &&
          dateToday > calcPrinciplesEndDate
        ) {
          return (
            <div className='px-2 py-2 border border-red-500 rounded-lg bg-red-400 flex flex-row justify-center'>
              Abgelaufen
            </div>
          );
        }
      }
    },
    Header: 'Status',
  },
  { accessor: 'chargeOutRate', Header: 'Stundensatz' },
  { accessor: 'chargeOutRateFormula', Header: 'Formel' },
  { accessor: 'budgetInHours', Header: 'Stundenbudget' },
  {
    accessor: (cell) => {
      switch (cell.budgetAllocation) {
        case 'MONTHLY':
          return 'Monatlich';
        case 'YEARLY':
          return 'Jährlich';
        case 'OVERALL':
          return 'Zwischen Start- und Enddatum der Berechnungsgrundlage';
        default:
          return '';
      }
    },
    Header: 'Stundenzuweisung',
  },
  {
    accessor: (cell) => moment(cell.createdAt).format('DD.MM.YYYY'),
    Header: 'Erstellt am',
  },
  {
    accessor: (cell) => moment(cell.updatedAt).format('DD.MM.YYYY'),
    Header: 'Geändert am',
  },
];

export default function CalculationPrinciple({ match, history, user }) {
  const { path } = match;
  const queryClient = useQueryClient();
  const endpoint = endpoints.calculationPrinciple;
  const queryKey = 'CalculationPrinciple';

  const { status, data } = useQuery(queryKey, () => findAll());

  const deleteRow = useMutation((id) => deleteById(Number.parseInt(id)), {
    onSuccess: () => {
      // alert('Löschung erfolgreich');
    },
    onError: (error) => {
      alert(error);
    },
    // After success or failure, refetch the query
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => {
          if (status == 'loading') {
            return <div>Datensätze werden geladen...</div>;
          }

          if (status == 'error') {
            return (
              <div>Beim Laden der Datensätze ist ein Fehler aufgetretten.</div>
            );
          }

          return (
            <Table
              name='bewilligung'
              title='Berechnungsgrundlagen'
              data={data}
              deleteRow={deleteRow}
              columns={columns}
              match={match}
              history={history}
            />
          );
        }}
      />
      <Route
        path={`${path}/add`}
        render={({ match, history }) => (
          <FormWrapper
            Form={BewilligungForm}
            endpoint={endpoint}
            queryKey='CalculationPrinciple'
            match={match}
            history={history}
          />
        )}
      />
      <Route
        path={`${path}/edit/:id`}
        render={({ match, history }) => (
          <FormWrapper
            Form={BewilligungForm}
            endpoint={endpoint}
            queryKey='CalculationPrinciple'
            match={match}
            history={history}
          />
        )}
      />
    </Switch>
  );
}

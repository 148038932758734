import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

export default function fahrtkostenBericht(
  appointments,
  startDate,
  endDate,
  fahrtkostenPauschale,
  specialistName
) {
  const marginL = 15;
  const marginR = 195;
  const doc = new jsPDF();
  let kilometersTotal = 0;

  doc.setFont('times', 'normal');
  doc.setFontSize(14);
  doc.text(
    `Fahrtkosten ${specialistName} von ${moment(startDate, 'YYYY-MM-DD').format(
      'DD.MM.YYYY'
    )} bis ${moment(endDate, 'YYYY-MM-DD').format('DD.MM.YYYY')}`,
    marginL,
    10
  );

  doc.setFontSize(10);
  doc.autoTable({
    body: appointments,
    headStyles: {
      textColor: 80,
      fillColor: [224, 224, 224],
      lineWidth: 0.1,
    },
    bodyStyles: { valign: 'middle', lineWidth: 0.1 },
    alternateRowStyles: { fillColor: [244, 244, 244] },
    columns: [
      { header: 'Fall', dataKey: 'youthWelfareCase' },
      { header: 'Datum', dataKey: 'date' },
      { header: 'Abfahrtort', dataKey: 'pointOfDeparture' },
      { header: 'Zielort', dataKey: 'location' },
      { header: 'Termin Begin', dataKey: 'startTime' },
      { header: 'Termin Ende', dataKey: 'endTime' },
      { header: 'Kilometer', dataKey: 'kilometers' },
    ],
    didParseCell: function (data) {
      if (data.section === 'body') {
        if (data.row.raw.isCommute) {
          data.cell.styles.fillColor = [15, 98, 254];
          data.cell.styles.textColor = 800;
        }
        if (data.column.dataKey === 'youthWelfareCase') {
          data.cell.text = data.cell.raw.identifier;
        }
        if (data.column.dataKey === 'date') {
          data.cell.text = data.cell.raw
            ? moment(data.cell.raw, 'YYYY-MM-DD').format('DD.MM.YYYY')
            : 'Kein Angabe';
        }
        if (data.column.dataKey === 'kilometers') {
          data.cell.text = data.cell.raw
            ? data.cell.raw.toString().replace('.', ',')
            : 'Keine Angabe';
        }
        if (
          data.column.dataKey === 'kilometers' &&
          data.row.raw.isCommute === false
        ) {
          kilometersTotal += data.cell.raw ? data.cell.raw : 0;
        }
      }
    },
  });

  doc.text(
    `Gesamt Kilometer abzüglich Anfahrten als Arbeitsweg ${Number.parseFloat(
      kilometersTotal
    ).toFixed(2)} * Fahrtkostenpauschale ${fahrtkostenPauschale}`,
    marginL,
    doc.lastAutoTable.finalY + 10
  );
  doc.setFont('times', 'bold');
  doc.text(
    `${(
      Number.parseFloat(kilometersTotal) *
      Number.parseFloat(fahrtkostenPauschale)
    ).toFixed(2)} €`,
    marginR,
    doc.lastAutoTable.finalY + 10,
    { align: 'right' }
  );

  doc.setFont('times', 'normal');
  doc.text(
    `${moment().format('DD.MM.YYYY')}`,
    marginR,
    doc.lastAutoTable.finalY + 30,
    { align: 'right' }
  );
  doc.line(
    marginR,
    doc.lastAutoTable.finalY + 32,
    110,
    doc.lastAutoTable.finalY + 32
  );

  const blobPDF = new Blob([doc.output('blob')], { type: 'application/pdf' });
  const blobUrl = URL.createObjectURL(blobPDF);
  const a = document.createElement('a');

  a.style = 'display: none';
  a.href = blobUrl;
  a.target = '_blank';
  a.download = `fahrtkosten_${specialistName}_${moment(
    startDate,
    'YYYY.MM.DD'
  ).format('DDMMYYYY')}${moment(endDate, 'YYYY.MM.DD').format('DDMMYYYY')}.pdf`;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  // doc.save(
  // `fahrtkosten_${specialistName}_${moment(startDate, 'YYYY.MM.DD').format(
  //   'DDMMYYYY'
  // )}${moment(endDate, 'YYYY.MM.DD').format('DDMMYYYY')}`
  // );
}

import { integer, number, pattern, string, validate } from 'superstruct';
import refinements from '../../_helpers/refinements';
import utility from '../../_helpers/utility';

function validateText(value) {
  const [err] = validate(value, refinements.required);
  return !err;
}

function validateNumber(value) {
  const [err] = validate(Number.parseInt(value), number());
  // console.log(Number.parseInt(value), !err);
  return !err;
}

function validateDate(value) {
  const [err] = validate(value, pattern(string(), utility.isDateYYYYMMDD));
  // console.log(value, !err, err);
  return !err;
}

function validateTimeQuarterly(value) {
  const [err] = validate(value, pattern(string(), utility.isTime.quarterly));
  // console.log(value, !err, err);
  return !err;
}

function validateDropdown(value) {
  const [err] = validate(Number.parseInt(value), number());
  // console.log(value, !err, err);
  return !err;
}

export default {
  validateDate,
  validateDropdown,
  validateNumber,
  validateText,
  validateTimeQuarterly,
};

import { isEmpty } from 'lodash';
import moment from 'moment';
import { string, refine, pattern, array } from 'superstruct';

const isNotEmpty = refine(string(), 'isNotEmpty', (value) => !isEmpty(value));

const required = refine(string(), 'required', (value) => value.length > 0);

const isDurationPositiv = refine(
  array(string()),
  'isDurationPositiv',
  (arr) => {
    let dur = moment(arr[0], 'HH:mm').diff(moment(arr[1], 'HH:mm'));
    // console.log(dur);
    return dur > 0;
  }
);

export default {
  isNotEmpty,
  isDurationPositiv,
  required,
};

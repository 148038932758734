import React from 'react';

/**
 *
 * @param {{type: React.HTMLInputTypeAttribute, id: string, name: string, label: string, checked: boolean, onChange: React.ChangeEventHandler<HTMLInputElement>}} props
 * @returns {JSX.Element} JSX.Element
 */
export default function Checkbox(props) {
  const { label, ...rest } = props;
  return (
    <div className='flex items-start'>
      <div className='flex items-center h-5'>
        <input
          type='checkbox'
          {...rest}
          className='focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded'
        />
      </div>
      <div className='ml-3 text-sm'>
        <label htmlFor='candidates' className='font-medium text-gray-700'>
          {label}
        </label>
      </div>
    </div>
  );
}

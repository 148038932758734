import React from 'react';

/**
 *
 * @param {React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>} props
 * @returns
 */
export default function SecondaryButton(props) {
  const { children } = props;
  return (
    <button
      className='inline-flex items-center rounded-lg border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2'
      {...props}
    >
      {children}
    </button>
  );
}
